import React from 'react';
import { FormValues, updateDomain } from './utils';
import { CustomModal, useGLGOParams } from 'ui';
import { isSuccessStatus } from 'helpers';
import { notifications } from '@mantine/notifications';
import { Text } from '@mantine/core';

type Props = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  newDomain: string;
  resetForm: (data?: FormValues) => void;
};

const ConfirmRemoveStagingDomainModal = ({
  showModal,
  setShowModal,
  newDomain,
  resetForm,
}: Props) => {
  const { projectKey = '' } = useGLGOParams();

  return (
    <CustomModal
      opened={showModal}
      onClose={() => setShowModal(false)}
      size="lg"
      title="Confirmation"
      footerActions={[
        { label: 'Cancel', action: () => setShowModal(false) },
        {
          label: 'Confirm',
          action: () => {
            updateDomain(newDomain, [], projectKey).then((response) => {
              const {
                project: {
                  origin_name: originName = '',
                  additional_origins: additionalOrigins = [] as string[],
                } = {},
              } = response || {};

              if (isSuccessStatus(response.status)) {
                notifications.show({
                  message: `Successfully removed staging domain!`,
                });
                resetForm({
                  domain: originName,
                  additional_origins: additionalOrigins,
                });
              } else {
                notifications.show({
                  message: `Failed to remove staging domain!`,
                  color: 'red',
                });
              }
              setShowModal(false);
            });
          },
        },
      ]}
    >
      <Text ta="center" fz="md">
        You are about to remove your project’s staging domain
      </Text>
    </CustomModal>
  );
};
export default ConfirmRemoveStagingDomainModal;
