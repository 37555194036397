import { LoadingSkeleton, useCurrentProjectData, useGLGOParams } from 'ui';
import { defaultSubscription } from '@glweb/xapis-client';
import { Suspense, useState } from 'react';
import { useRecommendedThresholdContext } from '../../../providers/RecommendedThresholdProvider';
import { getStatusBannerBody } from '../../utils';
import GoLiveModal from '../modals/GoLiveModal';
import { Grid } from '@mantine/core';
import { DashboardCard } from '../DashboardCard';
import { StatusBanner } from '../../shared/StatusBanner';
import MetricsSummaryHeader from '../metrics-summary/MetricsSummaryHeader';
import MetricsSummary from '../metrics-summary/MetricsSummary';
import ContactsHeader from '../contacts/ContactsHeader';
import {
  USER_ROLE_GLGO_ADMIN,
  USER_ROLE_TRANSPERFECT_ADMIN,
  USER_ROLE_TRANSPERFECT_USER,
} from 'helpers';
import MyContacts from '../contacts/MyContacts';
import MTUsageHeader from '../mt-usage/MTUsageHeader';
import { MTUsage } from '../mt-usage/MTUsage';
import PlanDetailsHeader from '../plan-details/PlanDetailsHeader';
import PlanDetails from '../plan-details/PlanDetails';
import MyLanguagesHeader from '../my-languages/MyLanguagesHeader';
import MyLanguages from '../my-languages/MyLanguages';
import MyBatchesHeader from '../batches/MyBatchesHeader';
import { MyBatches } from '../batches/MyBatches';
import ActiveBatchesHeader from '../batches/ActiveBatchesHeader';
import { ActiveBatches } from '../batches/ActiveBatches';
import useDashboardData from './useDashboardData';

const BasicDashboard = () => {
  const { projectId, projectKey = '' } = useGLGOParams();
  const [showGoLiveModal, setShowGoLiveModal] = useState(false);

  const thresholdFromEstimate = useRecommendedThresholdContext().threshold;
  const { project } = useCurrentProjectData();

  const {
    subscriptions,
    prevWordsServed,
    wordsServed,
    worldTraffic,
    translatedTraffic,
    batches,
  } = useDashboardData();

  const { translations: targets = [] } = project;

  const activeProjectStatus = project?.project_status || '';
  const isGLWEBEnterprisePlan = project?.project_type === 'OLJS';
  const isGLGOEnterprisePlan = project?.project_type === 'GLGO-ENTERPRISE';
  const isExpiredPlan = project?.subscription_status === 'expired';
  const hasSku = Boolean(project.subscription_sku);
  const isGLWEBEnterpriseWithoutSku = !hasSku && isGLWEBEnterprisePlan;

  const { words_served = 0, price = 0 } = subscriptions[0] || {};

  const hasActiveSubscription = !isExpiredPlan && subscriptions.length > 0;
  const isFreePlan = price === 0;

  const wordsServedAmount = hasActiveSubscription
    ? parseInt(words_served || defaultSubscription.words_served)
    : 0;

  const statusBannerBody = getStatusBannerBody(
    isExpiredPlan,
    isFreePlan,
    activeProjectStatus,
    setShowGoLiveModal,
    projectId || '',
    thresholdFromEstimate
  );

  if (!project || !projectKey) {
    return <></>;
  }

  return (
    <>
      <GoLiveModal setOpened={setShowGoLiveModal} opened={showGoLiveModal} />
      <Grid grow gutter="sm" align="stretch">
        <DashboardCard
          isVisible={Boolean(statusBannerBody)}
          isCard={false}
          maxColSpan={12}
        >
          <StatusBanner
            statusBody={statusBannerBody}
            statusType={isExpiredPlan ? 'subscription' : 'translations'}
          />
        </DashboardCard>

        <DashboardCard maxColSpan={8} header={<MetricsSummaryHeader />}>
          <MetricsSummary
            timeSlices={translatedTraffic.slices}
            prevTimeSlices={translatedTraffic.prevSlices}
            worldTraffic={worldTraffic}
            wordsServed={wordsServed}
            prevWordsServed={prevWordsServed}
          />
        </DashboardCard>

        <DashboardCard header={<ContactsHeader />}>
          <MyContacts />
        </DashboardCard>

        <DashboardCard maxColSpan={8} header={<MTUsageHeader />}>
          <Suspense fallback={<LoadingSkeleton />}>
            <MTUsage thresholdValue={0} />
          </Suspense>
        </DashboardCard>

        <DashboardCard isCard={false}>
          <DashboardCard
            isNested
            isVisible={!isGLGOEnterprisePlan && !isGLWEBEnterpriseWithoutSku}
            header={<PlanDetailsHeader />}
          >
            <PlanDetails
              isExpiredPlan={isExpiredPlan}
              subscriptions={subscriptions}
              wordsServedAmount={wordsServedAmount}
            />
          </DashboardCard>

          <DashboardCard isNested flex={2} header={<MyLanguagesHeader />}>
            <MyLanguages targets={targets} />
          </DashboardCard>
        </DashboardCard>
        <>
          <DashboardCard
            allowedRoles={
              new Set([
                USER_ROLE_TRANSPERFECT_USER,
                USER_ROLE_TRANSPERFECT_ADMIN,
                USER_ROLE_GLGO_ADMIN,
              ])
            }
            header={<MyBatchesHeader />}
          >
            <MyBatches batches={batches?.my_batches ?? []} />
          </DashboardCard>

          <DashboardCard
            allowedRoles={
              new Set([
                USER_ROLE_TRANSPERFECT_USER,
                USER_ROLE_TRANSPERFECT_ADMIN,
                USER_ROLE_GLGO_ADMIN,
              ])
            }
            header={<ActiveBatchesHeader />}
          >
            <ActiveBatches activeBatches={batches?.active_batches ?? []} />
          </DashboardCard>
        </>
      </Grid>
    </>
  );
};
export default BasicDashboard;
