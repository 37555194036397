import { CustomModal, Header } from 'ui';

type Props = {
  hasMultipleBatches: boolean;
  cancelBatch: (params: Record<string, string>) => void;
  opened: boolean;
  close: () => void;
};

const cancelBatchParams = {
  phase_status: 'cancelled',
  phase: 'complete',
} as Record<string, string>;

export const CancelBatchesModal = ({
  cancelBatch,
  opened,
  close,
  hasMultipleBatches,
}: Props) => {
  return (
    <CustomModal
      opened={opened}
      onClose={close}
      footerActions={[
        { action: close, label: 'Close' },
        { action: () => cancelBatch(cancelBatchParams), label: 'Confirm' },
      ]}
      title={
        <Header
          title={
            hasMultipleBatches
              ? 'Are you sure you want to cancel these batches?'
              : 'Are you sure you want to cancel this batch?'
          }
        />
      }
    />
  );
};
