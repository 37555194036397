import React from 'react';
import {
  BackgroundImage,
  Button,
  Card,
  Divider,
  Flex,
  StyleProp,
  Text,
  Title,
} from '@mantine/core';
import StepsTimeline from './StepsTimeline';
import GetStartedBackground from './images/get-started-background.svg';
import { useSetupStepsContext } from './ProjectSetupProvider';
import GLA from '../../analytics/googleAnalytics';
import fathomEventTracker from '../../analytics/fathomAnalytics';
import { useViewSize } from 'ui';

const GettingStartedStep = () => {
  const { isDesktopSize } = useViewSize();
  const {
    sendTagEvent,
    EVENT_CLICK,
    EVENT_ACTION_CREATE_PROJECT_BUTTON,
    EVENT_LABEL_CREATE_PROJECT_FORM,
  } = GLA;
  const { setActiveStep } = useSetupStepsContext();

  return (
    <Flex
      w="100%"
      maw={
        (isDesktopSize ? 450 : undefined) as StyleProp<
          React.CSSProperties['maxWidth']
        >
      }
      h={
        (isDesktopSize ? undefined : '100%') as StyleProp<
          React.CSSProperties['height']
        >
      }
      rowGap="1.5rem"
      direction="column"
      justify="center"
      align="center"
    >
      <Title fz={isDesktopSize ? '2rem' : '1.5rem'} order={1}>
        Let's get started!
      </Title>
      <Divider w="100%" color="divider.0" size={2} />
      <Text fz={isDesktopSize ? 'lg' : 'md'} fw={500} ta="center">
        We’re going to walk you through the process of setting up your website
        translation project step-by-step. This can take a few minutes.
      </Text>
      <Button
        w="auto"
        onClick={() => {
          fathomEventTracker('Project Initiate - 1');
          sendTagEvent(
            EVENT_CLICK,
            EVENT_ACTION_CREATE_PROJECT_BUTTON,
            EVENT_LABEL_CREATE_PROJECT_FORM
          );
          setActiveStep((prevStep) => prevStep + 1);
        }}
      >
        <Text c="inherit" fz="inherit" fw="inherit" id="project-initiate-1">
          Next
        </Text>
      </Button>
    </Flex>
  );
};

export const GetStartedSideMenu = () => {
  return <GettingStartedStep />;
};

export const GetStartedBody = () => (
  <BackgroundImage
    h="100%"
    src={`"${GetStartedBackground}"`}
    style={{
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    }}
    pl="3rem"
    pr="2rem"
  >
    <Flex w="100%" h="100%" align="center">
      <StepsTimeline />
    </Flex>
  </BackgroundImage>
);

export const MobileGetStartedStep = () => {
  return (
    <Card maw={{ base: '100%', xs: 650, lg: 450 }} px="1rem" py="2rem">
      <GettingStartedStep />
    </Card>
  );
};
