import { Flex, Image, Stepper, Text } from '@mantine/core';
import { useCallback } from 'react';
import PersonOne from './images/get-started-person-one.svg';
import PersonTwo from './images/get-started-person-two.svg';
import nextGLGOIcon from './images/next-steps-icon.svg';
import GLGOIcon from './images/steps-icon.svg';
import { useSetupStepsContext } from './ProjectSetupProvider';
import { useViewSize } from 'ui';
import classes from './StepsTimeline.module.css';

const getStepperStep = (activeStep: number) => {
  switch (activeStep) {
    default:
    case 0:
      return 0;
    case 1:
      return 0.5;
    case 2:
      return 1;
    case 3:
      return 1.5;
    case 4:
      return 2;
    case 5:
      return 3;
    case 6:
      return 4;
    case 7:
      return 5;
    case 8:
      return 6;
  }
};

export const StepsTimeline = () => {
  const { isMobileSize, isDesktopSize } = useViewSize();
  const { activeStep } = useSetupStepsContext();

  const step = getStepperStep(activeStep);

  const getStepperIcon = useCallback(
    (label: string, type: string, index: number, isStepActive: boolean) => {
      const isNext = type === 'next';
      const icon = isNext ? nextGLGOIcon : GLGOIcon;

      return (
        <Flex w="max-content" justify="center" wrap="wrap">
          <Flex w="100%" justify="center" align="center">
            <Image
              src={icon}
              width={isMobileSize ? 30 : 50}
              height={isMobileSize ? 36 : 60}
              opacity={isNext ? 0.3 : 1}
              alt={`Project Setup: step ${index + 1} icon`}
            />
            <Text
              pos="absolute"
              fw={700}
              fz={{ base: '0.8rem', xs: '0.85rem' }}
              c="font1.0"
            >
              {index + 1}
            </Text>
          </Flex>
          <Text
            w={{ base: '4rem', xs: '7rem' }}
            mt="0.5rem"
            lh={{ base: '1rem', sm: '1.25rem' }}
            fz={{ base: '0.8rem', xs: '0.95rem' }}
            ta="center"
            c={isStepActive ? 'text.8' : 'font1.0'}
            opacity={isNext ? 0.6 : 1}
            fw={700}
          >
            {label}
          </Text>
        </Flex>
      );
    },
    [isMobileSize]
  );

  const getSeparatorClassName = () => {
    switch (true) {
      case step === 1:
        return classes.activeFirstSeparator;
      case step % 1 !== 0 && step < 1:
        return classes.firstSeparator;
      case step % 1 !== 0 && step > 1:
        return classes.secondSeparator;
      case step >= 2:
        return classes.activeSeparator;
      default:
        return '';
    }
  };

  return (
    <Flex w="100%" h={{ base: 80, xs: 100 }} px="2rem" justify="center">
      <Flex w="100%" align="center" justify="center" pos="relative">
        {isDesktopSize && step === 0 && (
          <Flex
            pos="absolute"
            w="100%"
            maw="21.5rem"
            top="-6rem"
            justify="space-between"
          >
            <Image width={100} src={PersonOne} alt="person taking steps" />
            <Image
              width={100}
              src={PersonTwo}
              alt="another person taking steps"
            />
          </Flex>
        )}
        <Stepper
          w="100%"
          maw={{ base: '100%', xs: '25rem', sm: '36rem' }}
          color="text.8"
          active={step}
          classNames={{
            stepIcon: classes.stepIcon,
            separator: `${classes.defaultSeparator} ${getSeparatorClassName()}`,
          }}
        >
          {['Create Project', 'Project Setup', 'Add Snippet'].map(
            (label, index) => {
              const isFirstStep = label === 'Create Project' && step < 1;
              const isSecondStep =
                label === 'Project Setup' && step >= 1 && step < 2;
              const isThirdStep = label === 'Add Snippet' && step >= 2;
              const isStepActive = isFirstStep || isSecondStep || isThirdStep;

              return (
                <Stepper.Step
                  key={label}
                  icon={getStepperIcon(label, 'next', index, isStepActive)}
                  progressIcon={getStepperIcon(
                    label,
                    'progress',
                    index,
                    isStepActive
                  )}
                  completedIcon={getStepperIcon(
                    label,
                    'completed',
                    index,
                    isStepActive
                  )}
                  disabled
                />
              );
            }
          )}
        </Stepper>
      </Flex>
    </Flex>
  );
};

export default StepsTimeline;
