import {
  Button,
  Card,
  CopyButton,
  Flex,
  Paper,
  Stack,
  Text,
  Title,
  UnstyledButton,
} from '@mantine/core';
import { useState } from 'react';
import { MdArrowRightAlt, MdContentCopy } from 'react-icons/md';
import { useCurrentProjectData, useGLGOParams } from 'ui';
import { Link } from 'react-router-dom';

const Injection = () => {
  const [showCodeSnippet, setShowCodeSnippet] = useState(false);
  const { projectKey } = useGLGOParams();
  const { project: { origin_name: originName = '' } = {} } =
    useCurrentProjectData();

  const previewDomain = originName
    ? `https://${originName}?glgo_preview=1`
    : '';

  const longCode = `
    <script referrerpolicy="no-referrer-when-downgrade"
    type="text/javascript" src="https://www.onelink-edge.com/moxie.min.js"
    data-oljs="P${projectKey}"></script>
  `;

  return (
    <Card>
      <Stack gap={10}>
        <Title>Injection</Title>
        <Text mb={10}>
          {'Ready to go live? Add this code to your website’s <head> section'}
        </Text>
        <Flex gap={10}>
          <Button
            variant="outline"
            onClick={() => setShowCodeSnippet(!showCodeSnippet)}
          >
            {showCodeSnippet ? 'Hide' : 'Show'} Code Snippet
          </Button>
          <CopyButton value={longCode} timeout={2000}>
            {({ copied, copy }) => (
              <Button onClick={copy}>
                {copied ? 'Copied!' : 'Copy'}&nbsp;
                <MdContentCopy size={20} />
              </Button>
            )}
          </CopyButton>
        </Flex>
        {showCodeSnippet ? (
          <Paper
            withBorder
            p="1rem"
            radius="lg"
            shadow="none"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Text
              fw={600}
              fz="sm"
              data-testid="pw-project-settings-snippet"
              style={{ wordBreak: 'break-all' }}
            >
              {longCode}
            </Text>
          </Paper>
        ) : (
          <></>
        )}
        <Paper withBorder p="1rem" radius="lg" shadow="none" bg={'cta1.5'}>
          <Title order={3} mb={5}>
            Need to test first?
          </Title>
          <UnstyledButton fz={14} color="red">
            <Link
              to={previewDomain}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Flex c="cta1.2">
                Preview in Sandbox Environment
                <MdArrowRightAlt size={24} />
              </Flex>
            </Link>
          </UnstyledButton>
        </Paper>
      </Stack>
    </Card>
  );
};
export default Injection;
