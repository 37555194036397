import { Button, Flex, Menu } from '@mantine/core';
import { MdArrowDropDown } from 'react-icons/md';
import React from 'react';
import BatchActionsDropdown from '../../BatchActionsDropdown';
import { useCurrentProjectData } from 'ui';
import { filterTargets } from '../../../metrics/trafficUtils';
import QueryMultiSelector from '../../../../components/selectors-filters/QueryMultiSelector';
import { MyBatchesButton } from '../../MyBatchesButton';
import { FiltersDropdown } from '../../FiltersDropdown';
import { BatchFieldOptions } from '../../../../pages/batches/ActiveBatchesPage';
import QuerySearchBar from '../../QuerySearchBar';
import {
  internalUserRoles,
  USER_ROLE_ADMIN,
  USER_ROLE_PROJECT_MANAGER,
  useViewPermissions,
} from 'helpers';

const ActiveBatchFilters = ({
  selectedBatches,
  assigneeOptions,
  handleBatchChange,
  openCancelBatchModal,
  openCompleteBatchesModal,
  openGradeBatchModal,
  openEditBatchModal,
}: {
  selectedBatches: string[];
  assigneeOptions: string[];
  handleBatchChange: (
    batches: string[],
    params: Partial<Record<Partial<BatchFieldOptions>, string>>
  ) => void;
  openCancelBatchModal: (batches: string[]) => void;
  openCompleteBatchesModal: (batches: string[]) => void;
  openGradeBatchModal: (batch: string) => void;
  openEditBatchModal: (batch: string) => void;
}) => {
  const { project } = useCurrentProjectData();
  const { hasViewPermissions, rolePermissions } = useViewPermissions();
  const canEditBatch = hasViewPermissions(
    rolePermissions,
    new Set([...internalUserRoles, USER_ROLE_ADMIN, USER_ROLE_PROJECT_MANAGER])
  );
  const filteredTargets = filterTargets(project.translations || []);

  return (
    <>
      <Flex justify="space-between" gap={10}>
        <Flex gap={10} align="center" wrap="wrap">
          <QuerySearchBar />
          <QueryMultiSelector
            encodeKeys
            selectAll
            shouldRevalidate
            options={filteredTargets}
            distinctSelectAll
          />
          <FiltersDropdown assigneeOptions={assigneeOptions} />
          <MyBatchesButton />
        </Flex>
        {canEditBatch && (
          <Menu>
            <Menu.Target>
              <Button
                disabled={!selectedBatches.length}
                rightSection={<MdArrowDropDown size={24} />}
              >
                Actions
              </Button>
            </Menu.Target>
            <BatchActionsDropdown
              batches={selectedBatches}
              handleBatchChange={(params) =>
                handleBatchChange(selectedBatches, params)
              }
              openCancelBatchModal={openCancelBatchModal}
              openCompleteBatchesModal={openCompleteBatchesModal}
              openGradingBatchModal={openGradeBatchModal}
              openEditBatchModal={openEditBatchModal}
            />
          </Menu>
        )}
      </Flex>
    </>
  );
};
export default ActiveBatchFilters;
