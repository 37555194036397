import { useSegmentEditorContext } from '../../context/SegmentEditorProvider';
import { Box, Button, Flex } from '@mantine/core';
import { useUnsavedTranslations } from '../../store/SegmentsState';
import { useUnsavedReviews } from '../../store/BatchReviewStore';

type DashboardProps = {
  onSaveSegments: () => void;
  SortByButtonsAndMenu: React.ReactElement;
  SearchBarAndFilterMenu: React.ReactElement;
};

const EditorDashboard = ({
  SearchBarAndFilterMenu,
  SortByButtonsAndMenu,
  onSaveSegments,
}: DashboardProps) => {
  const unsavedTranslations = useUnsavedTranslations();
  const unsavedReviews = useUnsavedReviews();
  const unsavedCount = unsavedTranslations.union(unsavedReviews).size;
  const { settings } = useSegmentEditorContext();
  const { viewOnly } = settings;
  const disableSave = viewOnly || !unsavedCount;

  const saveLabel = (() => {
    if (viewOnly) return 'View Only';
    return unsavedCount ? `Save (${unsavedCount})` : 'Save';
  })();

  return (
    <Flex w="100%" py={8} px={20} justify={'space-between'} bg="blue.0">
      {SearchBarAndFilterMenu}
      <Flex direction="column" rowGap={5} align="flex-end">
        <Button size="xs" onClick={onSaveSegments} disabled={disableSave}>
          {saveLabel}
        </Button>
        {SortByButtonsAndMenu}
      </Flex>
    </Flex>
  );
};

export default EditorDashboard;
