import { Button, Flex, Title } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';
import { useUserProvider } from 'ui';
import { useSetupStepsContext } from './ProjectSetupProvider';
import { handleCreateProject } from './handleProjectSetupSteps';

type Props = {
  title: string;
  isNextButtonDisabled?: boolean;
  isBackButtonDisabled?: boolean;
};

export const MobileActionHeader = ({
  title,
  isNextButtonDisabled,
  isBackButtonDisabled,
}: Props) => {
  const [, setSearchParams] = useSearchParams();
  const { xapisUser, setXapisUser } = useUserProvider();
  const {
    activeStep,
    setActiveStep,
    updateStatus,
    sourceTargetLangCodes,
    themePosition,
    url: { domain },
  } = useSetupStepsContext();
  const queryClient = useQueryClient();

  const isRegisterStep = activeStep === 3;
  const isAddSnippetStep = activeStep === 4;

  return (
    <Flex
      w="100%"
      justify={isAddSnippetStep ? 'center' : 'space-between'}
      align="center"
    >
      <Button
        display={isAddSnippetStep ? 'none' : 'block'}
        variant="transparent"
        disabled={isBackButtonDisabled}
        p="0.5rem"
        onClick={() => {
          setActiveStep((prevStep) => {
            return prevStep - 1;
          });
        }}
      >
        <MdArrowBackIos size={24} color="inherit" />
      </Button>

      <Title fz={{ base: 'xl', sm: '1.5rem' }} order={1}>
        {title}
      </Title>

      <Button
        display={isAddSnippetStep ? 'none' : 'block'}
        variant="transparent"
        disabled={isNextButtonDisabled}
        p="0.5rem"
        onClick={() => {
          if (isRegisterStep) {
            handleCreateProject(
              { domain, sourceTargetLangCodes, themePosition },
              updateStatus,
              setActiveStep,
              xapisUser,
              setXapisUser,
              setSearchParams,
              queryClient
            );
          } else {
            setActiveStep((prevStep) => {
              return prevStep + 1;
            });
          }
        }}
      >
        <MdArrowForwardIos size={24} color="inherit" />
      </Button>
    </Flex>
  );
};
