import { projectQuery } from '@glweb/xapis-client';
import { useSetupStepsContext } from '../ProjectSetupProvider';
import { useQuery } from '@tanstack/react-query';

export default function useInjectionStatus() {
  const { project_key, project_status } = useSetupStepsContext().project;
  const isInjected = project_status === 'VERIFIED';
  const shouldPollProject = project_key && !isInjected;

  useQuery(
    projectQuery(project_key!, false, {
      enabled: shouldPollProject,
      refetchInterval: 3000,
    })
  );

  return project_status === 'VERIFIED';
}
