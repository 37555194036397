import { desktopRC } from '@glweb/constants';
import {
  Box,
  Flex,
  Grid,
  Menu,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { encodeKey, isValidKey } from 'helpers';
import { CssIcon } from 'images';
import React from 'react';
import { HiOutlineMinusSm } from 'react-icons/hi';
import { MdOutlineArrowForwardIos } from 'react-icons/md';
import { PiWarningCircleFill } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import {
  AnchorLink,
  OpenWebStudio,
  StatusCircleIcon,
  useCurrentProjectData,
  useGLGOParams,
} from 'ui';
import { BasicHorizontalDivider } from '../../shared/BasicHorizontalDivider';
import DeployStatusToggle from './DeployStatusToggle';

type Props = {
  translation: TranslationKey;
  updateTranslationStatus: (
    selectedTranslationKey: string,
    isLive: boolean
  ) => void;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTranslation: React.Dispatch<
    React.SetStateAction<TranslationKey | null>
  >;
  isLastItem: boolean;
};
export const ActiveTranslationItem = ({
  translation,
  updateTranslationStatus,
  setIsModalOpen,
  setSelectedTranslation,
  isLastItem,
}: Props) => {
  const { projectId } = useGLGOParams();
  const { project } = useCurrentProjectData();
  const { deployment_method = '' } = project || {};
  const colors = useMantineTheme().colors;

  const {
    target_lang_code = '',
    target_lang_name = '',
    source_lang_name = '',
    translation_key = '',
    is_live = false,
    deployment_value = '',
  } = translation || {};

  const isValidTarget =
    target_lang_code !== 'YY' && target_lang_name !== source_lang_name;
  const sourceLanguage =
    source_lang_name
      ?.substring(0, source_lang_name.indexOf('('))
      .slice(0, -1) || 'English';

  const needsDeploymentValue = deployment_value.length === 0;

  const CustomStatusIconMenu = ({
    method,
    children,
  }: {
    method: string;
    children: React.ReactNode;
  }) => (
    <Menu position="bottom-start" trigger="hover" withArrow withinPortal>
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown maw={300} p="sm" style={{ wordBreak: 'break-word' }}>
        <Text>
          <Text span tt="capitalize">
            {method}
          </Text>{' '}
          was left empty or is not a correct {method} so it will not appear in
          the language selector.{' '}
          <AnchorLink
            fz="inherit"
            td="none"
            to={`/${projectId}/settings/installation`}
          >
            Go to your deployment configuration
          </AnchorLink>{' '}
          to add a {method} for this language.
        </Text>
      </Menu.Dropdown>
    </Menu>
  );

  return target_lang_name && isValidTarget ? (
    <>
      <Grid align="center" my="auto" p={20}>
        <Grid.Col span={{ base: 12, sm: 8 }}>
          <Flex direction="row" align="center" columnGap={20}>
            {needsDeploymentValue ? (
              <CustomStatusIconMenu method={deployment_method}>
                <Flex align="center">
                  <PiWarningCircleFill
                    color={colors.text[4]}
                    cursor="pointer"
                    size={15}
                  />
                </Flex>
              </CustomStatusIconMenu>
            ) : (
              <Box pl="0.1rem">
                <StatusCircleIcon status={is_live ? 'live' : 'private'} />
              </Box>
            )}
            <Text c={needsDeploymentValue ? 'text.7' : undefined} fw={500}>
              {sourceLanguage}
            </Text>
            <MdOutlineArrowForwardIos size={18} color={colors.font1[1]} />
            <Text
              data-testid={`pw-language-lang-code-${target_lang_code}`}
              fw={500}
              c={needsDeploymentValue ? 'text.7' : undefined}
            >
              {target_lang_name}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 4 }}>
          <Flex
            align="center"
            justify="end"
            gap={{ base: 20, xl: 40 }}
            rowGap={{ base: 'auto', xl: 60 }}
          >
            <Tooltip label="Custom CSS Editor">
              <Link to={`custom-css-editor?t=${encodeKey(translation_key)}`}>
                <Flex c="blue.7">
                  <CssIcon />
                </Flex>
              </Link>
            </Tooltip>
            <OpenWebStudio
              path={`/${projectId}/${desktopRC.CSS_EDITOR}?t=${encodeKey(translation_key)}`}
              disabled={!isValidKey(translation_key)}
            />
            <Tooltip
              events={
                needsDeploymentValue
                  ? { hover: false, touch: false, focus: false }
                  : { hover: true, touch: false, focus: true }
              }
              label={
                <Text inherit>
                  When a language is set to Private, it is not visible to the
                  public.
                  <br />
                  <br />
                  To view any page of your site in preview mode, add
                  ?glgo_preview=1 to the url and then choose a language using
                  the selector.
                </Text>
              }
              position="right"
            >
              <Box>
                <DeployStatusToggle
                  needsDeploymentValue={needsDeploymentValue}
                  is_live={is_live}
                  updateTranslationStatus={updateTranslationStatus}
                  translation_key={translation_key}
                />
              </Box>
            </Tooltip>
            <Box
              h={24}
              onClick={() => {
                setSelectedTranslation(translation);
                setIsModalOpen(true);
              }}
              style={{ cursor: 'pointer' }}
            >
              <HiOutlineMinusSm color={colors.text[8]} size={24} />
            </Box>
          </Flex>
        </Grid.Col>
      </Grid>
      {!isLastItem && (
        <BasicHorizontalDivider borderWidth={2} mobileMargin="0" />
      )}
    </>
  ) : (
    <></>
  );
};

export default ActiveTranslationItem;
