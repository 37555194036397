import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Image,
  MantineFontSize,
  MantineSize,
  Select,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { isValidDomain } from 'helpers';
import React, { useEffect, useRef } from 'react';
import { useUserProvider } from 'ui';
import fathomEventTracker from '../../analytics/fathomAnalytics';
import BrowserWindow from './BrowserWindow';
import { handleQuickQuote } from './handleQuickQuote';
import WelcomePage from './images/welcome-page.svg';
import { useSetupStepsContext } from './ProjectSetupProvider';
import { FaChevronDown } from 'react-icons/fa';
import { UrlObj } from './types';
import { trimUrl } from './utils';
import classes from './CreateProjectStep.module.css';

const onDomainChange = (
  event: React.FormEvent<HTMLInputElement>,
  setUrl: React.Dispatch<React.SetStateAction<UrlObj>>
) => {
  const domain = trimUrl(event.currentTarget.value);
  setUrl((prevUrl) => ({ ...prevUrl, domain }));
};

const CreateProjectText = ({ fz }: { fz: MantineFontSize }) => {
  return (
    <>
      <Title fz={fz === 'md' ? '1.5rem' : '2rem'} order={1}>
        Create Project
      </Title>
      <Divider w="100%" color="divider.0" size={2} />
      <Text fz={fz} fw={500} ta="center">
        You are about to make your website multilingual!
      </Text>
      <Text fz={fz} fw={700} ta="center">
        What is your project's website domain?
      </Text>
    </>
  );
};

const DomainInput = ({ size }: { size: MantineSize }) => {
  const {
    url: { protocol, domain },
    setUrl,
  } = useSetupStepsContext();

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  return (
    <>
      <Select
        value={protocol}
        placeholder="https"
        data={['https', 'http']}
        size={size}
        rightSection={<FaChevronDown size={18} />}
        rightSectionWidth={40}
        onChange={(v) =>
          v &&
          setUrl((prevUrl) => ({
            ...prevUrl,
            protocol: v as UrlObj['protocol'],
          }))
        }
        classNames={{
          root: classes.protocolSelectRoot,
          input: classes.protocolSelectInput,
        }}
      />
      <TextInput
        w="100%"
        ref={inputRef}
        size={size}
        required
        withAsterisk
        aria-label="domain"
        value={domain}
        onInput={(event) => onDomainChange(event, setUrl)}
        classNames={{
          input: classes.domainInput,
        }}
      />
    </>
  );
};

const NextButton = () => {
  const { email = '' } = useUserProvider().xapisUser;

  const {
    url: { domain },
    setActiveStep,
  } = useSetupStepsContext();

  const isNextButtonDisabled = !isValidDomain(domain);

  return (
    <Button
      w="auto"
      disabled={isNextButtonDisabled}
      onClick={() => {
        fathomEventTracker('Domain Entry - 2');
        handleQuickQuote(domain, email);
        setActiveStep((prevStep) => prevStep + 1);
      }}
    >
      <Text c="inherit" fz="inherit" fw="inherit" id="domain-entry-2">
        Next
      </Text>
    </Button>
  );
};

export const CreateProjectBody = () => {
  return (
    <Box px="2rem">
      <BrowserWindow>
        <Flex align="center">
          <DomainInput size={'xl'} />
        </Flex>
        <Image src={WelcomePage} alt="Welcome page text and design" />
      </BrowserWindow>
    </Box>
  );
};

export const CreateProjectSideMenu = () => {
  return (
    <Flex
      w="100%"
      maw={450}
      rowGap="1.5rem"
      direction="column"
      justify="center"
      align="center"
    >
      <CreateProjectText fz="lg" />
      <NextButton />
    </Flex>
  );
};

export const MobileCreateProjectStep = () => {
  return (
    <Card w="100%" maw={{ base: '100%', xs: 650, lg: 450 }} px="1rem" py="2rem">
      <Flex
        w="100%"
        h="100%"
        rowGap="1.5rem"
        direction="column"
        justify="center"
        align="center"
      >
        <CreateProjectText fz="md" />
        <Flex w="100%" maw={{ base: '100%', xs: '28rem' }} align="center">
          <DomainInput size={'md'} />
        </Flex>
        <NextButton />
      </Flex>
    </Card>
  );
};
