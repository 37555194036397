import { Card, Title, Text, Stack } from '@mantine/core';
import EditDomainForm from './EditDomainForm';

const DomainSettings = () => {
  return (
    <Card p={25}>
      <Stack gap={5}>
        <Title>Domain Settings</Title>
        <Text mb={10}>Configure your website's domain settings</Text>
        <EditDomainForm />
      </Stack>
    </Card>
  );
};

export default DomainSettings;
