import { MRT_Cell, MRT_ColumnDef, MRT_Row } from 'mantine-react-table';
import {
  CustomTable,
  getBasicColumn,
  useCurrentProjectData,
  OpenWebStudio,
} from 'ui';
import { Card, Image, Flex, Text, UnstyledButton } from '@mantine/core';
import ReviewedIcon from '../../../assets/images/ReviewedIcon.svg';
import NotReviewedIcon from '../../../assets/images/NotReviewedIcon.svg';
import { useDetailedBatch } from '../../../queries/batchesQueries';
import { BatchEditorIcon } from 'images';
import { encodeKey, getFullUrl, isDesktopApp } from 'helpers';
import { desktopRC } from '@glweb/constants';
import { useParams } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import ApproveURLsModal from './ApproveURLsModal';
import UnapproveURLsModal from './UnapproveURLsModal';

export const UrlsTable = () => {
  const { projectId } = useParams();
  const { batch } = useDetailedBatch();
  const {
    project: { origin_name },
  } = useCurrentProjectData();
  const studioBatchPath = `/${projectId}/${desktopRC.BATCH}?batch_key=${batch.batch_key}&t=${encodeKey(batch.translation_key)}`;

  const [
    approveModalOpened,
    { open: openApproveModal, close: closeApproveModal },
  ] = useDisclosure();
  const [
    unapproveModalOpened,
    { open: openUnapproveModal, close: closeUnapproveModal },
  ] = useDisclosure();
  const [urlsToReview, setUrlsToReview] = useState<BatchURL[]>([]);

  const columns: MRT_ColumnDef<BatchURL>[] = [
    {
      ...getBasicColumn('is_reviewed', 'reviewed'),
      Cell: ({
        cell,
        row,
      }: {
        cell: MRT_Cell<BatchURL, unknown>;
        row: MRT_Row<BatchURL>;
      }) => {
        const reviewed = cell.getValue() as boolean;
        return reviewed ? (
          <UnstyledButton
            onClick={() => {
              setUrlsToReview([row.original]);
              openUnapproveModal();
            }}
          >
            <Image src={ReviewedIcon} alt="Reviewed" />
          </UnstyledButton>
        ) : (
          <UnstyledButton
            onClick={() => {
              setUrlsToReview([row.original]);
              openApproveModal();
            }}
          >
            <Image src={NotReviewedIcon} alt="Not Reviewed" />
          </UnstyledButton>
        );
      },
      enableSorting: false,
      grow: false,
      mantineTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      ...getBasicColumn('content_url', 'Urls'),
      Cell: ({ cell }: { cell: MRT_Cell<BatchURL, unknown> }) => {
        const row = cell.row.original as BatchURL;
        const fullUrl = getFullUrl(row.content_url, origin_name);
        const webStudioPath = `${studioBatchPath}&url=${encodeURIComponent(
          `${fullUrl}`
        )}&url_hash=${row.content_url_hash}`;

        return isDesktopApp() ? (
          <OpenWebStudio
            icon={
              <Flex gap={5} align="center" justify={'flex-end'} c="blue.7">
                <Text c="blue.7">{fullUrl || ''}</Text>
                <BatchEditorIcon />
              </Flex>
            }
            path={webStudioPath}
          />
        ) : (
          <Text>{fullUrl || ''}</Text>
        );
      },
    },
    {
      ...getBasicColumn('num_segments', 'segments'),
      grow: false,
      mantineTableBodyCellProps: {
        align: 'right',
      },
      size: 120,
    },
    {
      ...getBasicColumn('word_count', 'words'),
      grow: false,
      mantineTableBodyCellProps: {
        align: 'right',
      },
    },
  ];
  return (
    <>
      <Card p={0}>
        <CustomTable
          columns={columns}
          data={batch.urls}
          enableBottomToolbar={false}
        />
      </Card>
      <ApproveURLsModal
        urlHashes={urlsToReview.map((url) => url.content_url_hash)}
        batchKey={batch.batch_key}
        translationKey={batch.translation_key}
        opened={approveModalOpened}
        onClose={closeApproveModal}
      />
      <UnapproveURLsModal
        urlHashes={urlsToReview.map((url) => url.content_url_hash)}
        batchKey={batch.batch_key}
        translationKey={batch.translation_key}
        opened={unapproveModalOpened}
        onClose={closeUnapproveModal}
      />
    </>
  );
};
