import { CustomModal, Header } from 'ui';

type Props = {
  hasMultipleBatches: boolean;
  completeBatches: (params: Record<string, string>) => void;
  opened: boolean;
  close: () => void;
};

const completeBatchParams = {
  phase_status: 'completed',
  phase: 'complete',
} as Record<string, string>;

export const CompleteBatchesModal = ({
  completeBatches,
  opened,
  close,
  hasMultipleBatches,
}: Props) => {
  return (
    <CustomModal
      opened={opened}
      onClose={close}
      footerActions={[
        { action: close, label: 'Close' },
        {
          action: () => completeBatches(completeBatchParams),
          label: 'Confirm',
        },
      ]}
      title={
        <Header
          title={
            hasMultipleBatches
              ? 'Are you sure you want to complete these batches?'
              : 'Are you sure you want to complete this batch?'
          }
        />
      }
    />
  );
};
