import { dashboardRC } from '@glweb/constants';
import { QueryClient } from '@tanstack/react-query';
import { RouteObject } from 'react-router-dom';
import { NoTranslateLanding } from 'ui';
import RestrictedRoute from '../../RestrictedRoute';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import Glossary from '../../components/translations/glossary/Glossary';
import {
  USER_ROLES_ACTIVE_BATCHES_PAGE,
  USER_ROLES_BATCH_HISTORY_PAGE,
  USER_ROLES_GLOSSARY_PAGE,
  USER_ROLES_LANGUAGES_PAGE,
  USER_ROLES_NO_TRANSLATE_PAGE,
} from '../../constants';
import {
  activeBatchesLoader,
  batchDetailsLoader,
  batchHistoryLoader,
} from '../../loaders/batchesLoader';
import glossaryLoader from '../../loaders/glossaryLoader';
import ActiveBatchesPage from '../batches/ActiveBatchesPage';
import BatchDetailsPage from '../batches/BatchDetailsPage';
import BatchHistoryPage from '../batches/BatchHistoryPage';
import { Languages } from './Languages';
import { GLGO_PROJECT_TYPE, nonGLNOWProjectTypesSet } from 'helpers';

export const composeTranslationsRoutes = (
  queryClient: QueryClient
): Array<RouteObject> => [
  {
    path: dashboardRC.PAGE_LANGUAGES,
    element: (
      <RestrictedRoute
        allowedRoles={USER_ROLES_LANGUAGES_PAGE}
        allowedProjectTypes={nonGLNOWProjectTypesSet}
      >
        <Languages />
      </RestrictedRoute>
    ),
  },
  {
    path: dashboardRC.PAGE_GLOSSARY,
    loader: glossaryLoader(queryClient),
    element: (
      <RestrictedRoute
        allowedRoles={USER_ROLES_GLOSSARY_PAGE}
        allowedProjectTypes={nonGLNOWProjectTypesSet}
      >
        <Glossary />
      </RestrictedRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: dashboardRC.PAGE_NO_TRANSLATE_RULES,
    element: (
      <RestrictedRoute
        allowedRoles={USER_ROLES_NO_TRANSLATE_PAGE}
        allowedProjectTypes={nonGLNOWProjectTypesSet}
      >
        <NoTranslateLanding />
      </RestrictedRoute>
    ),
  },
  {
    path: dashboardRC.PAGE_ACTIVE_BATCHES,
    loader: activeBatchesLoader(queryClient),
    id: 'batches',
    children: [
      {
        index: true,
        element: (
          <RestrictedRoute
            allowedRoles={USER_ROLES_ACTIVE_BATCHES_PAGE}
            allowedProjectTypes={nonGLNOWProjectTypesSet.difference(
              new Set([GLGO_PROJECT_TYPE])
            )}
          >
            <ActiveBatchesPage />
          </RestrictedRoute>
        ),
      },
      {
        path: dashboardRC.PAGE_BATCH_DETAILS,
        loader: batchDetailsLoader(queryClient),
        element: (
          <RestrictedRoute
            allowedRoles={USER_ROLES_ACTIVE_BATCHES_PAGE}
            allowedProjectTypes={nonGLNOWProjectTypesSet.difference(
              new Set([GLGO_PROJECT_TYPE])
            )}
          >
            <BatchDetailsPage />
          </RestrictedRoute>
        ),
      },
      {
        path: dashboardRC.PAGE_BATCH_HISTORY,
        loader: batchHistoryLoader(queryClient),
        element: (
          <RestrictedRoute
            allowedRoles={USER_ROLES_BATCH_HISTORY_PAGE}
            allowedProjectTypes={nonGLNOWProjectTypesSet.difference(
              new Set([GLGO_PROJECT_TYPE])
            )}
          >
            <BatchHistoryPage />
          </RestrictedRoute>
        ),
      },
    ],
  },
];
