import { Card, Stack, Text, Title } from '@mantine/core';
import LanguagesForm from './LanguagesForm';

const LanguageConfiguration = () => {
  return (
    <Card p={25}>
      <Stack gap={5}>
        <Title>Language Configuration</Title>
        <Text mb={10}>
          Define how each language will be accessed on your website
        </Text>
      </Stack>
      <LanguagesForm />
    </Card>
  );
};

export default LanguageConfiguration;
