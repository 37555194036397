import { getColor } from './utils';
export const menueStyles = {
  dropdown: {
    padding: '4px',
    borderRadius: 5,
  },
  item: {
    padding: '5px 10px',
    borderRadius: '5px',
  },
  itemLabel: {
    color: getColor('blue', 6),
    fontWeight: 600,
  },
};
