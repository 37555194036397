import { create } from 'zustand';

export type SegmentStatus = {
  isSaved: boolean;
  isSelected: boolean;
  isUnsaved: boolean;
  isSetToLive: boolean;
  isFailedSave: boolean;
  isAttribute?: boolean;
};
export type SegmentsState = {
  selected: string;
  modified: string[];
  setToLive: string[];
  saved: string[];
  failedSave: string[];
  attributes: BlockAttributes;

  actions: {
    addModified: (hash: string) => void;
    removeModified: (hash: string) => void;
    addSetToLive: (hash: string) => void;
    removeSetToLive: (hash: string) => void;
    resetUnsaved: () => void;
    setFailedSave: (hashes: string[]) => void;
    removeFailedSave: (hash: string) => void;
    setSaved: (hashes: string[]) => void;
    setSelected: (sHash: string) => void;
    resetSelected: () => void;
    resetSegmentsState: (
      attributes: BlockAttributes | null | undefined
    ) => void;
    setAttributes: (attributes: BlockAttributes | null | undefined) => void;
    addAttribute: (
      hashes: string[],
      attributeName: keyof BlockAttributes
    ) => void;
  };
};

const segmentsStore = create<SegmentsState>((set, get) => ({
  selected: '',
  modified: [],
  setToLive: [],
  saved: [],
  failedSave: [],
  deletedTranslations: [],
  attributes: {
    domAttributes: [],
  },
  actions: {
    addModified: (hash: string) => {
      const current = get().modified;
      if (current.includes(hash)) return;
      current.push(hash);
      set({ modified: [...current] });
    },
    addSetToLive: (hash: string) => {
      const current = get().setToLive;
      if (current.includes(hash)) return;
      current.push(hash);
      set({ setToLive: [...current] });
    },
    removeModified: (hash: string) => {
      // if hash is in modified, remove it
      if (!get().modified.includes(hash)) return;
      const removed = get().modified.filter((h) => h !== hash);
      set({ modified: removed });
    },
    removeSetToLive: (hash: string) => {
      // if hash is in modified, remove it
      if (!get().setToLive.includes(hash)) return;
      const removed = get().setToLive.filter((h) => h !== hash);
      set({ setToLive: removed });
    },
    setFailedSave: (hashes: string[]) => set({ failedSave: hashes }),
    resetUnsaved: () => set({ modified: [], setToLive: [] }),
    removeFailedSave: (hash: string) => {
      // if hash is in failedSave, remove it
      if (!get().failedSave.includes(hash)) return;
      const hashes = get().failedSave.filter((h) => h !== hash);
      set({ failedSave: hashes });
    },
    setSaved: (hashes: string[]) => {
      // remove hashes from modified and setToLive
      const modified = get().modified.filter((h) => !hashes.includes(h));
      const setToLive = get().setToLive.filter((h) => !hashes.includes(h));
      const failedSave = get().failedSave.filter((h) => !hashes.includes(h));
      set({ saved: hashes, modified, failedSave, setToLive });
      // clear saved after 3 seconds
      setTimeout(() => {
        set({ saved: [] });
      }, 3000);
    },
    setSelected: (sHash) => set({ selected: sHash }),
    resetSelected: () => set({ selected: '' }),
    resetSegmentsState: (attributes) => {
      set({
        selected: '',
        modified: [],
        setToLive: [],
        saved: [],
        failedSave: [],
        attributes: attributes || { domAttributes: [] },
      });
    },
    setAttributes: (attributes) => {
      if (!attributes) return;
      // Add to existing attributes
      const attr = { ...get().attributes };
      Object.keys(attributes).forEach((key) => {
        const existing = attr[key as keyof BlockAttributes] || [];
        const newHashes = attributes[key as keyof BlockAttributes].filter(
          (hash) => !existing.includes(hash)
        );
        attr[key as keyof BlockAttributes] = [...existing, ...newHashes];
      });
      set({ attributes: attr });
    },
    addAttribute: (hashes, attributeName) => {
      const currentAttributes = { ...get().attributes };
      const current = currentAttributes[attributeName] || [];
      const newHashes = hashes.filter((hash) => !current.includes(hash));
      currentAttributes[attributeName] = [...current, ...newHashes];
      set({ attributes: currentAttributes });
    },
  },
}));

export const useSelected = () => segmentsStore((state) => state.selected);
export const useIsSelected = (hash: string) =>
  segmentsStore((state) => state.selected === hash);
// export const useUnsaved = () => segmentsStore((state) => state.modified);
export const useIsSetToLive = (hash: string) =>
  segmentsStore((state) => state.setToLive.includes(hash));
export const useSetToLive = () => segmentsStore((state) => state.setToLive);
export const useIsModified = (hash: string) =>
  segmentsStore((state) => state.modified.includes(hash));
export const useIsUnsaved = (hash: string) =>
  segmentsStore(
    (state) => state.modified.includes(hash) || state.setToLive.includes(hash)
  );
export const useUnsavedCount = () =>
  segmentsStore((state) => {
    return new Set([...state.modified, ...state.setToLive]).size;
  });
export const useUnsavedTranslations = () =>
  segmentsStore((state) => {
    return new Set([...state.modified, ...state.setToLive]);
  });
export const useSaved = () => segmentsStore((state) => state.saved);
export const useSegmentsAttributes = () =>
  segmentsStore((state) => state.attributes);
export const useDomAttributes = () =>
  segmentsStore((state) => state.attributes.domAttributes);

export const useFailedSave = () => segmentsStore((state) => state.failedSave);
export const useSegmentsStateActions = () =>
  segmentsStore((state) => state.actions);

export const useSegmentStatus = (hash: string): SegmentStatus =>
  segmentsStore((state) => {
    const { selected, modified, setToLive, saved, failedSave, attributes } =
      state;
    const isSelected = selected === hash;
    const isUnsaved = modified.includes(hash) || setToLive.includes(hash);
    const isSetToLive = setToLive.includes(hash);
    const isSaved = saved.includes(hash);
    const isFailedSave = failedSave.includes(hash);
    const isAttribute = attributes.domAttributes.includes(hash);
    return {
      isSelected,
      isUnsaved,
      isSetToLive,
      isSaved,
      isFailedSave,
      isAttribute,
    };
  });
