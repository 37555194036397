import {
  Badge,
  Card,
  Flex,
  Group,
  Image,
  StyleProp,
  Text,
  Title,
} from '@mantine/core';
import { useCurrentProjectData, useViewSize } from 'ui';
import { CircleAvatar } from '../../shared/CircleAvatar';
import React from 'react';
import moment from 'moment/moment';
import { getPhaseIcon, getStatusColor } from '../utils';
import { useDetailedBatch } from '../../../queries/batchesQueries';

export const BasicDetailsCard = () => {
  const {
    batch: { assigned_user, created_utc, due_date_utc, phase_status, phase },
  } = useDetailedBatch();
  const { projectUserPermissionMap } = useCurrentProjectData();
  const { isMobileSize } = useViewSize();
  const assignee = projectUserPermissionMap[assigned_user] ?? ({} as User);
  const { first_name, last_name } = assignee;

  const direction: StyleProp<React.CSSProperties['flexDirection']> =
    isMobileSize ? 'row' : 'column';
  const align = isMobileSize ? 'center' : 'flex-start';
  return (
    <Card>
      <Flex gap={20} direction={direction} align={align} wrap="wrap">
        <Flex gap={5} direction={direction} align={align}>
          <Title order={3}>Assignee</Title>
          <Flex align="center" gap={10}>
            {first_name && (
              <CircleAvatar
                name={first_name}
                backgroundColor="#6ED6CC"
                circleSize={25}
              />
            )}
            <Text c="inherit">
              {first_name} {last_name}
            </Text>
          </Flex>
        </Flex>
        <Flex gap={5} direction={direction} align={align}>
          <Title order={3}>Created</Title>
          {moment.utc(created_utc).format('MM/DD/YYYY')}
        </Flex>
        <Flex gap={5} direction={direction} align={align}>
          <Title order={3}>Due</Title>
          {due_date_utc ? moment.utc(due_date_utc).format('MM/DD/YYYY') : ''}
        </Flex>
        <Flex gap={5} direction={direction} align={align}>
          <Title order={3}>Status</Title>
          {phase_status && (
            <Badge
              bg={getStatusColor(phase_status)}
              c="white"
              radius={4}
              tt="uppercase"
              w={100}
            >
              {phase_status}
            </Badge>
          )}
        </Flex>
        <Flex gap={5} direction={direction} align={align}>
          <Title order={3}>Phase</Title>
          {phase && (
            <Group gap={0} wrap={'nowrap'}>
              <Image
                w={16}
                h={16}
                mr={5}
                src={getPhaseIcon(phase)}
                alt="Phase"
              />
              <Text fw={700} tt="uppercase">
                {phase}
              </Text>
            </Group>
          )}
        </Flex>
      </Flex>
    </Card>
  );
};
