import { Text } from '@mantine/core';
import React from 'react';
import { CustomModal, useGLGOParams } from 'ui';
import { FormValues, updateDomain } from './utils';
import { isSuccessStatus } from 'helpers';
import { notifications } from '@mantine/notifications';

type Props = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  currentDomain: string;
  newDomain: string;
  newStagingDomains: string[];
  resetForm: (data?: FormValues) => void;
};
const ConfirmEditDomainModal = ({
  showModal,
  setShowModal,
  currentDomain,
  newDomain,
  newStagingDomains,
  resetForm,
}: Props) => {
  const { projectKey = '' } = useGLGOParams();

  return (
    <CustomModal
      size="lg"
      title="Confirmation"
      opened={showModal}
      onClose={() => setShowModal(false)}
      footerActions={[
        { label: 'Cancel', action: () => setShowModal(false) },
        {
          label: 'Confirm',
          action: () => {
            updateDomain(newDomain, newStagingDomains, projectKey).then(
              (response) => {
                const {
                  project: {
                    origin_name: originName = '',
                    additional_origins: additionalOrigins = [] as string[],
                  } = {},
                } = response || {};

                if (isSuccessStatus(response.status)) {
                  notifications.show({
                    message: `Successfully updated domain!`,
                  });
                  resetForm({
                    domain: originName,
                    additional_origins: additionalOrigins,
                  });
                } else {
                  notifications.show({
                    message: `Failed to update domain!`,
                    color: 'red',
                  });
                }
                setShowModal(false);
              }
            );
          },
        },
      ]}
    >
      <Text ta="center" fz="md">
        You are about to change your project’s source domain from{' '}
        <Text fz="inherit" fw={600} span>
          {currentDomain}
        </Text>{' '}
        to{' '}
        <Text fz="inherit" fw={600} span>
          {newDomain}
        </Text>
        , please confirm that this is what you want to do.
      </Text>
    </CustomModal>
  );
};

export default ConfirmEditDomainModal;
