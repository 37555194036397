import { Box, Button, Card, Divider, Flex, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import cheet from 'cheet.js';
import { encodeProjectKey } from 'helpers';
import { Dispatch, SetStateAction, useState } from 'react';
import { MdCheckCircle, MdOutlineSync } from 'react-icons/md';
import { CustomModal } from 'ui';
import fathomEventTracker from '../../analytics/fathomAnalytics';
import BrowserWindow from './BrowserWindow';
import { MobileActionHeader } from './MobileActionHeader';
import classes from './PreviewStep.module.css';
import { useSetupStepsContext } from './ProjectSetupProvider';
import SkipInjectionModal from './SkipInjectionModal';
import useInjectionStatus from './hooks/useInjectionStatus';
import { Xapis } from '@glweb/xapis-client';

const SkipForNow = ({
  setIsModalOpen,
}: {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <Text
      id="preview-site-skip-6"
      onClick={() => {
        fathomEventTracker('Preview Site Skip - 6');
        setIsModalOpen(true);
      }}
      style={{ cursor: 'pointer' }}
      c="text.8"
      td="underline"
      fw={600}
    >
      Skip for now.
    </Text>
  );
};

const PreviewBodyContent = () => {
  const {
    project: { project_status, origin_name },
  } = useSetupStepsContext();
  const previewDomain = origin_name ? `//${origin_name}?glgo_preview=1` : '';

  const isInjected = project_status === 'VERIFIED';

  return (
    <>
      <Text fz="md" fw={500}>
        Next, click the “Preview” button to go to your private website preview.
        Remember, your translations will not be publicly visibly until you’re
        ready.
      </Text>
      <Button
        w="8rem"
        mx="auto"
        onClick={() => {
          fathomEventTracker('Preview Site - 6');
          if (previewDomain) {
            window.open(previewDomain);
          }
        }}
      >
        <Text c="inherit" fz="inherit" fw="inherit" id="preview-site-6">
          Preview
        </Text>
      </Button>
      <Text fz="md" fw={500}>
        Once you see the selector, choose from your available languages and the
        page you’re on will be translated instantly. You can edit translations
        and adjust other settings from your dashboard.
      </Text>
      <Flex
        className={classes.injectStatusBox}
        w="100%"
        p={20}
        align="center"
        justify="space-between"
        pos="relative"
        columnGap={10}
      >
        {!isInjected ? (
          <Text fw={700}>Waiting to detect your website...</Text>
        ) : (
          <Text fw={700}>Success! You are ready to GO global!</Text>
        )}
        {!isInjected ? (
          <MdOutlineSync size={20} className={classes.loadingIcon} />
        ) : (
          <MdCheckCircle size={20} color="#008535" />
        )}
      </Flex>
    </>
  );
};

export const PreviewSideMenu = () => {
  const {
    setActiveStep,
    project: { project_key, project_status },
  } = useSetupStepsContext();

  const encodedProjectKey = encodeProjectKey(project_key!);
  const isInjected = project_status === 'VERIFIED';

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <SkipInjectionModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        encodedProjectKey={encodedProjectKey}
      />
      <Flex
        w="100%"
        maw={480}
        rowGap="1.5rem"
        direction="column"
        justify="center"
        align="center"
      >
        <Title fz="2rem" order={1}>
          Preview your site
        </Title>
        <Divider w="100%" maw={450} color="divider.0" size={2} />
        <Text fz="lg" fw={500} ta="center">
          <Text fz="inherit" fw={700} span>
            NOTE:
          </Text>{' '}
          To see any page of your site in preview mode, just add{' '}
          <Text fz="inherit" fw={700} span>
            /?glgo_preview=1
          </Text>{' '}
          to the end of the url.
        </Text>
        <Flex columnGap="1rem">
          <Button
            variant="outline"
            onClick={() => setActiveStep((prevStep) => prevStep - 1)}
          >
            Back
          </Button>
          <Button
            disabled={!isInjected}
            onClick={() => {
              fathomEventTracker('Preview Complete - 6');
              setActiveStep((prevStep) => prevStep + 1);
            }}
          >
            <Text c="inherit" fz="inherit" fw="inherit" id="preview-complete-6">
              Next
            </Text>
          </Button>
        </Flex>
        <SkipForNow setIsModalOpen={setIsModalOpen} />
      </Flex>
    </>
  );
};

export const PreviewBody = () => {
  const {
    project: { project_key },
  } = useSetupStepsContext();

  const [opened, { open, close }] = useDisclosure(false);

  (cheet as unknown as (str: string, cb: () => void) => void)(
    'ctrl x a p i s',
    () => {
      open();
    }
  );

  const handleOverride = () => {
    if (project_key) {
      Xapis.Project.put(project_key, { project_status: 'VERIFIED' });
      close();
    }
  };

  return (
    <Box px="2rem">
      <CustomModal
        title="So You Decided to Cheat..."
        opened={opened}
        onClose={close}
        footerActions={[
          {
            label: 'Bypass Snippet Check',
            action: handleOverride,
          },
        ]}
      />
      <BrowserWindow>
        <Flex w="100%" direction="column" rowGap="1rem">
          <PreviewBodyContent />
        </Flex>
      </BrowserWindow>
    </Box>
  );
};

export const MobilePreviewStep = () => {
  const {
    setActiveStep,
    project: { project_key },
  } = useSetupStepsContext();

  const isInjected = useInjectionStatus();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const encodedProjectKey = encodeProjectKey(project_key!);

  return (
    <>
      <SkipInjectionModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        encodedProjectKey={encodedProjectKey}
      />
      <Card
        w="100%"
        maw={{ base: '100%', xs: 650, lg: 450 }}
        px="1rem"
        py="2rem"
      >
        <Flex
          w="100%"
          h="100%"
          direction="column"
          align="center"
          rowGap="1.5rem"
        >
          <MobileActionHeader
            title="Add Code Snippet"
            isNextButtonDisabled={!isInjected}
          />
          <Divider w="100%" color="divider.0" size={2} />
          <PreviewBodyContent />
          <Divider w="100%" color="divider.0" size={2} />
          <Button
            w={{ base: '100%', xs: 'auto' }}
            disabled={!isInjected}
            onClick={() => {
              fathomEventTracker('Preview Complete - 6');
              setActiveStep((prevStep) => prevStep + 1);
            }}
          >
            <Text c="inherit" fz="inherit" fw="inherit" id="preview-complete-6">
              Next
            </Text>
          </Button>
          <SkipForNow setIsModalOpen={setIsModalOpen} />
        </Flex>
      </Card>
    </>
  );
};
