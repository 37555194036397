import {
  Button,
  Checkbox,
  Flex,
  Text,
  Popover,
  Stack,
  UnstyledButton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FilterIcon } from 'images';
import React, { useRef, useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { Form, useSearchParams, useSubmit } from 'react-router-dom';
import { Header, useCurrentProjectData } from 'ui';
import classes from '../selectors-filters/MultiSelector.module.css';
import {
  BATCH_PHASE_REVIEW,
  BATCH_PHASE_TESTING,
  BATCH_PHASE_TRANSLATION,
  BATCH_PHASE_VALIDATION,
  BATCH_STATUS_INPROGRESS,
  BATCH_STATUS_READY,
  BATCH_STATUS_TODO,
  mergeParams,
} from './utils';
import { useViewPermissions } from 'helpers';

export const FiltersDropdown = ({
  assigneeOptions,
  history = false,
}: {
  assigneeOptions: string[];
  history?: boolean;
}) => {
  const { projectUserPermissionMap } = useCurrentProjectData();
  const [searchParams] = useSearchParams();
  const formRef = useRef<HTMLFormElement>(null);
  const submit = useSubmit();
  const { hasInternalRole } = useViewPermissions();
  const canFilterByPhase = hasInternalRole;

  const existingAssignees = searchParams.get('assignedUser')?.split(',') ?? [];
  const existingPhases = searchParams.get('phase')?.split(',') ?? [];
  const existingStatuses = searchParams.get('status')?.split(',') ?? [];

  const [filters, setFilters] = useState({
    assignees: existingAssignees,
    phases: existingPhases,
    statuses: existingStatuses,
  });

  const [opened, { open, close }] = useDisclosure();

  const clearAll = () => {
    setFilters({ assignees: [], phases: [], statuses: [] });
    submit(mergeParams(formRef.current!, searchParams, true));
    close();
  };

  return (
    <Popover
      opened={opened}
      onChange={() => (opened ? close() : open())}
      onOpen={() => {
        setFilters({
          assignees: existingAssignees,
          phases: existingPhases,
          statuses: existingStatuses,
        });
      }}
    >
      <Popover.Target>
        <Button
          leftSection={<FilterIcon c="var(--mantine-color-font1-1)" />}
          onClick={opened ? close : open}
          variant="transparent"
          classNames={{
            root: classes.filterButton,
            inner: classes.filterText,
          }}
          rightSection={<MdArrowDropDown size={24} />}
        >
          Filters
        </Button>
      </Popover.Target>
      <Popover.Dropdown w={300}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            submit(mergeParams(e.currentTarget, searchParams));
          }}
          ref={formRef}
        >
          <Header
            title={'ASSIGNEE'}
            flexContainerProps={{ mih: 0, mb: 5 }}
            variant="h3"
          />
          <Popover
            withinPortal={false}
            disabled={!assigneeOptions.length}
            width={'target'}
          >
            <Popover.Target>
              <UnstyledButton
                disabled={!assigneeOptions.length}
                w="100%"
                bd="1px solid border1.0"
                style={{ borderRadius: 5 }}
                p={8}
              >
                <Flex justify="space-between">
                  Assignee
                  <MdArrowDropDown
                    size={24}
                    color="var(--mantine-color-font1-1)"
                  />
                </Flex>
              </UnstyledButton>
            </Popover.Target>
            <Popover.Dropdown>
              <Checkbox.Group value={filters.assignees}>
                {assigneeOptions.map((tkey) => {
                  const assignee =
                    projectUserPermissionMap[tkey] ?? ({} as User);
                  const { first_name, last_name } = assignee;
                  return (
                    <Button
                      variant={'transparent'}
                      onClick={() => {
                        const newAssignees = filters.assignees.includes(tkey)
                          ? filters.assignees.filter(
                              (assignee) => assignee !== tkey
                            )
                          : [...filters.assignees, tkey];
                        setFilters({ ...filters, assignees: newAssignees });
                      }}
                      key={tkey}
                      leftSection={<Checkbox value={tkey} />}
                      p={0}
                      mih={0}
                      h="100%"
                      style={{ borderRadius: 0 }}
                    >
                      <Text>
                        {first_name} {last_name}
                      </Text>
                    </Button>
                  );
                })}
              </Checkbox.Group>
            </Popover.Dropdown>
          </Popover>
          <input
            hidden
            name={'assignedUser'}
            value={filters.assignees.join(',')}
            readOnly
          />
          {!history && (
            <input
              hidden
              name={'phase'}
              value={filters.phases.join(',')}
              readOnly
            />
          )}
          <input
            hidden
            name={'status'}
            value={filters.statuses.join(',')}
            readOnly
          />
          {!history && canFilterByPhase && (
            <>
              <Header title={'PHASE'} variant="h3" />
              <Checkbox.Group
                value={filters.phases}
                onChange={(phases) => setFilters({ ...filters, phases })}
              >
                <Stack gap={5}>
                  <Checkbox
                    value={BATCH_PHASE_TRANSLATION}
                    label={'Translation'}
                  />
                  <Checkbox value={BATCH_PHASE_TESTING} label={'Testing'} />
                  <Checkbox value={BATCH_PHASE_REVIEW} label={'Review'} />
                  <Checkbox
                    value={BATCH_PHASE_VALIDATION}
                    label={'Validation'}
                  />
                </Stack>
              </Checkbox.Group>
            </>
          )}
          <Header title={'STATUS'} variant="h3" />
          <Checkbox.Group
            value={filters.statuses}
            onChange={(statuses) => setFilters({ ...filters, statuses })}
          >
            {history ? (
              <Stack gap={5}>
                <Checkbox value={'completed'} label={'Completed'} />
                <Checkbox value={'cancelled'} label={'Cancelled'} />
              </Stack>
            ) : (
              <Stack gap={5}>
                <Checkbox value={BATCH_STATUS_TODO} label={'To Do'} />
                <Checkbox
                  value={BATCH_STATUS_INPROGRESS}
                  label={'In Progress'}
                />
                <Checkbox value={BATCH_STATUS_READY} label={'Ready'} />
              </Stack>
            )}
          </Checkbox.Group>
          <Flex justify={'space-between'} mt={10}>
            <Button variant={'outline'} onClick={clearAll}>
              Clear All
            </Button>
            <Button type="submit" onClick={close}>
              Apply
            </Button>
          </Flex>
        </Form>
      </Popover.Dropdown>
    </Popover>
  );
};
