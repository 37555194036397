import React from 'react';
import {
  BackgroundImage,
  Button,
  Divider,
  Image,
  Flex,
  Text,
  Card,
  Title,
  MantineFontSize,
} from '@mantine/core';
import { useSetupStepsContext } from './ProjectSetupProvider';
import AddSnippetBackground from './images/add-snippet-background.svg';
import AddSnippetPerson from './images/add-snippet-person.svg';

const AddCodeSnippetText = ({ fz }: { fz: MantineFontSize }) => {
  const { setActiveStep } = useSetupStepsContext();
  return (
    <>
      <Title fz={fz === 'md' ? '1.5rem' : '2rem'} order={1}>
        Add Code Snippet
      </Title>
      <Divider w="100%" color="divider.0" size={2} />
      <Text fz={fz} fw={500} ta="center">
        You’ll need to add a snippet of JavaScript to your website. But don't
        worry! Your site will not be visibly affected and no translations will
        be publicly available until you say so.
        <br />
        <br />
        You’ll be able to preview everything first and when you’re confident
        everything looks good you can set it to public.
      </Text>
      <Button
        w={{ base: '100%', xs: 'auto' }}
        onClick={() => {
          setActiveStep((prevStep) => prevStep + 1);
        }}
      >
        Next
      </Button>
    </>
  );
};

export const AddSnippetSideMenu = () => {
  return (
    <Flex
      w="100%"
      maw={450}
      rowGap="1.5rem"
      direction="column"
      justify="center"
      align="center"
    >
      <AddCodeSnippetText fz={'lg'} />
    </Flex>
  );
};

export const AddSnippetBody = () => (
  <BackgroundImage
    w="100%"
    h="100%"
    src={`"${AddSnippetBackground}"`}
    style={{
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right center',
    }}
    pl="3rem"
    pr="2rem"
  >
    <Flex w="100%" h="100%" align="center" justify="center">
      <Image
        w="100%"
        maw="45rem"
        h="auto"
        src={AddSnippetPerson}
        alt="Person on file step copying GLGO translation code snippet"
      />
    </Flex>
  </BackgroundImage>
);

export const MobileAddSnippetStep = () => {
  return (
    <Card w="100%" maw={{ base: '100%', xs: 650, lg: 450 }} px="1rem" py="2rem">
      <Flex w="100%" h="100%" direction="column" align="center" rowGap="1.5rem">
        <AddCodeSnippetText fz={'md'} />
      </Flex>
    </Card>
  );
};
