import { Card, Stack, Text, Title } from '@mantine/core';
import DeploymentMethodChoices from './DeploymentMethodChoices';

const DeploymentMethod = () => {
  return (
    <Card p={25}>
      <Stack gap={5}>
        <Title>Choose Your Deployment Method</Title>
        <Text mb={10}>
          Select how you want to handle different language versions of your
          website
        </Text>
      </Stack>
      <DeploymentMethodChoices />
    </Card>
  );
};
export default DeploymentMethod;
