import { CustomModal } from 'ui';
import { Xapis } from '@glweb/xapis-client';
import { notifications } from '@mantine/notifications';
import { isSuccessStatus } from 'helpers';

type Props = {
  urlHashes: string[];
  batchKey: string;
  translationKey: string;
  opened: boolean;
  onClose: () => void;
};
const UnapproveURLsModal = ({
  urlHashes,
  batchKey,
  translationKey,
  onClose,
  opened,
}: Props) => {
  const handleReviewBatches = () => {
    Xapis.BatchURLs.patch({
      batchKey,
      translationKey,
      hashes: urlHashes,
      isReviewed: false,
    })
      .then((result) => {
        if (!isSuccessStatus(result.status)) {
          notifications.show({
            message: `Error while marking segments as not reviewed`,
            color: 'red',
          });
        } else {
          notifications.show({
            message: `Successfully marked segments as not reviewed`,
          });
        }
      })
      .finally(onClose);
  };

  return (
    <CustomModal
      onClose={onClose}
      opened={opened}
      footerActions={[
        { label: 'Cancel', action: onClose },
        {
          label: 'Confirm',
          action: handleReviewBatches,
        },
      ]}
      title={`Mark all segments in this URL as NOT reviewed?`}
    />
  );
};
export default UnapproveURLsModal;
