import React, { useState } from 'react';
import {
  Box,
  Modal,
  Button,
  Group,
  Select,
  Stack,
  Textarea,
  Text,
  Menu,
  Flex,
} from '@mantine/core';
import classes from './ReviewSegment.module.css';
import { themeColors } from '../../theme/colors';
import { FlagIcon } from '../../icons/IndicatorIcons';
import { getColor } from '../../theme/utils';
import { StoreApi, useStore } from 'zustand';
import { EditHistoryStore } from '../../store/EditHistoryStore';
import { useBatchReviewActions } from '../../store/BatchReviewStore';
import { menueStyles } from '../../theme/styles';

const TYPE_OPTIONS = [
  { value: 'terminology', label: 'Terminology' },
  { value: 'grammar', label: 'Grammar' },
  { value: 'spelling', label: 'Spelling/Punctuation' },
  { value: 'mistranslation', label: 'Mistranslation' },
  { value: 'accuracy', label: 'Accuracy' },
  { value: 'preferencial', label: 'Preferencial' },
];

const SEVERITY_OPTIONS = [
  { value: 'minor', label: 'Minor' },
  { value: 'major', label: 'Major' },
  { value: 'critical', label: 'Critical' },
];

interface ClearableSelectProps {
  label: string;
  placeholder: string;
  data: { value: string; label: string }[];
  value: string | null;
  onChange: (value: string | null) => void;
  required?: boolean;
}

const ClearableSelect = ({
  label,
  placeholder,
  data,
  value,
  onChange,
  required,
}: ClearableSelectProps) => (
  <Select
    label={label}
    placeholder={placeholder}
    data={data}
    value={value}
    onChange={onChange}
    clearable
    required={required}
    onClear={() => onChange(null)}
    rightSectionProps={{
      style: {
        pointerEvents: value ? 'auto' : undefined,
      },
    }}
    comboboxProps={{
      withArrow: false,
      styles: {
        dropdown: {
          border: '1px solid gray',
        },
      },
    }}
  />
);

export const ReviewSegment = ({
  segment,
  store,
}: {
  segment: BatchSegment;
  store: StoreApi<EditHistoryStore>;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const { updateReviewedSegments } = useBatchReviewActions();

  const { segmentReview } = useStore(store);
  const { setSegmentReview } = useStore(store).actions;

  const isReviewed =
    segmentReview && typeof segmentReview.is_reviewed === 'boolean'
      ? segmentReview.is_reviewed
      : segment.is_reviewed || false;

  const savedValues: SegmentReview = {
    is_reviewed: segment.is_reviewed || false,
    grade: segment.grade || '',
    severity: segment.severity || '',
    comment: segment.comment || '',
  };
  const currentValues: Omit<SegmentReview, 'is_reviewed'> = {
    grade: segmentReview?.grade || savedValues.grade,
    severity: segmentReview?.severity || savedValues.severity,
    comment: segmentReview?.comment || savedValues.comment,
  };
  const [reviewValues, setReviewValues] = useState(currentValues);

  const { grade, severity, comment } = reviewValues;
  const hasRating = !!currentValues.grade && !!currentValues.severity;
  const invalidRating = (!grade && !!severity) || (!!grade && !severity);

  const hasChanges = Object.keys(reviewValues).some(
    (key) =>
      reviewValues[key as keyof typeof reviewValues] !==
      currentValues[key as keyof typeof currentValues]
  );

  const isUpdateDisabled = invalidRating || !hasChanges;
  const showFlag = hasRating || !!currentValues.comment;

  const handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    setMenuOpened(true);
  };

  const onChangeValue = (value: string | null, field: keyof SegmentReview) => {
    if (field === 'comment' && value && value.length > 250) return;

    setReviewValues({ ...reviewValues, [field]: value });
  };

  const updateIsReviewed = () => {
    setSegmentReview({
      is_reviewed: !isReviewed === savedValues.is_reviewed ? null : !isReviewed,
    });
    updateReviewedSegments(segment.segment_hash, !isReviewed);
  };
  const handleUpdate = () => {
    const updatedValues: Partial<SegmentReview> = { ...reviewValues };
    Object.entries(updatedValues).forEach(([key, value]) => {
      if (value === savedValues[key as keyof typeof savedValues]) {
        updatedValues[key as keyof typeof updatedValues] = null;
      }
    });
    setSegmentReview(updatedValues);
    setIsModalOpen(false);
  };

  const undoChanges = () => {
    setSegmentReview(null);
    setReviewValues(savedValues);
  };

  const handleAddRating = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event bubbling
    setIsModalOpen(true);
    setMenuOpened(false);
  };

  return (
    <>
      <Box
        className={classes.reviewInfo}
        onClick={updateIsReviewed}
        onContextMenu={handleContextMenu}
        style={{
          backgroundColor: isReviewed ? themeColors.green[2] : 'white',
          borderColor: isReviewed ? themeColors.green[6] : themeColors.gray[3],
        }}
      >
        <Menu
          shadow="md"
          withinPortal
          withArrow={false}
          position="right"
          opened={menuOpened}
          onChange={setMenuOpened}
          closeOnItemClick={false}
          styles={menueStyles}
        >
          <Menu.Target>
            <Flex align="center" justify="center">
              {showFlag && <FlagIcon color={getColor('blue', 8)} size={16} />}
            </Flex>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item onClick={handleAddRating}>Add Rating</Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Box>
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onClick={(ev) => ev.stopPropagation()} // Prevent selection of segment
        title="Rating"
        size="sm"
        centered
        styles={{
          content: {
            padding: '10px',
          },
          header: {
            alignItems: 'center',
            minHeight: 'unset',
          },
          title: {
            padding: 0,
            margin: 0,
            fontSize: '18px',
            fontWeight: 600,
          },
        }}
      >
        <Stack
          gap="md"
          pt="md"
          style={{
            borderTop: '1px solid var(--mantine-color-gray-3)',
          }}
        >
          <ClearableSelect
            label="Type"
            placeholder="Select type"
            data={TYPE_OPTIONS}
            value={grade}
            onChange={(value) => onChangeValue(value, 'grade')}
            required={!!severity}
          />
          <ClearableSelect
            label="Severity"
            placeholder="Select severity"
            data={SEVERITY_OPTIONS}
            value={severity}
            onChange={(value) => onChangeValue(value, 'severity')}
            required={!!grade}
          />
          <Box>
            <Textarea
              label="NOTES"
              placeholder="Add notes..."
              value={comment || ''}
              onChange={(ev) => onChangeValue(ev.target.value, 'comment')}
              autosize
              minRows={2}
              maxRows={6}
              styles={{
                label: {
                  color: getColor('blue', 8),
                  fontWeight: 600,
                },
              }}
            />
            <Text ta="right" fw={600}>
              {comment?.length || 0} / 250 characters
            </Text>
          </Box>
          <Group justify="flex-end" mt="md">
            <Button variant="outline" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            {/* <Button onClick={undoChanges} disabled={!hasUnsavedChanges}>
              Undo
            </Button> */}
            <Button onClick={handleUpdate} disabled={isUpdateDisabled}>
              Update
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};
