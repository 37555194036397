import {
  Box,
  Divider,
  Flex,
  Image,
  MantineSpacing,
  StyleProp,
  Text,
  useMantineTheme,
} from '@mantine/core';
import {
  DARK_MODE_THEME,
  LIGHT_MODE_THEME,
} from '../settings/selector-customization/CustomThemeButton';
import {
  BOTTOM_LEFT_POSITION,
  positionOptions,
} from './LanguageSelectorAppearances';
import classes from './LanguageSelectorStyle.module.css';
import { useSetupStepsContext } from './ProjectSetupProvider';
import DarkLanguageSelector from './images/dark-language-selector.svg';
import LightLanguageSelector from './images/light-language-selector.svg';
import { getLanguageSelectorImage } from './utils';
import { useViewSize } from 'ui';

const ColorOptions = () => {
  const { colors } = useMantineTheme();
  const {
    themePosition: { theme: selectedTheme },
    setThemePosition,
    status: { isCreatingProject },
  } = useSetupStepsContext();

  const isDarkTheme = selectedTheme === DARK_MODE_THEME;

  return (
    <Box>
      <Text fw={500} fz="md">
        COLOR
      </Text>
      <Text fw={400} c="text.9">
        Choose the color scheme of the language selector.
      </Text>
      <Flex direction="row" columnGap="1rem" mt="0.5rem">
        <Box miw={100} maw={120}>
          <Image
            w="100%"
            height="auto"
            src={LightLanguageSelector}
            className={classes.selectorOption}
            style={{
              border: !isDarkTheme ? `2px solid ${colors.border[8]}` : 'none',
              boxShadow: !isDarkTheme
                ? '0px 5px 5px rgba(5, 53, 140, 0.15)'
                : 'none',
            }}
            onClick={() => {
              !isCreatingProject &&
                setThemePosition((prevThemePosition) => ({
                  ...prevThemePosition,
                  theme: LIGHT_MODE_THEME,
                }));
            }}
            alt="Light theme language selector"
          />
        </Box>
        <Box miw={100} maw={120}>
          <Image
            w="100%"
            height="auto"
            src={DarkLanguageSelector}
            className={classes.selectorOption}
            style={{
              border: isDarkTheme ? `2px solid ${colors.border[8]}` : 'none',
              boxShadow: isDarkTheme
                ? '0px 5px 5px rgba(5, 53, 140, 0.15)'
                : 'none',
            }}
            onClick={() =>
              !isCreatingProject &&
              setThemePosition((prevThemePosition) => ({
                ...prevThemePosition,
                theme: DARK_MODE_THEME,
              }))
            }
            alt="Dark theme language selector"
          />
        </Box>
      </Flex>
    </Box>
  );
};

const PositionOptions = () => {
  const { colors } = useMantineTheme();
  const {
    themePosition: { position: selectedPosition },
    setThemePosition,
    status: { isCreatingProject },
  } = useSetupStepsContext();
  return (
    <>
      <Text fw={500} fz="md">
        POSITION
      </Text>
      <Text fw={400} c="text.9">
        Choose the location of the language selector.
      </Text>
      <Flex w="100%" mt="0.5rem" columnGap={20} rowGap={20} wrap="wrap">
        {positionOptions.map(
          ({ position = BOTTOM_LEFT_POSITION, src, alt = '' }) => {
            const isPositionActive = position === selectedPosition;
            return (
              <Image
                key={position}
                src={src}
                height="auto"
                maw={100}
                className={classes.selectorOption}
                style={{
                  border: `2px solid ${colors.border[isPositionActive ? 8 : 7]}`,
                  boxShadow: isPositionActive
                    ? '0px 5px 5px rgba(5, 53, 140, 0.15)'
                    : 'none',
                }}
                onClick={() =>
                  !isCreatingProject &&
                  setThemePosition((prevThemePosition) => ({
                    ...prevThemePosition,
                    position,
                  }))
                }
                alt={alt}
              />
            );
          }
        )}
      </Flex>
    </>
  );
};

const SelectorAppearanceImage = () => {
  const { isDesktopSize } = useViewSize();
  const {
    themePosition: { theme: selectedTheme, position: selectedPosition },
  } = useSetupStepsContext();

  return (
    <Image
      pt={isDesktopSize ? ('2rem' as StyleProp<MantineSpacing>) : undefined}
      width="100%"
      maw={isDesktopSize ? 300 : 450}
      height="auto"
      mx={!isDesktopSize ? ('auto' as StyleProp<MantineSpacing>) : undefined}
      src={getLanguageSelectorImage(selectedTheme, selectedPosition)}
      alt="GlobalLink WEB Language Selector Appearance"
    />
  );
};

export const LanguageSelectorStyle = () => {
  const { isDesktopSize } = useViewSize();

  if (isDesktopSize) {
    return (
      <Box w="100%">
        <Flex columnGap="1rem">
          <ColorOptions />
          <SelectorAppearanceImage />
        </Flex>
        <PositionOptions />
      </Box>
    );
  }

  return (
    <Flex w="100%" direction="column" rowGap="2rem">
      <Flex w="100%" direction="column" rowGap="1rem">
        <ColorOptions />
        <Box>
          <PositionOptions />
        </Box>
      </Flex>
      <Divider w="100%" color="divider.0" size={2} />
      <SelectorAppearanceImage />
    </Flex>
  );
};
