import { LoadingSkeleton, useCurrentProjectData, useGLGOParams } from 'ui';
import { defaultSubscription } from '@glweb/xapis-client';
import { Suspense, useState } from 'react';
import { useRecommendedThresholdContext } from '../../../providers/RecommendedThresholdProvider';
import { getStatusBannerBody } from '../../utils';
import GoLiveModal from '../modals/GoLiveModal';
import { Grid } from '@mantine/core';
import { DashboardCard } from '../DashboardCard';
import { StatusBanner } from '../../shared/StatusBanner';
import MetricsSummaryHeader from '../metrics-summary/MetricsSummaryHeader';
import MetricsSummary from '../metrics-summary/MetricsSummary';
import WordsServedEstimateHeader from '../words-served-estimate/WordsServedEstimateHeader';
import WordsServedEstimate from '../words-served-estimate/WordsServedEstimate';
import WordsServedUsageHeader from '../words-served-usage/WordsServedUsageHeader';
import WordsServedUsage from '../words-served-usage/WordsServedUsage';
import PlanDetailsHeader from '../plan-details/PlanDetailsHeader';
import PlanDetails from '../plan-details/PlanDetails';
import MyLanguagesHeader from '../my-languages/MyLanguagesHeader';
import MyLanguages from '../my-languages/MyLanguages';
import useDashboardData from './useDashboardData';

const GlGoDashboard = () => {
  const { projectId, projectKey = '' } = useGLGOParams();

  const [showGoLiveModal, setShowGoLiveModal] = useState(false);

  const thresholdFromEstimate = useRecommendedThresholdContext().threshold;
  const { project } = useCurrentProjectData();

  const {
    subscriptions,
    monthlyWordsServed,
    prevWordsServed,
    wordsServed,
    worldTraffic,
    translatedTraffic,
    quickQuote,
  } = useDashboardData();

  const { translations: targets = [] } = project;

  const activeProjectStatus = project?.project_status || '';
  const isGLWEBEnterprisePlan = project?.project_type === 'OLJS';
  const isGLGOEnterprisePlan = project?.project_type === 'GLGO-ENTERPRISE';
  const isExpiredPlan = project?.subscription_status === 'expired';
  const hasSku = Boolean(project.subscription_sku);
  const isGLWEBEnterpriseWithoutSku = !hasSku && isGLWEBEnterprisePlan;

  const {
    threshold_value = 0,
    words_served = 0,
    price = 0,
  } = subscriptions[0] || {};

  const hasActiveSubscription = !isExpiredPlan && subscriptions.length > 0;
  const isFreePlan = price === 0;

  const thresholdValue = hasActiveSubscription ? threshold_value : 0;
  const wordsServedAmount = hasActiveSubscription
    ? parseInt(words_served || defaultSubscription.words_served)
    : 0;

  const statusBannerBody = getStatusBannerBody(
    isExpiredPlan,
    isFreePlan,
    activeProjectStatus,
    setShowGoLiveModal,
    projectId || '',
    thresholdFromEstimate
  );

  const [showEstimate, setShowEstimate] = useState(
    isFreePlan && Boolean(quickQuote?.qmessage?.includes('result was cached'))
  );

  if (!project || !projectKey) {
    return <></>;
  }

  return (
    <>
      <GoLiveModal setOpened={setShowGoLiveModal} opened={showGoLiveModal} />
      <Grid grow gutter="sm" align="stretch">
        <DashboardCard
          isVisible={Boolean(statusBannerBody)}
          isCard={false}
          maxColSpan={12}
        >
          <StatusBanner
            statusBody={statusBannerBody}
            statusType={isExpiredPlan ? 'subscription' : 'translations'}
          />
        </DashboardCard>

        <DashboardCard maxColSpan={8} header={<MetricsSummaryHeader />}>
          <MetricsSummary
            timeSlices={translatedTraffic.slices}
            prevTimeSlices={translatedTraffic.prevSlices}
            worldTraffic={worldTraffic}
            wordsServed={wordsServed}
            prevWordsServed={prevWordsServed}
          />
        </DashboardCard>

        <DashboardCard
          isVisible={showEstimate}
          maxColSpan={8}
          header={
            <WordsServedEstimateHeader setShowEstimate={setShowEstimate} />
          }
        >
          <Suspense fallback={<LoadingSkeleton />}>
            <WordsServedEstimate quickQuote={quickQuote} />
          </Suspense>
        </DashboardCard>

        <DashboardCard
          isVisible={!showEstimate}
          maxColSpan={8}
          header={<WordsServedUsageHeader />}
        >
          <Suspense fallback={<LoadingSkeleton />}>
            <WordsServedUsage
              thresholdValue={thresholdValue}
              monthlyWordsServed={monthlyWordsServed}
            />
          </Suspense>
        </DashboardCard>

        <DashboardCard isCard={false}>
          <DashboardCard
            isNested
            isVisible={!isGLGOEnterprisePlan && !isGLWEBEnterpriseWithoutSku}
            header={<PlanDetailsHeader />}
          >
            <PlanDetails
              isExpiredPlan={isExpiredPlan}
              subscriptions={subscriptions}
              wordsServedAmount={wordsServedAmount}
            />
          </DashboardCard>

          <DashboardCard isNested flex={2} header={<MyLanguagesHeader />}>
            <MyLanguages targets={targets} />
          </DashboardCard>
        </DashboardCard>
      </Grid>
    </>
  );
};
export default GlGoDashboard;
