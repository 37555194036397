import { dashboardRC } from '@glweb/constants';
import { Box, Group, Image, Stack } from '@mantine/core';
import GLWEBLogoColor from 'images/logos/glweb-logo-color.svg';
import GLWEBLogoLight from 'images/logos/glweb-logo-light.svg';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useViewSize, Wait } from 'ui';
import { useSetupStepsContext } from '../../components/setup/ProjectSetupProvider';
import StepsSwitch from '../../components/setup/StepsSwitch';
import StepsTimeline from '../../components/setup/StepsTimeline';
import { trimUrl } from '../../components/setup/utils';
import { CircleAvatar } from '../../components/shared/CircleAvatar';
export default function ProjectSetupSteps() {
  const { isDesktopSize } = useViewSize();
  const {
    activeStep,
    setActiveStep,
    url,
    project: { project_key, project_name },
  } = useSetupStepsContext();

  const projectName = project_name || trimUrl(url.domain, 'name');

  const isInformativeStep =
    activeStep === 0 || activeStep === 4 || activeStep === 7;

  useEffect(() => {
    if (project_key) {
      setActiveStep(4);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // on mount

  if (activeStep === 4 && !project_key) {
    return <Wait text="Loading project" />;
  }

  if (!isDesktopSize) {
    return (
      <>
        <Group
          h={48}
          w="100%"
          px="1rem"
          bg="background1.0"
          pos="fixed"
          justify="space-between"
          align="center"
          style={{
            zIndex: 999,
          }}
        >
          <Link to={dashboardRC.PAGE_ROOT}>
            <Image h={20} src={GLWEBLogoLight} alt="GlobalLink WEB logo" />
          </Link>
          {activeStep > 1 && (
            <CircleAvatar name={projectName} backgroundColor="#6ED6CC" />
          )}
        </Group>
        <Stack w="100%" pt="5rem" pb="2rem" gap="2rem" align="center">
          <StepsTimeline />
          <StepsSwitch contentType="mobile" />
        </Stack>
      </>
    );
  }

  return (
    <Group
      w="100%"
      h="100vh"
      mih={800}
      wrap="nowrap"
      justify="flex-end"
      bg="background.0"
      gap={0}
    >
      <Stack
        w="39%"
        h="100%"
        align="center"
        justify="center"
        bg="background.1"
        px="2rem"
        pos="relative"
        style={{
          borderTopRightRadius: 20,
          borderBottomRightRadius: 20,
          boxShadow: '-7px 4px 17px 0px rgba(7, 29, 73, 1)',
          zIndex: 20,
        }}
      >
        <StepsSwitch contentType="side-menu" />
        <Box pos="absolute" top="2rem" left="2rem">
          <Link to={dashboardRC.PAGE_ROOT}>
            <Image h={30} src={GLWEBLogoColor} alt="GlobalLink WEB logo" />
          </Link>
        </Box>
      </Stack>
      <Stack w="62%" h="100%" justify="center" gap={0} pos="relative">
        {!isInformativeStep && (
          <Box w="100%" mt="2rem" pos="absolute" top="1rem">
            <StepsTimeline />
          </Box>
        )}
        <Stack
          pt={isInformativeStep ? 0 : '5rem'}
          w="100%"
          h={isInformativeStep ? '100%' : 'fit-content'}
        >
          <StepsSwitch contentType="body" />
        </Stack>
      </Stack>
    </Group>
  );
}
