import { NOOP, filterTranslations } from 'helpers';
import React, { createContext, useCallback, useContext, useState } from 'react';
import {
  ProjectResponse,
  ProjectTranslationResponse,
  Xapis,
} from '@glweb/xapis-client';
import { useCurrentProjectData, useGLGOParams } from 'ui';
import {
  COOKIE,
  DOMAIN,
  FOLDER,
  FormData,
  QUERY,
  SelectedMethod,
} from './deploymentUtils';

type Context = {
  showChangeMethodModal: boolean;
  setShowChangeMethodModal: React.Dispatch<React.SetStateAction<boolean>>;
  showConfirmMethodModal: boolean;
  setShowConfirmMethodModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedMethod: SelectedMethod;
  setSelectedMethod: React.Dispatch<React.SetStateAction<SelectedMethod>>;
  projectKey: string;
  activeProject: ProjectKey | undefined;
  setActiveProject: React.Dispatch<React.SetStateAction<ProjectKey>>;
  targets: TranslationKey[];
  setTargets: React.Dispatch<React.SetStateAction<TranslationKey[]>>;
  updateDeploymentDetails: (
    data: FormData
  ) => Promise<[ProjectResponse, ...ProjectTranslationResponse[]]>;
};

const defaultContext = {
  showChangeMethodModal: false,
  setShowChangeMethodModal: NOOP,
  showConfirmMethodModal: false,
  setShowConfirmMethodModal: NOOP,
  selectedMethod: '',
  setSelectedMethod: NOOP,
  projectKey: '',
  activeProject: undefined,
  setActiveProject: NOOP,
  targets: [],
  setTargets: NOOP,
  updateDeploymentDetails: () => Promise.reject([undefined, []]),
};

const DeploymentContext = createContext<Context>(defaultContext);

function DeploymentProvider({ children }: { children?: React.ReactNode }) {
  const { projectKey = '' } = useGLGOParams();
  const { project, project: { translations = [], origin_name = '' } = {} } =
    useCurrentProjectData();

  const [showChangeMethodModal, setShowChangeMethodModal] = useState(false);
  const [showConfirmMethodModal, setShowConfirmMethodModal] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState<SelectedMethod>('');

  const [activeProject, setActiveProject] = useState(project);
  const [targets, setTargets] = useState(filterTranslations(translations));

  const updateDeploymentDetails = useCallback(
    async (data: FormData) => {
      if (!projectKey) {
        return Promise.reject([]);
      }

      const {
        deploymentValues = [],
        deploymentMethod,
        deploymentName,
      } = data || {};

      const shouldUpdateDeploymentValues =
        deploymentValues.length > 0 || !!deploymentMethod;

      const shouldUpdateDeployment = deploymentMethod || deploymentName;

      const shouldUseDefaultDeploymentValues = !!deploymentMethod;

      const projectData = deploymentMethod
        ? {
            deployment_method: deploymentMethod,
            deployment_name: deploymentName,
          }
        : {
            deployment_name: deploymentName,
          };

      const updateProjectDeployment = shouldUpdateDeployment
        ? Xapis.Project.put(projectKey, projectData)
        : undefined;

      const getDefaultDeploymentMethodValue = (target: TranslationKey) => {
        const targetLangCode = target.target_lang_code.toLowerCase();
        const parsedDomain = origin_name.replaceAll('www.', '');

        switch (deploymentMethod) {
          case FOLDER:
            return `/${targetLangCode}/`;
          case DOMAIN:
            return `${targetLangCode}.${parsedDomain}`;
          case QUERY:
          case COOKIE:
          default:
            return targetLangCode;
        }
      };

      const updateTargetDeploymentValues = shouldUpdateDeploymentValues
        ? targets.map((target, index) => {
            const { translation_key, deployment_value } = target;
            const currentValueString = Array.isArray(deployment_value)
              ? deployment_value.join(',')
              : deployment_value;

            const updatedValue = shouldUseDefaultDeploymentValues
              ? getDefaultDeploymentMethodValue(target)
              : deploymentValues[index] || [];
            const updatedValueString = Array.isArray(updatedValue)
              ? updatedValue.join(',')
              : updatedValue;

            if (currentValueString !== updatedValueString) {
              return Xapis.ProjectTranslation.put(projectKey, translation_key, {
                deployment_value: updatedValueString,
              });
            }
            return undefined;
          })
        : [];

      return Promise.all([
        updateProjectDeployment,
        ...updateTargetDeploymentValues,
      ])
        .then((responses) => {
          const [projectResponse, ...targetResponses] = responses || [];

          if (projectResponse?.project) {
            setActiveProject(projectResponse.project);
          }

          if (targetResponses && targetResponses.length > 0) {
            const filteredTargetResponses = targetResponses.filter(
              (response) => {
                if (response && response.data) {
                  return true;
                }
                return false;
              }
            ) as ProjectTranslationResponse[];

            const updatedFilteredTargets = filterTranslations(
              filteredTargetResponses.map(
                ({ data }) => data
              ) as TranslationKey[]
            );

            setTargets((prevTargets) =>
              [...prevTargets].map((prevTarget) => {
                const { target_lang_code = '' } = prevTarget || {};
                const updateTargetFound = updatedFilteredTargets.find(
                  (updatedTarget) => {
                    return updatedTarget.target_lang_code === target_lang_code;
                  }
                );
                if (updateTargetFound) {
                  return updateTargetFound;
                }
                return prevTarget;
              })
            );
          }
          return responses;
        })
        .catch((errors) => {
          return errors;
        });
    },
    [projectKey, targets]
  );

  return (
    <DeploymentContext.Provider
      value={{
        showChangeMethodModal,
        setShowChangeMethodModal,
        showConfirmMethodModal,
        setShowConfirmMethodModal,
        selectedMethod,
        setSelectedMethod,
        projectKey,
        activeProject,
        setActiveProject,
        targets,
        setTargets,
        updateDeploymentDetails,
      }}
    >
      {children}
    </DeploymentContext.Provider>
  );
}

function useDeploymentContext() {
  const context = useContext(DeploymentContext);
  if (!context) throw new Error('Not inside the Provider');
  return context;
}
export { DeploymentProvider, useDeploymentContext };
