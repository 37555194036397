import {
  activeBatchesQuery,
  batchDetailsQuery,
  batchHistoryQuery,
} from '../queries/batchesQueries';
import { decodeProjectId } from 'helpers';
import { Params, redirect } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';

export type ActiveBatches = {
  batches: ActiveBatch[];
  assigneeOptions: string[];
};

export type HistoryBatches = {
  batches: HistoryBatch[];
  assigneeOptions: string[];
};

export type DetailedBatch = {
  batch: BatchDetailsResponse;
  allBatches: ActiveBatch[];
};

export const activeBatchesLoader =
  (queryClient: QueryClient) =>
  async ({ params }: { params: Params<string> }) => {
    const { projectId } = params || {};

    if (!projectId) {
      throw new Response('Project key is not defined', {
        status: 404,
        statusText: 'Project key is not defined',
      });
    }
    const projectKey = decodeProjectId(projectId);

    const data = await queryClient.ensureQueryData(
      activeBatchesQuery(projectKey)
    );

    return data as ActiveBatches;
  };

export const batchDetailsLoader =
  (queryClient: QueryClient) =>
  async ({ request, params }: { params: Params<string>; request: Request }) => {
    const searchParams = new URL(request.url).searchParams;
    const batchKey = searchParams.get('batch');

    const { projectId } = params || {};

    if (!projectId) {
      throw new Response('Project key is not defined', {
        status: 404,
        statusText: 'Project key is not defined',
      });
    }
    const projectKey = decodeProjectId(projectId);

    const { batches } = await queryClient.ensureQueryData(
      activeBatchesQuery(projectKey)
    );
    const batch = batches.find(({ batch_key }) => batch_key === batchKey);

    if (!batchKey || !batch || !batch.translation_key) {
      //return to active batches page
      return redirect('..');
    }
    return await queryClient.ensureQueryData(
      batchDetailsQuery(projectKey, batchKey)
    );
  };

export const batchHistoryLoader =
  (queryClient: QueryClient) =>
  async ({ params }: { params: Params<string> }) => {
    const { projectId } = params || {};

    if (!projectId) {
      throw new Response('Project key is not defined', {
        status: 404,
        statusText: 'Project key is not defined',
      });
    }
    const projectKey = decodeProjectId(projectId);

    const data = await queryClient.ensureQueryData(
      batchHistoryQuery(projectKey)
    );

    return data as HistoryBatches;
  };
