import { LoadingSkeleton, useCurrentProjectData, useGLGOParams } from 'ui';
import { Suspense, useState } from 'react';
import GoLiveModal from '../modals/GoLiveModal';
import { Grid } from '@mantine/core';
import { DashboardCard } from '../DashboardCard';
import MTUsageHeader from '../mt-usage/MTUsageHeader';
import { MTUsage } from '../mt-usage/MTUsage';

const GlNowDashboard = () => {
  const { projectKey = '' } = useGLGOParams();
  const [showGoLiveModal, setShowGoLiveModal] = useState(false);
  const { project } = useCurrentProjectData();

  if (!project || !projectKey) {
    return <></>;
  }

  return (
    <>
      <GoLiveModal setOpened={setShowGoLiveModal} opened={showGoLiveModal} />
      <Grid grow gutter="sm" align="stretch">
        <DashboardCard maxColSpan={12} header={<MTUsageHeader />}>
          <Suspense fallback={<LoadingSkeleton />}>
            <MTUsage thresholdValue={0} />
          </Suspense>
        </DashboardCard>
      </Grid>
    </>
  );
};
export default GlNowDashboard;
