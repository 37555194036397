import {
  internalUserRoles,
  USER_ROLE_ADMIN,
  USER_ROLE_BILLING,
  USER_ROLE_CONTRACTOR,
  USER_ROLE_DEVELOPER,
  USER_ROLE_LINGUIST,
  USER_ROLE_PROJECT_MANAGER,
  USER_ROLE_SALES_LEAD,
} from 'helpers';

const constants = {
  GTM_TAG_ID: 'GTM-P2DG2MG6',
};

export const USER_ROLES_DASHBOARD_PAGE = new Set([
  USER_ROLE_ADMIN,
  USER_ROLE_PROJECT_MANAGER,
  USER_ROLE_LINGUIST,
  USER_ROLE_BILLING,
  USER_ROLE_DEVELOPER,
  USER_ROLE_SALES_LEAD,
  USER_ROLE_CONTRACTOR,
  ...internalUserRoles,
] as const);
export const USER_ROLES_ACTIVE_BATCHES_PAGE = new Set([
  USER_ROLE_ADMIN,
  USER_ROLE_PROJECT_MANAGER,
  USER_ROLE_LINGUIST,
  ...internalUserRoles,
] as const);
export const USER_ROLES_BATCH_HISTORY_PAGE = new Set([
  USER_ROLE_ADMIN,
  USER_ROLE_PROJECT_MANAGER,
  ...internalUserRoles,
] as const);
export const USER_ROLES_LANGUAGES_PAGE = new Set([
  USER_ROLE_ADMIN,
  USER_ROLE_PROJECT_MANAGER,
  USER_ROLE_LINGUIST,
  USER_ROLE_CONTRACTOR,
  ...internalUserRoles,
] as const);
export const USER_ROLES_GLOSSARY_PAGE = new Set([
  USER_ROLE_ADMIN,
  USER_ROLE_PROJECT_MANAGER,
  USER_ROLE_LINGUIST,
  USER_ROLE_CONTRACTOR,
  ...internalUserRoles,
] as const);
export const USER_ROLES_NO_TRANSLATE_PAGE = new Set([
  USER_ROLE_ADMIN,
  USER_ROLE_PROJECT_MANAGER,
  USER_ROLE_LINGUIST,
  USER_ROLE_DEVELOPER,
  ...internalUserRoles,
] as const);

export const USER_ROLES_TRANSLATED_TRAFFIC_PAGE = new Set([
  USER_ROLE_ADMIN,
  USER_ROLE_PROJECT_MANAGER,
  USER_ROLE_BILLING,
  USER_ROLE_SALES_LEAD,
  ...internalUserRoles,
] as const);
export const USER_ROLES_WORLD_TRAFFIC_PAGE = new Set([
  USER_ROLE_ADMIN,
  USER_ROLE_PROJECT_MANAGER,
  USER_ROLE_BILLING,
  USER_ROLE_SALES_LEAD,
  ...internalUserRoles,
] as const);
export const USER_ROLES_LANGUAGE_SETTINGS_PAGE = new Set([
  USER_ROLE_ADMIN,
  USER_ROLE_PROJECT_MANAGER,
  USER_ROLE_DEVELOPER,
  ...internalUserRoles,
] as const);
export const USER_ROLES_LANGUAGE_SELECTOR_PAGE = new Set([
  USER_ROLE_ADMIN,
  USER_ROLE_PROJECT_MANAGER,
  USER_ROLE_DEVELOPER,
  ...internalUserRoles,
] as const);
export const USER_ROLES_INSTALLATION_PAGE = new Set([
  USER_ROLE_ADMIN,
  USER_ROLE_PROJECT_MANAGER,
  USER_ROLE_DEVELOPER,
  ...internalUserRoles,
] as const);
export const USER_ROLES_PROJECT_SCOPE_PAGE = new Set([
  USER_ROLE_ADMIN,
  USER_ROLE_PROJECT_MANAGER,
  ...internalUserRoles,
] as const);
export const USER_ROLES_USER_MANAGEMENT_PAGE = new Set([
  USER_ROLE_ADMIN,
  USER_ROLE_SALES_LEAD,
  USER_ROLE_PROJECT_MANAGER,
  USER_ROLE_BILLING,
  ...internalUserRoles,
] as const);
export const USER_ROLES_PROFILE_PAGE = new Set([
  USER_ROLE_ADMIN,
  USER_ROLE_PROJECT_MANAGER,
  USER_ROLE_LINGUIST,
  USER_ROLE_BILLING,
  USER_ROLE_DEVELOPER,
  USER_ROLE_SALES_LEAD,
  USER_ROLE_CONTRACTOR,
  ...internalUserRoles,
] as const);
export const USER_ROLES_WORDS_SERVED_PAGE = new Set([
  USER_ROLE_ADMIN,
  USER_ROLE_PROJECT_MANAGER,
  USER_ROLE_BILLING,
  USER_ROLE_SALES_LEAD,
  ...internalUserRoles,
] as const);
export const USER_ROLES_MT_USAGE_PAGE = new Set([
  USER_ROLE_ADMIN,
  USER_ROLE_PROJECT_MANAGER,
  USER_ROLE_BILLING,
  USER_ROLE_SALES_LEAD,
  ...internalUserRoles,
] as const);
export const USER_ROLES_BILLING_PAGE = new Set([
  USER_ROLE_ADMIN,
  USER_ROLE_BILLING,
  USER_ROLE_SALES_LEAD,
  ...internalUserRoles,
] as const);
export const USER_ROLES_IMPERSONATE_MODE = new Set(internalUserRoles);

const C = constants;
export default C;
