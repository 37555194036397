import { SVGAttributes } from 'react';

type SvgProps = { svg: { path: string; attr?: SVGAttributes<SVGElement> } };

type IconProps = {
  color?: string;
  size?: number;
  fill?: string;
};
const Icon = ({ svg }: SvgProps) => {
  const { path, attr } = svg;
  return (
    <svg
      width={attr?.width || '20'}
      height={attr?.height || '20'}
      viewBox={attr?.viewBox || '0 0 20 20'}
      fill={attr?.fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d={path}
          stroke={attr?.stroke || 'currentColor'}
          strokeWidth={attr?.strokeWidth || 1}
          strokeLinecap={attr?.strokeLinecap || 'round'}
          strokeLinejoin={attr?.strokeLinejoin || 'round'}
        />
      </g>
    </svg>
  );
};

export const AttributeIcon = () => {
  const svg = {
    path: 'M3.5 3.6 16.2 16.2M8.9 8.9C8.7 9.2 8.5 9.6 8.5 10 8.5 10.4 8.7 10.8 8.9 11.1 9.2 11.3 9.6 11.5 10 11.5 10.4 11.5 10.8 11.3 11.1 11.1M8 5C8.7 4.9 9.3 4.8 10 4.8 13.1 4.8 15.6 6.5 17.7 10 17 11 16.4 11.9 15.8 12.6M14.1 14.1C12.9 14.9 11.5 15.2 10 15.2 6.9 15.2 4.4 13.5 2.4 10 3.4 8.2 4.6 6.9 5.9 6',
    attr: {
      strokeWidth: 1.6,
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const LockIcon = ({ color, size }: IconProps) => {
  const svg = {
    path: 'M 15.7 9.2 H 14.5 V 5.3 C 14.5 4.1 13.4 3.1 12.3 3.1 H 7.8 C 6.6 3.1 5.5 4.1 5.5 5.3 V 9.2 H 4.5 C 4 9.2 3.8 9.5 3.8 9.8 V 16.6 C 3.8 16.9 4 17.1 4.5 17.1 H 15.7 C 16 17.1 16.1 16.9 16.1 16.6 V 9.8 C 16.1 9.5 16 9.2 15.7 9.2 Z M 10.4 13.4 V 14.3 C 10.4 14.5 10.4 14.5 10.3 14.5 H 9.7 C 9.6 14.5 9.6 14.5 9.6 14.3 V 13.4 C 9.4 13.2 9.3 13.1 9.3 13 C 9.1 12.8 9.1 12.7 9.3 12.5 C 9.3 12.3 9.4 12.2 9.6 12 C 9.7 11.9 9.9 11.9 10 11.9 C 10.1 11.9 10.3 11.9 10.4 12 C 10.6 12.2 10.8 12.3 10.8 12.5 C 10.9 12.7 10.9 12.8 10.8 13 C 10.8 13.1 10.6 13.2 10.4 13.4 Z M 13.1 9.2 H 6.8 V 5.3 C 6.8 4.8 7.3 4.4 7.8 4.4 H 12.3 C 12.7 4.4 13.1 4.8 13.1 5.3 V 9.2 Z',
    attr: {
      fill: color || '#637392',
      width: size || 20,
      height: size || 20,
      stroke: 'none',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const Success = ({ size, color }: IconProps) => {
  const svg = {
    path: 'M 4 10 C 6.1 11.6 7.6 12.9 9 16 C 10.7 10.8 12.8 8.2 16 4',
    attr: {
      width: size || 20,
      height: size || 20,
      viewBox: '0 0 20 20',
      stroke: color || 'currentColor',
      strokeWidth: 1.8,
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const SuccessSave = () => {
  const svg = {
    path: 'M3.8 15V5.1C3.8 4.3 4.4 3.7 5.1 3.7H13.1C13.4 3.7 13.8 3.9 14.1 4.2L16 6.1C16.3 6.4 16.4 6.7 16.4 7.1V15C16.3 15.8 15.8 16.4 15 16.3H5.1C4.4 16.3 3.8 15.8 3.8 15ZM7.6 7.9H12.3C12.6 7.9 12.8 7.8 12.8 7.6V4.2C12.8 3.9 12.6 3.7 12.3 3.7H7.6C7.3 3.7 7.1 3.9 7.1 4.2V7.6C7.1 7.8 7.3 7.9 7.6 7.9ZM5.7 9.4 9.3 14 15.3 6.7',
    attr: {
      strokeWidth: 1.6,
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const Unsaved2 = () => {
  const svg = {
    path: 'M2 2 18 18M7.5 3.5H13C13 3.5 16.4 6.8 16.5 7V12.4M3.5 7.5V14.5C3.5 16 4 16.4 5.5 16.5H12.5M12.5 3.5V6.4C12.5 6.8 12.4 7 12 7H11',
    attr: {
      strokeWidth: 1.6,
      stroke: 'currentColor',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};
export const UnSaved = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.8 15V5.1C3.8 4.3 4.4 3.7 5.1 3.7H13.1C13.4 3.7 13.8 3.9 14.1 4.2L16 6.1C16.3 6.4 16.4 6.7 16.4 7.1V15C16.3 15.8 15.8 16.4 15 16.3H5.1C4.4 16.3 3.8 15.8 3.8 15ZM7.6 7.9H12.3C12.6 7.9 12.8 7.8 12.8 7.6V4.2C12.8 3.9 12.6 3.7 12.3 3.7H7.6C7.3 3.7 7.1 3.9 7.1 4.2V7.6C7.1 7.8 7.3 7.9 7.6 7.9Z"
        stroke="currentColor"
        strokeWidth="1.6"
      />
      <rect
        width="26"
        height="5"
        transform="matrix(-0.7 -0.7 -0.7 0.7 21 18)"
        fill="white"
      />
      <line
        x1="4"
        y1="-1"
        x2="24"
        y2="-1"
        transform="matrix(-0.7 -0.7 -0.7 0.7 19 21)"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const AlertIcon = ({
  size,
  color,
}: {
  size?: number;
  color?: string;
}) => {
  const svg = {
    path: 'M10 1.5C8.3 1.5 6.7 2 5.3 2.9 3.9 3.9 2.8 5.2 2.1 6.7 1.5 8.3 1.3 10 1.7 11.7 2 13.3 2.8 14.8 4 16 5.2 17.2 6.7 18 8.3 18.3 10 18.7 11.7 18.5 13.3 17.9 14.8 17.2 16.1 16.1 17.1 14.7 18 13.3 18.5 11.7 18.5 10 18.5 7.7 17.6 5.6 16 4 14.4 2.4 12.3 1.5 10 1.5ZM10 17.1C8.6 17.1 7.2 16.7 6.1 15.9 4.9 15.1 4 14 3.5 12.7 2.9 11.4 2.8 10 3.1 8.6 3.3 7.2 4 6 5 5 6 4 7.2 3.3 8.6 3.1 10 2.8 11.4 2.9 12.7 3.5 14 4 15.1 4.9 15.9 6.1 16.7 7.2 17.1 8.6 17.1 10 17.1 10.9 16.9 11.9 16.5 12.7 16.2 13.6 15.7 14.4 15 15 14.4 15.7 13.6 16.2 12.7 16.5 11.9 16.9 10.9 17.1 10 17.1ZM10 11.5C9.8 11.5 9.5 11.4 9.3 11.2 9.2 11 9.1 10.8 9.1 10.5V6.3C9.1 6.1 9.2 5.8 9.3 5.6 9.5 5.5 9.8 5.4 10 5.4 10.2 5.4 10.5 5.5 10.7 5.6 10.8 5.8 10.9 6.1 10.9 6.3V10.5C10.9 10.8 10.8 11 10.7 11.2 10.5 11.4 10.2 11.5 10 11.5ZM10 14.6C10.6 14.6 11 14.1 11 13.6 11 13 10.6 12.5 10 12.5 9.4 12.5 8.9 13 8.9 13.6 8.9 14.1 9.4 14.6 10 14.6Z',
    attr: {
      width: size || 20,
      height: size || 20,
      fill: color || 'currentColor',
      stroke: 'none',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};
export const WarningIcon = ({
  size,
  color,
}: {
  size?: number;
  color?: string;
}) => {
  const svg = {
    path: 'M 4 18 L 15.9 18 C 17.3 18 18 16.5 17.6 15.6 L 11.9 3.4 C 11.1 1.8 8.9 1.8 8.1 3.5 L 2.5 15.5 C 2 16.6 2.7 18 4 18 Z M 9.1 4 C 9.5 3.1 10.6 3.1 11 4 L 16.4 15.5 C 16.7 16.4 16.3 17 15.8 17 L 4.3 17 C 3.7 17 3.4 16.3 3.7 15.5 L 9 4.1 Z M 11 14.5 C 11 16 9 16 9 14.5 V 14.5 C 9.1 13.2 11 13.2 11 14.5 Z M 10.7 7.5 C 10.7 6.6 9.3 6.6 9.4 7.5 L 9.3 11.4 C 9.3 12.6 10.6 12.6 10.7 11.6 L 10.7 7.5 Z',
    attr: {
      width: size || 20,
      height: size || 20,
      fill: color || 'currentColor',
      stroke: 'none',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const InfoIcon = ({
  size,
  color,
}: {
  size?: number;
  color?: string;
}) => {
  const svg = {
    path: 'M 10 18.5 C 11.7 18.5 13.3 18 14.7 17.1 C 16.1 16.1 17.2 14.8 17.9 13.3 C 18.5 11.7 18.7 10 18.3 8.3 C 18 6.7 17.2 5.2 16 4 C 14.8 2.8 13.3 2 11.7 1.7 C 10 1.3 8.3 1.5 6.7 2.1 C 5.2 2.8 3.9 3.9 2.9 5.3 C 2 6.7 1.5 8.3 1.5 10 C 1.5 12.3 2.4 14.4 4 16 C 5.6 17.6 7.7 18.5 10 18.5 Z M 10 2.9 C 11.4 2.9 12.8 3.3 13.9 4.1 C 15.1 4.9 16 6 16.5 7.3 C 17.1 8.6 17.2 10 16.9 11.4 C 16.7 12.8 16 14 15 15 C 14 16 12.8 16.7 11.4 16.9 C 10 17.2 8.6 17.1 7.3 16.5 C 6 16 4.9 15.1 4.1 13.9 C 3.3 12.8 2.9 11.4 2.9 10 C 2.9 9.1 3.1 8.1 3.5 7.3 C 3.8 6.4 4.3 5.6 5 5 C 5.6 4.3 6.4 3.8 7.3 3.5 C 8.1 3.1 9.1 2.9 10 2.9 Z M 10 8.5 C 10.2 8.5 10.5 8.6 10.7 8.8 C 10.8 9 10.9 9.2 10.9 9.5 V 13.7 C 10.9 13.9 10.8 14.2 10.7 14.4 C 10.5 14.5 10.2 14.6 10 14.6 C 9.8 14.6 9.5 14.5 9.3 14.4 C 9.2 14.2 9.1 13.9 9.1 13.7 V 9.5 C 9.1 9.2 9.2 9 9.3 8.8 C 9.5 8.6 9.8 8.5 10 8.5 Z M 10 5.4 C 9.4 5.4 9 5.9 9 6.4 C 9 7 9.4 7.5 10 7.5 C 10.6 7.5 11.1 7 11.1 6.4 C 11.1 5.9 10.6 5.4 10 5.4 Z',
    attr: {
      width: size || 20,
      height: size || 20,
      fill: color || 'currentColor',
      stroke: 'none',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};
export const FlagIcon = ({
  size,
  color,
}: {
  size?: number;
  color?: string;
}) => {
  const svg = {
    path: 'M12.1 3.3C12.6 3.3 12.8 3.9 12.5 4.3L12.5 4.3 10.3 6.5 12.5 8.8C12.8 9.1 12.6 9.7 12.1 9.7L12.1 9.7H4.5V13.3C4.5 13.4 4.4 13.5 4.4 13.6 4.3 13.7 4.1 13.8 4 13.8L3.9 13.8C3.8 13.8 3.6 13.8 3.5 13.7 3.4 13.6 3.4 13.5 3.3 13.3L3.3 13.2V3.9C3.3 3.8 3.4 3.6 3.5 3.5 3.6 3.4 3.7 3.4 3.8 3.3L3.9 3.3H12.1',
    attr: {
      width: size || 16,
      height: size || 16,
      viewBox: '0 0 16 16',
      fill: color || 'currentColor',
      stroke: 'none',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const CheckMarkIcon = ({
  size,
  borderColor,
  fillColor,
  markColor,
}: {
  size?: number;
  borderColor?: string;
  fillColor?: string;
  markColor?: string;
}) => {
  return (
    <svg
      width={size || '20'}
      height={size || '20'}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 10C18 12.1 17.2 14.2 15.7 15.7 14.2 17.2 12.1 18 10 18 7.9 18 5.8 17.2 4.3 15.7 2.8 14.2 2 12.1 2 10 2 7.9 2.8 5.8 4.3 4.3 5.8 2.8 7.9 2 10 2 12.1 2 14.2 2.8 15.7 4.3 17.2 5.8 18 7.9 18 10Z"
        fill={fillColor || 'transparent'}
        stroke={borderColor || fillColor || 'gray'}
      />
      <path
        d="M6 10 9.536 13.5 13.5 7"
        fill="none"
        stroke={markColor || 'white'}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
