import {
  getUserKey,
  Xapis,
} from '@glweb/xapis-client/src/xapis-wrappers/xapis';

export const startSpiderJob = async (
  yyTranslationKey: string,
  projectName: string
) => {
  const userKey = getUserKey();

  const body = {
    job_name: `${projectName}-topology-crawl`,
    user_key: userKey,
    debug_level: 0,
    status: 'START',
    seo_only: 'OFF',
    max_spider_minutes: 10,
  };

  return await Xapis.SpiderJob.post(yyTranslationKey, body);
};
