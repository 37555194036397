import { create } from 'zustand';

export type ReviewStore = {
  unsavedReviews: Set<string>; // Set of segment hashes with unsaved review changes
  reviewedSegments: Set<string>; // Set of segment hashes that have been reviewed

  actions: {
    updateUnsavedReviews: (segmentHashe: string, isUnsaved: boolean) => void;
    // update reviewed segments
    updateReviewedSegments: (segmentHashe: string, isReviewed: boolean) => void;
    // Clear all unsaved reviews
    clearUnsavedReviews: () => void;
    // Initialize reviewed segments with a list of hashes
    initReviewedSegments: (segmentHashes: string[]) => void;
  };
};

const batchReviewStore = create<ReviewStore>((set, get) => ({
  unsavedReviews: new Set<string>(),
  reviewedSegments: new Set<string>(),

  actions: {
    updateUnsavedReviews: (segmentHashe: string, isUnsaved: boolean) => {
      const current = get().unsavedReviews;
      if (isUnsaved) {
        current.add(segmentHashe);
      } else {
        current.delete(segmentHashe);
      }
      set({ unsavedReviews: current });
    },
    updateReviewedSegments: (segmentHashe: string, isReviewed: boolean) => {
      const current = get().reviewedSegments;
      if (isReviewed) {
        current.add(segmentHashe);
      } else {
        current.delete(segmentHashe);
      }
      set({ reviewedSegments: current });
    },
    clearUnsavedReviews: () => set({ unsavedReviews: new Set<string>() }),
    initReviewedSegments: (segmentHashes: string[]) =>
      set({ reviewedSegments: new Set(segmentHashes) }),
  },
}));

export const useBatchReviewState = () =>
  batchReviewStore((state) => ({
    reviewedSegments: state.reviewedSegments,
    unsavedReviews: state.unsavedReviews,
  }));
export const useBatchReviewActions = () =>
  batchReviewStore((state) => state.actions);
export const useUnsavedReviews = () =>
  batchReviewStore((state) => state.unsavedReviews);
export const useUnsavedReviewsCount = () =>
  batchReviewStore((state) => state.unsavedReviews.size);
export const useReviewedSegments = () =>
  batchReviewStore((state) => state.reviewedSegments);
export const useReviewedSegmentsCount = () =>
  batchReviewStore((state) => state.reviewedSegments.size);
