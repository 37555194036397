import React, { createContext, useContext, useEffect, useState } from 'react';
import { Xapis } from '../http/xapis';
import { isSuccessStatus } from 'helpers';

export interface SegmentEditorConfig {
  target: TranslationKey;
  user: User;
  batch?: {
    batchKey: string;
    urlHash: string;
    translationKey: string;
  };
  glossary?: GlossaryItem[];
  settings: {
    xapisHost: string; // XAPIS host origin, 'https://www.onelink-edge.com/' for example
    accessToken: string; // access Token
    contextMode: boolean; // In context mode or not
    viewOnly?: boolean; // View only mode
    disableTagEdit?: boolean; // Disable adding/deleting of (html) tags
    htmlDir?: 'auto' | 'ltr' | 'rtl';
  };
  contextAPI?: {
    onResetFilter?: () => void; // Actions to do when the user resets the filters
    onSelect?: (data: { segmentHash: string; blocks?: string[] }) => void; // Actions to do when the user selects a segment
    onTranslationChange?: (data: {
      segments: PreviewSegment[];
      blocks?: string[];
    }) => void; // Runs when a translation is modified
    onReviewChange?: (data: StudioModeData) => void; // Runs when a translation is modified
  };
}

const initialConfig: SegmentEditorConfig = {
  target: null as unknown as TranslationKey,
  glossary: [],
  user: { user_key: '' },
  settings: {
    xapisHost: 'http://localhost',
    accessToken: 'DefaultAccessToken',
    contextMode: false,
    viewOnly: false,
    disableTagEdit: false,
  },
  contextAPI: {},
};

interface SEContext {
  config: SegmentEditorConfig;
  target: TranslationKey;
  batch?: {
    batchKey: string;
    urlHash: string;
    translationKey: string;
  };
  isBatchMode: boolean;
  translationKey: string;
  translationMethods: string[];
  user: User;
  glossary: GlossaryItem[];
  userKey: string;
  settings: SegmentEditorConfig['settings'];
  contextAPI: SegmentEditorConfig['contextAPI'];
}

const SegmentEditorContext = createContext<SEContext>({
  config: initialConfig,
  target: null as unknown as TranslationKey,
  batch: null as unknown as {
    batchKey: string;
    urlHash: string;
    translationKey: string;
  },
  isBatchMode: false,
  glossary: [],
  translationKey: '',
  translationMethods: [],
  user: initialConfig.user,
  userKey: '',
  settings: initialConfig.settings,
  contextAPI: initialConfig.contextAPI,
});

export const segmenetEditorGlobals = {
  version: '2.0.1',
  appName: 'GLWeb Segment Editor',
  translationKey: '',
  userKey: '',
};

export function useSegmentEditorContext() {
  const context = useContext(SegmentEditorContext);
  if (!context) {
    throw new Error(
      'SegmentEditorContext must be used within a SegmentEditorProvider'
    );
  }
  return context;
}

export function SegmentEditorProvider({
  children,
  config,
}: {
  children: React.ReactNode;
  config: SegmentEditorConfig;
}) {
  const {
    user,
    glossary: initialGlossary,
    target,
    batch,
    settings,
    contextAPI,
  } = config;
  const [glossary, setGlossary] = useState<GlossaryItem[]>(
    initialGlossary || []
  );

  const fetchGlossary = async () => {
    try {
      const { status, glossary } = await Xapis.Glossary.get(
        user.user_key,
        target.translation_key
      );
      if (isSuccessStatus(status)) {
        setGlossary(glossary?.glossary_items || []);
      }
    } catch (error) {
      console.error('Error fetching glossary:', error);
    }
  };

  useEffect(() => {
    if (!initialGlossary && target.translation_key && user.user_key) {
      fetchGlossary();
    }
  }, [target.translation_key, user.user_key, initialGlossary]);

  settings.viewOnly = settings.viewOnly || target.translation_edit === false;
  const translationMethods = target?.translation_methods
    ? target.translation_methods.split(',')
    : [];
  const isBatchMode = Boolean(
    batch &&
      batch.translationKey === target.translation_key &&
      batch.urlHash &&
      batch.batchKey
  );

  segmenetEditorGlobals.translationKey = target?.translation_key || '';
  segmenetEditorGlobals.userKey = user.user_key || '';

  return (
    <SegmentEditorContext.Provider
      value={{
        config,
        glossary,
        target,
        batch,
        translationKey: target?.translation_key || '',
        translationMethods,
        user,
        userKey: user.user_key || '',
        settings,
        contextAPI,
        isBatchMode,
      }}
    >
      {children}
    </SegmentEditorContext.Provider>
  );
}
