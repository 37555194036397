import PositionTopLeft from './images/position-top-left.svg';
import PositionTopRight from './images/position-top-right.svg';
import PositionBottomLeft from './images/position-bottom-left.svg';
import PositionBottomRight from './images/position-bottom-right.svg';
import { DARK_MODE_THEME } from '../settings/selector-customization/CustomThemeButton';

export const DARK_THEME = 'dark' as const;
export const LIGHT_THEME = 'light' as const;
const SELECTOR_THEMES = [DARK_THEME, LIGHT_THEME];
export type LanguageSelectorTheme = (typeof SELECTOR_THEMES)[number] | string;

export const TOP_POSITION = 'top' as const;
export const BOTTOM_POSITION = 'bottom' as const;
export const LEFT_POSITION = 'left' as const;
export const RIGHT_POSITION = 'right' as const;
export type TopBottomPosition = typeof TOP_POSITION | typeof BOTTOM_POSITION;
export type LeftRightPosition = typeof LEFT_POSITION | typeof RIGHT_POSITION;
export type LanguageSelectorPosition =
  `${TopBottomPosition}_${LeftRightPosition}`;

export const TOP_LEFT_POSITION = `${TOP_POSITION}_${LEFT_POSITION}` as const;
export const TOP_RIGHT_POSITION = `${TOP_POSITION}_${RIGHT_POSITION}` as const;
export const BOTTOM_LEFT_POSITION =
  `${BOTTOM_POSITION}_${LEFT_POSITION}` as const;
export const BOTTOM_RIGHT_POSITION =
  `${BOTTOM_POSITION}_${RIGHT_POSITION}` as const;

export const getLanguageSelectorPositions = (
  languageSelectorPosition: LanguageSelectorPosition
) => {
  const [topBottom = BOTTOM_POSITION, leftRight = LEFT_POSITION] =
    languageSelectorPosition.split('_');
  return { topBottom, leftRight } as {
    topBottom: TopBottomPosition;
    leftRight: LeftRightPosition;
  };
};

export type AppearanceOption = {
  position: LanguageSelectorPosition;
  src: string;
  alt: string;
};

export const positionOptions: AppearanceOption[] = [
  {
    position: TOP_LEFT_POSITION,
    src: PositionTopLeft as string,
    alt: 'Select top left widget position',
  },
  {
    position: TOP_RIGHT_POSITION,
    src: PositionTopRight as string,
    alt: 'Select top right widget position',
  },
  {
    position: BOTTOM_LEFT_POSITION,
    src: PositionBottomLeft as string,
    alt: 'Select bottom left widget position',
  },
  {
    position: BOTTOM_RIGHT_POSITION,
    src: PositionBottomRight as string,
    alt: 'Select bottom right widget position',
  },
];

const WIDTH_DEFAULT = 175;

export type SelectorDefault = {
  position: LanguageSelectorPosition | undefined;
  theme: LanguageSelectorTheme | undefined;
  width: number | undefined;
};

const getCSSComment = (
  topBottom: TopBottomPosition = BOTTOM_POSITION,
  leftRight: LeftRightPosition = LEFT_POSITION,
  theme: LanguageSelectorTheme = DARK_THEME,
  width = WIDTH_DEFAULT
) =>
  `/* LANGUAGE_SELECTOR_POSITION: "${topBottom}_${leftRight}" LANGUAGE_SELECTOR_THEME: "${theme}" LANGUAGE_SELECTOR_WIDTH: "${width}" */`;

// Will also match on comments with only Position and Theme (for backwards compatibility)
const selectorDefaultsRegex = new RegExp(
  `LANGUAGE_SELECTOR_POSITION: "([^"]*)" LANGUAGE_SELECTOR_THEME: "([^"]*)"(?: LANGUAGE_SELECTOR_WIDTH: "([^"]*)")*`
);

export const isPresetCss = (customCss: string) => {
  return selectorDefaultsRegex.test(customCss);
};

export const getCSSPreferences = (
  customCSSOrTranslations: string | ProjectKey['translations']
) => {
  let customCSS;

  if (typeof customCSSOrTranslations === 'string') {
    customCSS = customCSSOrTranslations;
  } else {
    const yyTarget = customCSSOrTranslations?.find(
      ({ target_lang_code }) => target_lang_code === 'YY'
    );
    const { custom_css = '' } = yyTarget || {};
    if (custom_css) {
      customCSS = custom_css;
    }
  }

  if (!customCSS || !isPresetCss(customCSS)) {
    return {
      position: undefined,
      theme: undefined,
      width: undefined,
    } as SelectorDefault;
  }

  const [, position = 'bottom_right', theme = 'light', width = WIDTH_DEFAULT] =
    customCSS.match(selectorDefaultsRegex) || [];
  return {
    position,
    theme,
    width: Number(width),
  } as SelectorDefault;
};

const getThemeColors = (theme: LanguageSelectorTheme = DARK_THEME) => {
  switch (theme) {
    case LIGHT_THEME:
      return {
        backgroundColor: 'white',
        highlightColor: 'rgba(255, 255, 255, .7)',
        textColor: 'black',
      };

    case DARK_THEME:
      return {
        backgroundColor: 'black',
        highlightColor: 'rgba(0, 0, 0, .5)',
        textColor: 'white',
      };
    default:
      return {
        backgroundColor: theme.split('_')[0],
        textColor: theme.split('_')[1],
        highlightColor: theme.split('_')[2],
      };
  }
};

export function getCustomCSS(
  topBottom: TopBottomPosition = BOTTOM_POSITION,
  leftRight: LeftRightPosition = LEFT_POSITION,
  theme: LanguageSelectorTheme = DARK_MODE_THEME,
  width = WIDTH_DEFAULT
) {
  const { backgroundColor, highlightColor, textColor } = getThemeColors(theme);
  const verticalPosition = topBottom === TOP_POSITION ? 'top' : 'bottom';
  const horizontalPosition = leftRight === LEFT_POSITION ? 'left' : 'right';

  // Do NOT indent the string below as it will ruin the CSS output
  return `${getCSSComment(topBottom, leftRight, theme, width)}
.GLGO-language-selector {
  position: fixed;
  ${verticalPosition}: 0;
  ${horizontalPosition}: 20px;
  z-index: 9999999999;
  width: ${width}px;
  white-space: nowrap;
}

.oljs-select-selected {
  background: ${backgroundColor};
}

.oljs-select-selected:after {
  position: absolute;
  ${verticalPosition}: 40%;
  right: 8px;
  z-index: 9999999999;
  content: "";
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: ${textColor} transparent transparent transparent;
  transform: rotateZ(${verticalPosition === TOP_POSITION ? '0deg' : '180deg'});
  transition: 0.5s;
}

.oljs-select-selected.oljs-select-arrow-active:after {
  border-color: transparent transparent ${textColor} transparent;
  ${verticalPosition}: 22%;
}

.oljs-select-items div,.oljs-select-selected {
  color: ${textColor} !important;
  font-family: Arial !important;
  font-size: small !important;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-right-width: 6px;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis !important;
  line-height: 1.5em !important;
}

.oljs-select-items {
  position: absolute;
  max-height: 300px;
  overflow-y: auto;
  background: ${highlightColor};
  width: ${width}px;
  scrollbar-color: ${backgroundColor};
  ${verticalPosition}: 100%;
  right: 0;
  z-index: 9999999999;
}

.oljs-select-hide {
  display: none;
}

.oljs-select-items div:hover, .oljs-same-as-selected {
  background: ${backgroundColor} !important;
}`;
}
