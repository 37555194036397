import {
  GLGO_ENTERPRISE_PROJECT_TYPE,
  GLGO_PROJECT_TYPE, GLNOW_PROJECT_TYPE,
  GLWEB_PROJECT_TYPE,
  OLJS_PROJECT_TYPE
} from "helpers";
import { useUserProvider } from 'ui';
import useGLGOParams from './glgoParams';

const useCurrentProjectType = () => {
  const { projectKey } = useGLGOParams();
  const { xapisUser: { project_keys = [] } = {} } = useUserProvider();

  const projectType =
    project_keys.find((p) => p.project_key === projectKey)?.project_type || '';

  const isGLGOProject = projectType === GLGO_PROJECT_TYPE;
  const isGLWEBProject =
    projectType === GLWEB_PROJECT_TYPE || projectType === OLJS_PROJECT_TYPE;
  const isGLWEBEnterprise = projectType === OLJS_PROJECT_TYPE;
  const isGLGOEnterprise = projectType === GLGO_ENTERPRISE_PROJECT_TYPE;
  const isGLNOWProject = projectType === GLNOW_PROJECT_TYPE;

  return {
    isGLGOProject,
    isGLWEBProject,
    isGLGOEnterprise,
    isGLWEBEnterprise,
    isGLNOWProject
  };
};

export default useCurrentProjectType;
