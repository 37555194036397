import { Flex, Menu } from '@mantine/core';
import React from 'react';
import { BatchFieldOptions } from '../../pages/batches/ActiveBatchesPage';
import { MdArrowRight } from 'react-icons/md';
import {
  BATCH_STATUS_TODO,
  BATCH_PHASE_REVIEW,
  BATCH_PHASE_TESTING,
  BATCH_PHASE_TRANSLATION,
  BATCH_PHASE_VALIDATION,
  BATCH_STATUS_INPROGRESS,
  BATCH_STATUS_READY,
} from './utils';
import {
  USER_ROLE_ADMIN,
  USER_ROLE_PROJECT_MANAGER,
  useViewPermissions,
} from 'helpers';
import { internalUserRoles } from 'helpers';

type Props = {
  batches?: string[];
  handleBatchChange: (
    params: Partial<Record<Partial<BatchFieldOptions>, string>>
  ) => void;
  isDetailed?: boolean;
  openCancelBatchModal: (batches: string[]) => void;
  openCompleteBatchesModal: (batches: string[]) => void;
  openGradingBatchModal: (batch: string) => void;
  openEditBatchModal: (batch: string) => void;
  openApproveUrlsModal?: () => void;
};

const BatchActionsDropdown = ({
  batches = [],
  handleBatchChange,
  openCancelBatchModal,
  openCompleteBatchesModal,
  openGradingBatchModal,
  openEditBatchModal,
  openApproveUrlsModal = () => {},
  isDetailed = false,
}: Props) => {
  const singleBatch = batches?.length === 1;
  const { hasViewPermissions, rolePermissions, hasInternalRole } =
    useViewPermissions();
  const canChangePhase = hasInternalRole;
  const canEditBatch = hasViewPermissions(
    rolePermissions,
    new Set([...internalUserRoles, USER_ROLE_ADMIN, USER_ROLE_PROJECT_MANAGER])
  );

  return (
    <Menu.Dropdown miw={153}>
      {singleBatch && canEditBatch && (
        <Menu.Item onClick={() => openEditBatchModal(batches[0])}>
          Edit
        </Menu.Item>
      )}
      {canEditBatch && (
        <Menu.Item closeMenuOnClick={false}>
          <Menu position="left-start" offset={30} closeDelay={200}>
            <Menu.Target>
              <Flex align="center" justify="space-between">
                Change Status
                <MdArrowRight size={24} />
              </Flex>
            </Menu.Target>
            <Menu.Dropdown miw={111}>
              {[
                BATCH_STATUS_TODO,
                BATCH_STATUS_INPROGRESS,
                BATCH_STATUS_READY,
              ].map((status) => (
                <Menu.Item
                  onClick={() =>
                    handleBatchChange({ phase_status: status.toLowerCase() })
                  }
                  key={status}
                  tt="capitalize"
                >
                  {status}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        </Menu.Item>
      )}
      {canChangePhase && (
        <>
          <Menu.Item closeMenuOnClick={false}>
            <Menu position="left-start" offset={30} closeDelay={200}>
              <Menu.Target>
                <Flex align="center" justify="space-between">
                  Change Phase
                  <MdArrowRight size={24} />
                </Flex>
              </Menu.Target>
              <Menu.Dropdown miw={112}>
                {[
                  BATCH_PHASE_TRANSLATION,
                  BATCH_PHASE_TESTING,
                  BATCH_PHASE_REVIEW,
                  BATCH_PHASE_VALIDATION,
                ].map((phase) => (
                  <Menu.Item
                    onClick={() =>
                      handleBatchChange({
                        phase: phase.toLowerCase(),
                        phase_status: BATCH_STATUS_TODO,
                        assigned_user: '',
                      })
                    }
                    key={phase}
                    tt="capitalize"
                  >
                    {phase}
                  </Menu.Item>
                ))}
              </Menu.Dropdown>
            </Menu>
          </Menu.Item>
          {(singleBatch || isDetailed) && (
            <Menu.Item onClick={() => openGradingBatchModal(batches[0])}>
              Grade Batch
            </Menu.Item>
          )}
          <Menu.Item onClick={() => openCompleteBatchesModal(batches)}>
            {`Complete Batch${batches?.length > 1 ? 'es' : ''}`}
          </Menu.Item>
          <Menu.Item
            onClick={() => openCancelBatchModal(batches)}
          >{`Cancel Batch${batches?.length > 1 ? 'es' : ''}`}</Menu.Item>
        </>
      )}

      {isDetailed && (
        <Menu.Item onClick={() => openApproveUrlsModal()}>
          Mark All As Reviewed
        </Menu.Item>
      )}
    </Menu.Dropdown>
  );
};
export default BatchActionsDropdown;
