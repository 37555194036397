import { dashboardRC, desktopRC } from '@glweb/constants';
import { Button, Card, Flex, Stack, Text, Title } from '@mantine/core';
import { BatchEditorIcon, NoBatchesIcon } from 'images';
import { AnchorLink, BatchProgressBar, OpenWebStudio, useGLGOParams } from 'ui';
import { normalizeBatchName } from '../../batches/utils';
const EmptyBatches = () => (
  <Flex align={'center'} gap={10}>
    <NoBatchesIcon />
    <Stack gap={0}>
      <Text fw={700}>No batches to view yet.</Text>
      <Text>Once available your batches will appear here</Text>
    </Stack>
  </Flex>
);

const ExtraBatchesButton = ({
  numExtraBatches,
}: {
  numExtraBatches: number;
}) => {
  const { projectId } = useGLGOParams();
  return (
    <Flex justify={'center'} align={'center'} mt={10}>
      <AnchorLink
        to={`/${projectId}/${dashboardRC.PAGE_TRANSLATIONS}/${dashboardRC.PAGE_ACTIVE_BATCHES}`}
        fz="md"
        td="none"
        fw={700}
      >
        {`+${numExtraBatches} more`}
      </AnchorLink>
    </Flex>
  );
};

const dayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const BatchCard = ({ batch }: { batch: CardBatch }) => {
  const {
    progress = 0,
    batch_name,
    due_date_utc,
    batch_key,
    target_language_name,
  } = batch;
  const dateDue = due_date_utc ? new Date(due_date_utc) : '';
  const name = normalizeBatchName(batch_name);
  const { projectId } = useGLGOParams();
  const webStudioPath = `/${projectId}/${desktopRC.BATCH}/?batch_key=${batch_key}`;

  return (
    <Card px={10} py={5} style={{ boxShadow: 'none' }}>
      <Flex gap={10} px={0} align="center">
        <Stack align={'center'} gap={0} w={100}>
          <Title order={1} fz="2rem">
            {dateDue ? dateDue.getDate() : 'No'}
          </Title>
          <Text>{dateDue ? dayNames[dateDue.getDay()] : 'Due Date'}</Text>
        </Stack>
        <Flex justify="space-between" w="100%" align="center">
          <Stack
            style={{
              borderLeft: '2px solid var(--mantine-color-border-1',
            }}
            px={20}
            gap={10}
            justify="center"
          >
            <Flex gap={3}>
              <AnchorLink
                to={`/${projectId}/${dashboardRC.PAGE_TRANSLATIONS}/${dashboardRC.PAGE_ACTIVE_BATCHES}/${dashboardRC.PAGE_BATCH_DETAILS}?batch=${batch_key}`}
              >
                {name}
              </AnchorLink>
              <Text>|</Text>
              <Text>{target_language_name}</Text>
            </Flex>
            <BatchProgressBar progress={progress} />
          </Stack>
          <OpenWebStudio
            icon={
              <Button style={{ justifySelf: 'flex-end' }}>
                <Flex gap={10} align="center">
                  <BatchEditorIcon color="white" />
                  Launch
                </Flex>
              </Button>
            }
            path={webStudioPath}
          />
        </Flex>
      </Flex>
    </Card>
  );
};

export const MyBatches = ({ batches }: { batches: CardBatch[] }) => {
  const todayPlus30 = new Date();
  todayPlus30.setDate(todayPlus30.getDate() + 30);

  const batchesDueSoonList = batches
    .filter(
      (batch) => new Date(batch.due_date_utc || batch.created_utc) < todayPlus30
    )
    .sort(
      (a, b) =>
        new Date(a.due_date_utc || a.created_utc).getTime() -
        new Date(b.due_date_utc || b.created_utc).getTime()
    );

  return (
    <>
      {batchesDueSoonList.length > 0 ? (
        <Stack gap={10}>
          {batchesDueSoonList.slice(0, 4).map((batch) => (
            <BatchCard batch={batch} key={batch.batch_key} />
          ))}
        </Stack>
      ) : (
        <EmptyBatches />
      )}
      {batchesDueSoonList.length > 4 && (
        <ExtraBatchesButton numExtraBatches={batchesDueSoonList.length} />
      )}
    </>
  );
};
