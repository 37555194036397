import { useFilteredBatchHistory } from '../../../queries/batchesQueries';

import { CustomTable, getBasicColumn, useCurrentProjectData } from 'ui';
import React, { useState } from 'react';
import { MRT_Cell, MRT_ColumnDef, MRT_RowData } from 'mantine-react-table';
import { Badge, Flex, Rating, Text, UnstyledButton } from '@mantine/core';
import moment from 'moment/moment';
import { getStatusColor, normalizeBatchName } from '../utils';
import { CircleAvatar } from '../../shared/CircleAvatar';
import { TbStar, TbStarFilled } from 'react-icons/tb';
import { useDisclosure } from '@mantine/hooks';
import { MdComment } from 'react-icons/md';
import BatchCommentModal from './BatchCommentModal';

const BatchHistoryTable = () => {
  const { batches } = useFilteredBatchHistory();
  const { projectUserPermissionMap } = useCurrentProjectData();
  const [opened, { open, close }] = useDisclosure();
  const [batch, setBatch] = useState<HistoryBatch>();

  const columns: MRT_ColumnDef<HistoryBatch>[] = [
    {
      ...getBasicColumn('batch'),
      Cell: ({ row }: { row: MRT_RowData }) => {
        const { target_language_name, batch_name } = row.original;
        const name = normalizeBatchName(batch_name);

        return (
          <Flex gap={3}>
            <Text>{name}</Text>
            {/* TODO add this link when we have a way to show a detailed batch from the batch history table*/}
            {/*<AnchorLink*/}
            {/*  to={`../${dashboardRC.PAGE_BATCH_DETAILS}?batch=${batch_key}`}*/}
            {/*>*/}
            {/*  {name}*/}
            {/*</AnchorLink>*/}
            <Text>|</Text>
            <Text>{target_language_name}</Text>
          </Flex>
        );
      },
      size: 180,
      sortingFn: (rowA, rowB) =>
        rowA.original.batch_name.localeCompare(rowB.original.batch_name),
    },
    {
      ...getBasicColumn('phase_status', 'status'),
      Cell: ({ cell }: { cell: MRT_Cell<HistoryBatch, unknown> }) => {
        const status = cell.getValue() as string;

        if (!status) {
          return <></>;
        }

        return (
          <Badge
            bg={getStatusColor(status)}
            c={status === 'cancelled' ? 'status1.5' : 'white'}
            radius={4}
            tt="uppercase"
            w={100}
          >
            {status}
          </Badge>
        );
      },
      size: 75,
    },
    {
      ...getBasicColumn('urls'),
      Cell: ({ cell }: { cell: MRT_Cell<HistoryBatch, unknown> }) => {
        const urls = cell.getValue() as number;

        return (
          <Text
            ta="right"
            w={{ base: '100%', sm: '80%', md: '50%', lg: '30%', xl: '20%' }}
          >
            {`${urls}` || '0'}
          </Text> //todo enable later when we have a link for this page

          // <AnchorLink>
          //   <Button variant="transparent" rightSection={<BatchEditorIcon />}>
          //     {urls || '0'}
          //   </Button>
          // </AnchorLink>
        );
      },
      size: 60,
    },
    {
      ...getBasicColumn('assigned_user', 'assignee'),
      Cell: ({ cell }: { cell: MRT_Cell<HistoryBatch, unknown> }) => {
        const assigneeUserKey = cell.getValue() as string;
        const assignee =
          projectUserPermissionMap[assigneeUserKey] ?? ({} as User);
        const { first_name, last_name } = assignee;

        return (
          <Flex justify="center" align="center" gap={10}>
            {first_name && (
              <CircleAvatar name={first_name} backgroundColor="#6ED6CC" />
            )}
            <Text c="inherit">
              {first_name} {last_name}
            </Text>
          </Flex>
        );
      },
    },
    {
      ...getBasicColumn('created_utc', 'created'),
      Cell: ({ cell }: { cell: MRT_Cell<HistoryBatch, unknown> }) => {
        const dateString = cell.getValue() as string;

        if (!dateString) {
          return <></>;
        }

        return <Text>{moment.utc(dateString).format('MM/DD/YYYY')}</Text>;
      },
      size: 70,
    },
    {
      ...getBasicColumn('lastmod_utc', 'completed'),
      Cell: ({ cell }: { cell: MRT_Cell<HistoryBatch, unknown> }) => {
        const dateString = cell.getValue() as string;

        if (!dateString) {
          return <></>;
        }

        return <Text>{moment.utc(dateString).format('MM/DD/YYYY')}</Text>;
      },
      size: 80,
    },
    {
      ...getBasicColumn('score'),
      size: 70,
      Cell: ({ row }: MRT_RowData) =>
        row.original.phase_status === 'cancelled' ? (
          <></>
        ) : (
          <Rating
            readOnly
            value={row.original.score as number}
            emptySymbol={<TbStar color={'#FEBC2E'} />}
            fullSymbol={<TbStarFilled color={'#FEBC2E'} />}
          />
        ),
    },
    {
      ...getBasicColumn('batch_comment', 'Note'),
      size: 90,
      Cell: ({ row }: MRT_RowData) =>
        (row.original.batch_comment as string) ? (
          <Flex justify="center" align="center" w={'100%'}>
            <UnstyledButton
              onClick={() => {
                setBatch(row.original as HistoryBatch);
                open();
              }}
            >
              <MdComment color="var(--mantine-color-cta1-2)" />
            </UnstyledButton>
          </Flex>
        ) : (
          <></>
        ),
      grow: false,
    },
  ];

  return (
    <>
      <CustomTable
        columns={columns}
        data={batches}
        enableBottomToolbar={false}
        state={{ density: 'xs' }}
      />
      <BatchCommentModal batch={batch} opened={opened} onClose={close} />
    </>
  );
};
export default BatchHistoryTable;
