import { dashboardRC, GLWEB_SUPPORT_URL } from '@glweb/constants';
import { defaultSubscription } from '@glweb/xapis-client';
import { Button, Divider, ScrollArea, Stack } from '@mantine/core';
import cheet from 'cheet.js';
import { isDesktopApp, NOOP, useViewPermissions } from 'helpers';
import React, { useState } from 'react';
import { IoMdNotifications } from 'react-icons/io';
import {
  MdContactSupport,
  MdDoubleArrow,
  MdLogout,
  MdOutlineArrowRightAlt,
} from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { useCurrentProjectData, useCurrentProjectType, useViewSize } from 'ui';
import { accountLinks, type NavLink } from '../../navLinks';
import useGlobalModalStore, {
  type GlobalModalStore,
} from '../../store/GlobalModalStore';
import FreeTrialUsageCard from './FreeTrialUsageCard';
import { NavItem, TabletNavItem } from './NavItem';
import NotificationsMenu from './NotificationsMenu';
import classes from './SideNav.module.css';
type Props = {
  links: NavLink[];
  setIsDesktopToggled: React.Dispatch<React.SetStateAction<boolean>>;
  isDesktopToggled: boolean;
  toggleMobile: () => void;
};

export default function SideNav({
  links,
  setIsDesktopToggled,
  isDesktopToggled,
  toggleMobile,
}: Props) {
  const { pathname } = useLocation();
  const { setShowContactUsModal } = useGlobalModalStore(
    (state: GlobalModalStore) => state
  );

  const { isMobileSize, isTabletSize } = useViewSize();

  const [showQuoteLink, setShowQuoteLink] = useState(false);
  const [showDebugLink, setShowDebugLink] = useState(false);
  const [showMobileNotifications, setShowMobileNotifications] = useState(false);

  if (!isMobileSize) {
    (cheet as unknown as (str: string, cb: () => void) => void)(
      'ctrl d b g',
      () => {
        setShowDebugLink(!showDebugLink);
      }
    );
    (cheet as unknown as (str: string, cb: () => void) => void)(
      'ctrl shift q',
      () => {
        setShowQuoteLink(!showQuoteLink);
      }
    );
  }

  const {
    project,
    project: { project_type },
    subscriptions,
  } = useCurrentProjectData();
  const { isGLGOProject, isGLGOEnterprise, isGLWEBEnterprise } =
    useCurrentProjectType();
  const isGLWEBEnterpriseWithoutSku =
    isGLWEBEnterprise && !project.subscription_sku;
  const isDesktop = isDesktopApp();

  const { rolePermissions, hasViewPermissions } = useViewPermissions();

  const filteredAccountLinks: NavLink = {
    ...accountLinks,
    pages: (accountLinks.pages ?? []).filter(
      ({ path, userRoles, allowedProjectTypes }) => {
        switch (true) {
          case userRoles && !hasViewPermissions(rolePermissions, userRoles):
          case allowedProjectTypes && !allowedProjectTypes.has(project_type):
          case (isDesktop || isGLGOEnterprise || isGLWEBEnterpriseWithoutSku) &&
            path === dashboardRC.PAGE_BILLING_INFORMATION:
            return false;
          default:
            return true;
        }
      }
    ),
  };

  const mobileLinks: NavLink[] = [
    {
      icon: <IoMdNotifications size={20} />,
      label: 'Notifications',
      action: () => setShowMobileNotifications(true),
    },
    {
      icon: <MdContactSupport size={20} />,
      label: 'Contact Support',
      pages: [
        {
          label: 'Help',
          link: GLWEB_SUPPORT_URL,
          target: '_blank',
        },
        {
          label: 'Contact Us',
          action: () => setShowContactUsModal(true),
        },
      ],
    },
    { ...filteredAccountLinks },
    {
      icon: <MdLogout size={20} />,
      label: dashboardRC.PAGE_TITLE_LOGOUT,
      path: dashboardRC.PAGE_LOGOUT,
    },
  ];

  const filteredMobileLinks = mobileLinks.filter(
    ({ userRoles, allowedProjectTypes }) => {
      switch (true) {
        case userRoles && !hasViewPermissions(rolePermissions, userRoles):
        case allowedProjectTypes && !allowedProjectTypes.has(project_type):
          return false;
        default:
          return true;
      }
    }
  );

  const showAccountLinks =
    !isMobileSize && pathname.includes(`/${dashboardRC.PAGE_ACCOUNT}`);

  const filteredLinks = showAccountLinks
    ? [filteredAccountLinks]
    : [
        ...links.filter(({ label, userRoles, allowedProjectTypes }) => {
          switch (true) {
            case !showDebugLink && label === dashboardRC.PAGE_TITLE_DEBUG:
            case !showQuoteLink && label === dashboardRC.PAGE_TITLE_QUOTES:
            case userRoles && !hasViewPermissions(rolePermissions, userRoles):
            case allowedProjectTypes && !allowedProjectTypes.has(project_type):
              return false;
            default:
              return true;
          }
        }),
        ...(isMobileSize ? filteredMobileLinks : []),
      ];

  const showTabletNavItem = !isMobileSize && (isDesktopToggled || isTabletSize);

  const [activeSubscription = defaultSubscription] = subscriptions;
  const showFreeTrialUsage = activeSubscription.price == 0 && isGLGOProject;
  const isSubscriptionExpired = project.subscription_status === 'expired';

  if (isMobileSize && showMobileNotifications) {
    return (
      <NotificationsMenu
        showMobileNotifications={showMobileNotifications}
        setShowMobileNotifications={setShowMobileNotifications}
      />
    );
  }

  return (
    <Stack
      flex={1}
      gap="0.75rem"
      mt={10}
      mb={55}
      style={{ overflow: 'hidden' }}
      data-testid="sideNav"
    >
      {showAccountLinks && (
        <>
          <Link to="/" style={{ margin: '0 auto 0 auto' }}>
            {showTabletNavItem ? (
              <Button
                px="0.65rem"
                py="0.25rem"
                miw="fit-content"
                mx="auto"
                variant="transparent"
                c="font1.0"
              >
                <MdOutlineArrowRightAlt size={24} className="flip" />
              </Button>
            ) : (
              <Button
                w="100%"
                c="font1.0"
                fz="0.85rem"
                px={0}
                variant="transparent"
                leftSection={
                  <MdOutlineArrowRightAlt size={24} className="flip" />
                }
              >
                Back to Dashboard
              </Button>
            )}
          </Link>
          <Divider color="divider1.0" />
        </>
      )}
      <ScrollArea scrollbarSize={10} scrollbars="y">
        <Stack
          gap={showTabletNavItem ? '0.5rem' : 0}
          px="0.65rem"
          fz="sm"
          c="font1.0"
        >
          {filteredLinks.map((link) => {
            return showTabletNavItem ? (
              <TabletNavItem
                key={link.label}
                classes={classes}
                link={link}
                permissions={rolePermissions}
              />
            ) : (
              <NavItem
                key={link.label}
                permissions={rolePermissions}
                classes={classes}
                link={link}
                toggleMobile={isMobileSize ? toggleMobile : NOOP}
                isMobileSize={isMobileSize}
              />
            );
          })}
        </Stack>
      </ScrollArea>
      {showFreeTrialUsage && (
        <>
          <Divider color="divider1.0" />
          <FreeTrialUsageCard
            isCompact={showTabletNavItem}
            subscription={activeSubscription}
            isExpired={isSubscriptionExpired}
          />
        </>
      )}
      <Button
        display={{ base: 'none', lg: 'block' }}
        p="0.25rem"
        variant="transparent"
        pos="absolute"
        bottom={15}
        right={isTabletSize || isDesktopToggled ? 15 : 20}
        onClick={() => setIsDesktopToggled((prev) => !prev)}
      >
        <MdDoubleArrow
          color={'var(--mantine-color-font1-0)'}
          size={24}
          className={isDesktopToggled ? undefined : 'flip'}
        />
      </Button>
    </Stack>
  );
}
