/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Accordion,
  ActionIcon,
  Anchor,
  AppShell,
  Button,
  Card,
  Checkbox,
  ColorInput,
  Combobox,
  createTheme,
  MantineColorsTuple,
  MantineComponent,
  MantineThemeOverride,
  Menu,
  Modal,
  MultiSelect,
  NumberInput,
  PasswordInput,
  Pill,
  PillsInput,
  Progress,
  Radio,
  RadioGroup,
  ScrollArea,
  Select,
  Slider,
  Switch,
  Tabs,
  Text,
  Textarea,
  TextInput,
  Title,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import accordionClasses from './modules/Accordion.module.css';
import actionIconClasses from './modules/ActionIcon.module.css';
import anchorClasses from './modules/Anchor.module.css';
import appShellClasses from './modules/AppShell.module.css';
import buttonClasses from './modules/Button.module.css';
import cardClasses from './modules/Card.module.css';
import checkboxClasses from './modules/Checkbox.module.css';
import colorInputClasses from './modules/ColorInput.module.css';
import comboboxClasses from './modules/Combobox.module.css';
import menuClasses from './modules/Menu.module.css';
import modalClasses from './modules/Modal.module.css';
import multiSelectClasses from './modules/MultiSelect.module.css';
import numberInputClasses from './modules/NumberInput.module.css';
import passwordInputClasses from './modules/PasswordInput.module.css';
import pillsInputClasses from './modules/PillsInput.module.css';
import progressClasses from './modules/Progress.module.css';
import radioClasses from './modules/Radio.module.css';
import scrollAreaClasses from './modules/ScrollArea.module.css';
import selectClasses from './modules/Select.module.css';
import sliderClasses from './modules/Slider.module.css';
import switchClasses from './modules/Switch.module.css';
import tabsClasses from './modules/Tabs.module.css';
import textClasses from './modules/Text.module.css';
import textareaClasses from './modules/Textarea.module.css';
import textInputClasses from './modules/TextInput.module.css';
import titleClasses from './modules/Title.module.css';
import tooltipClasses from './modules/Tooltip.module.css';
import unstyledButtonClasses from './modules/UnstyledButton.module.css';

import { IoMdCheckmark } from 'react-icons/io';

export const dashboardTheme: MantineThemeOverride = createTheme({
  fontFamily: 'OpenSans, sans-serif',
  colors: {
    // TODO: Reorganize prior to page(s) revamp
    cta1: [
      '#FC5B1F', // light active bg
      '#FD8457', // light hover bg
      '#0071DB', // default active bg, outline border and font
      '#4094E4', // default hover bg, outline hover font
      '#757575', // default inactive bg, outline inactive font
      '#F7F8FB', // outline hover bg
      '#FFFFFF',
    ] as unknown as MantineColorsTuple,
    secondary1: ['#6E6ADD', '#ECF5FF'] as any,
    font1: [
      '#003B71',
      '#334552',
      '#0071DB',
      '#188038',
      '#D93025',
      '#757575',
    ] as any,
    background1: ['#003B71', '#EDF0F3'] as any,
    border1: ['#DBE1E5', '#0071DB'] as any,
    icons1: ['#0071DB', '#00328D', '#757575', '#2C49AC', '#003B71'] as any,
    divider1: ['#CFD2D6'] as any,
    status1: [
      '#255C8E',
      '#782AE3',
      '#E17900',
      '#169355',
      '#DBE1E5',
      '#D14900',
    ] as any,
    // TODO: Remove old colors once fully migrated to new style guide
    text: [
      '#FFFFFF',
      '#00328D',
      '#071D49',
      '#757575',
      '#D93025',
      '#188038',
      '#14CD5E',
      '#D93025',
      '#0071DB',
      '#868E96',
    ],
    text2: [
      '#2D3748',
      '#212529',
      '#000000',
      '#1A0DAB',
      '#687897',
      '#61759E',
      '#2C49AC',
      '#637392',
      '#707070',
      '#AB1A1F',
    ],
    text3: [
      '#E8F2EA',
      '#EAF0FF',
      '#0071DB',
      '#677689',
      '#003B71',
      '#139DD8',
    ] as any,
    background: [
      '#FFFFFF',
      '#FAFBFF',
      '#E8EBFC',
      '#8F9DBA',
      '#14CD5E',
      '#071D49',
      '#212529',
      '#F8F9FA',
      '#F5F5F5',
    ] as any,
    background2: [
      '#EFF4FB',
      '#188038',
      '#0071DB',
      '#707070',
      '#637392',
      '#EAF0FF',
      '#2C49AC',
      '#E4E7FA',
      '#E9EBFB',
      '#6E6ADD',
    ] as any,
    border: [
      '#E7F3FF',
      '#CBD1F9',
      '#637392',
      '#188038',
      '#D0DBF4',
      '#071D49',
      '#CED4DA',
      '#D9D9D9',
      '#0071DB',
      '#E9ECEF',
    ] as any,
    border2: [
      '#BAD9C3',
      '#C8DFF8',
      '#D1D1D4',
      '#D5DAE3',
      '#E8EBFC',
      '#D1D7FA',
      '#EAF0FF',
      '#2C49AC',
      '#757575',
    ] as any,
    border3: ['#EEEEEE'] as any,
    divider: ['#D1D7FA', '#CBD1F9', '#EAF0FF'] as any,
    badge: [
      '#EEEEEE',
      '#757575',
      '#E8F2EB',
      '#188038',
      '#656565',
      '#E2F0FF',
      '#E6F1FB',
      '#E9EBF1',
      '#EAF0FF',
      '#E7F3FF',
    ],
    icon: [
      '#0071DB',
      '#CED4DA',
      '#071D49',
      '#5C73BC',
      '#1976D2',
      '#2C49AC',
      '#FBC30C',
    ] as any,
    progressBar: ['#0071DB'] as any,
    checkBox: ['#00328D'] as any,
    scrollBar: ['#0071DB'] as any,
    blue: [
      '#F5FAFF', // 0
      '#E9F1FD', // 1
      '#D0DBF4', // 2
      '#CBDDF9', // 3
      '#A6D4FF', // 4
      '#90A0D3', // 5
      '#5672BC', // 6
      '#0071DB', // 7
      '#2C49AC', // 8
      '#071D49', // 9
    ],
    gray: [
      '#FAFBFF',
      '#EFF4FB',
      '#E4E7FA',
      '#E6E6E8',
      '#D6D9EC',
      '#B9C8E3',
      '#A0A7D6',
      '#8F9DBA',
      '#637392',
      '#252540',
    ],
    tableHeader: ['#DBE1E5'] as any,
  },
  components: {
    AppShell: AppShell.extend({
      classNames: appShellClasses,
    }),
    Text: (Text as MantineComponent<any>).extend({
      classNames: textClasses,
      defaultProps: {
        fz: 'sm',
      },
    }),
    Title: Title.extend({
      classNames: titleClasses,
    }),
    ActionIcon: ActionIcon.extend({
      classNames: actionIconClasses,
    }),
    Button: Button.extend({
      classNames: buttonClasses,
      defaultProps: {
        variant: 'default',
      },
    }),
    UnstyledButton: UnstyledButton.extend({
      classNames: unstyledButtonClasses,
    }),
    Card: Card.extend({
      classNames: cardClasses,
    }),
    Tooltip: Tooltip.extend({
      classNames: tooltipClasses,
      defaultProps: {
        multiline: true,
        withArrow: true,
        withinPortal: true,
        maw: 250,
        arrowSize: 10,
        position: 'bottom',
        p: 10,
        zIndex: 999,
        events: { hover: true, focus: false, touch: true },
      },
    }),
    Modal: Modal.extend({
      classNames: modalClasses,
    }),
    Progress: Progress.extend({
      classNames: progressClasses,
      defaultProps: {
        color: 'secondary1.0',
        bg: 'border1.0',
      },
    }),
    Checkbox: Checkbox.extend({
      classNames: checkboxClasses,
      defaultProps: {
        icon: IoMdCheckmark,
        size: 'xs',
      },
    }),
    RadioGroup: RadioGroup.extend({
      defaultProps: {
        c: 'font1.1',
      },
    }),
    Radio: Radio.extend({
      classNames: radioClasses,
    }),
    Switch: Switch.extend({
      classNames: switchClasses,
    }),
    Tabs: Tabs.extend({
      classNames: tabsClasses,
    }),
    Anchor: Anchor.extend({
      classNames: anchorClasses,
      defaultProps: {
        c: 'font1.2',
      },
    }),
    Accordion: Accordion.extend({
      classNames: accordionClasses,
    }),
    Slider: Slider.extend({
      classNames: sliderClasses,
    }),
    Pill: Pill.extend({
      defaultProps: {
        bg: 'secondary1.1',
        c: 'font1.0',
        fw: 600,
      },
    }),
    TextInput: TextInput.extend({
      classNames: textInputClasses,
      defaultProps: {
        variant: 'default',
      },
    }),
    PasswordInput: PasswordInput.extend({
      classNames: passwordInputClasses,
      defaultProps: {
        variant: 'default',
      },
    }),
    Textarea: Textarea.extend({
      classNames: (_theme, props) => ({
        input: props.error ? textareaClasses.inputError : textareaClasses.input,
      }),
    }),
    ColorInput: ColorInput.extend({
      classNames: colorInputClasses,
    }),
    NumberInput: NumberInput.extend({
      classNames: numberInputClasses,
    }),
    PillsInput: PillsInput.extend({
      classNames: pillsInputClasses,
      defaultProps: {
        variant: 'default',
      },
    }),
    Menu: Menu.extend({
      classNames: menuClasses,
      defaultProps: {
        arrowSize: 10,
      },
    }),
    Select: Select.extend({
      classNames: selectClasses,
      defaultProps: {
        variant: 'default',
        withCheckIcon: false,
        scrollAreaProps: {
          type: 'auto',
          scrollbarSize: 11,
        },
      },
    }),
    MultiSelect: MultiSelect.extend({
      classNames: multiSelectClasses,
      defaultProps: {
        withCheckIcon: false,
        scrollAreaProps: {
          type: 'auto',
          scrollbarSize: 11,
        },
      },
    }),
    Combobox: Combobox.extend({
      classNames: comboboxClasses,
      defaultProps: {
        variant: 'default',
      },
    }),
    ScrollArea: ScrollArea.extend({
      classNames: scrollAreaClasses,
      defaultProps: {
        type: 'auto',
        scrollbarSize: 11,
      },
    }),
  },
});

// export const dashboardThemeResolver: CSSVariablesResolver = (theme) => ({
//   variables: {
//     '--mantine-color-text': theme.colors.font1[1],
//   },
//   light: {},
//   dark: {},
// });

export default dashboardTheme;
