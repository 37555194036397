import { Button, Flex, useMantineTheme } from '@mantine/core';
import { useDeploymentContext } from './DeploymentContextProvider';
import { useCurrentProjectType } from 'ui';
import { COOKIE, DOMAIN, FOLDER } from './deploymentUtils';
import { CrownOutlineIcon } from 'images';
import React, { SetStateAction } from 'react';

type Props = {
  method: string;
  selected: boolean;
  editing: boolean;
  setEditing: React.Dispatch<SetStateAction<boolean>>;
  setShowUpgradeModal: React.Dispatch<SetStateAction<boolean>>;
  onEditSave: () => void;
  onCancel: () => void;
  editSaveDisabled: boolean;
};

const RightSectionDeploymentButton = ({
  method,
  selected,
  setEditing,
  editing,
  setShowUpgradeModal,
  onEditSave,
  onCancel,
  editSaveDisabled,
}: Props) => {
  const { colors } = useMantineTheme();

  const {
    setSelectedMethod,
    setShowConfirmMethodModal,
    activeProject: { deployment_method: defaultMethod = '' } = {},
  } = useDeploymentContext();
  const { isGLGOProject } = useCurrentProjectType();

  const shouldUpgradeToPlus = isGLGOProject && defaultMethod === COOKIE;

  if (shouldUpgradeToPlus && method !== COOKIE && !selected) {
    return (
      <Button
        ta="left"
        bg="background2.9"
        leftSection={<CrownOutlineIcon color={colors.icon[6]} />}
        onClick={() => setShowUpgradeModal(true)}
        ml={'auto'}
        miw="fit-content"
      >
        Upgrade Required
      </Button>
    );
  }
  if (!shouldUpgradeToPlus && !selected) {
    return (
      <Button
        onClick={() => {
          setSelectedMethod(method);
          setShowConfirmMethodModal(true);
          setEditing(false);
        }}
        ml={'auto'}
      >
        {'Select'}
      </Button>
    );
  }

  if (selected && (method === FOLDER || method === DOMAIN)) {
    return <></>;
  }

  if (editing) {
    return (
      <Flex ml={'auto'} gap={5} wrap="wrap" justify={'flex-end'}>
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onEditSave} disabled={editSaveDisabled}>
          Save
        </Button>
      </Flex>
    );
  }

  return (
    <Button onClick={() => setEditing(true)} variant="outline" ml="auto">
      Edit
    </Button>
  );
};

export default RightSectionDeploymentButton;
