import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Loader,
  Text,
  Title,
} from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { useUserProvider } from 'ui';
import fathomEventTracker from '../../analytics/fathomAnalytics';
import BrowserWindow from './BrowserWindow';
import { handleCreateProject } from './handleProjectSetupSteps';
import { LanguageSelectorStyle } from './LanguageSelectorStyle';
import { MobileActionHeader } from './MobileActionHeader';
import { useSetupStepsContext } from './ProjectSetupProvider';

const NextButton = () => {
  const [, setSearchParams] = useSearchParams();
  const { xapisUser, setXapisUser } = useUserProvider();
  const {
    setActiveStep,
    updateStatus,
    status: { isCreatingProject },
    sourceTargetLangCodes,
    themePosition,
    url: { domain },
  } = useSetupStepsContext();

  const queryClient = useQueryClient();

  return (
    <Button
      disabled={isCreatingProject}
      onClick={() => {
        fathomEventTracker('Language Selector - 4');
        updateStatus(true, 'isCreatingProject');
        handleCreateProject(
          { domain, sourceTargetLangCodes, themePosition },
          updateStatus,
          setActiveStep,
          xapisUser,
          setXapisUser,
          setSearchParams,
          queryClient
        );
      }}
    >
      {isCreatingProject ? (
        <Loader color="cta1.6" size="sm" />
      ) : (
        <Text c="inherit" fz="inherit" fw="inherit" id="language-selector-4">
          Next
        </Text>
      )}
    </Button>
  );
};

export const CustomizeAppearanceSideMenu = () => {
  const {
    setActiveStep,
    status: { isCreatingProject },
  } = useSetupStepsContext();

  return (
    <Flex
      w="100%"
      maw={450}
      rowGap="1.5rem"
      direction="column"
      justify="center"
      align="center"
    >
      <Title fz="2rem" order={1}>
        Project Setup
      </Title>
      <Divider w="100%" color="divider.0" size={2} />
      <Text fz="xl" fw={600} ta="center">
        Choose how the language selector appears
      </Text>
      <Text fz="md" fw={500} ta="center">
        (You can edit this anytime after your project is created.)
      </Text>
      <Flex columnGap="1rem">
        <Button
          variant="outline"
          disabled={isCreatingProject}
          onClick={() => setActiveStep((prevStep) => prevStep - 1)}
        >
          Back
        </Button>
        <NextButton />
      </Flex>
    </Flex>
  );
};

export const CustomizeAppearanceBody = () => {
  return (
    <Box px="2rem">
      <BrowserWindow>
        <LanguageSelectorStyle />
      </BrowserWindow>
    </Box>
  );
};

export const MobileCustomizeAppearanceStep = () => {
  const {
    status: { isCreatingProject },
  } = useSetupStepsContext();

  return (
    <Card w="100%" maw={{ base: '100%', xs: 650, lg: 450 }} px="1rem" py="2rem">
      <Flex w="100%" h="100%" direction="column" align="center" rowGap="1.5rem">
        <MobileActionHeader
          title="Project Setup"
          isBackButtonDisabled={isCreatingProject}
          isNextButtonDisabled={isCreatingProject}
        />
        <Divider w="100%" color="divider.0" size={2} />
        <Flex w="100%" direction="column" rowGap="1rem">
          <Text fz="md" fw={700}>
            Language Selector Style
          </Text>
          <LanguageSelectorStyle />
        </Flex>
        <NextButton />
      </Flex>
    </Card>
  );
};
