import { Divider, Flex, Group, Image, Stack, Text } from '@mantine/core';
import React from 'react';
import ReviewBatchIcon from '../../../assets/images/ReviewBatchIcon.svg';
import TestingBatchIcon from '../../../assets/images/TestingBatchIcon.svg';
import TranslationBatchIcon from '../../../assets/images/TranslationBatchIcon.svg';
import ValidationBatchIcon from '../../../assets/images/ValidationBatchIcon.svg';
import { BatchesProgressBar } from './BatchesProgressBar';
import {
  BATCH_PHASE_REVIEW,
  BATCH_PHASE_TESTING,
  BATCH_PHASE_TRANSLATION,
  BATCH_PHASE_VALIDATION,
  BATCH_STATUS_INPROGRESS,
  BATCH_STATUS_READY,
  BATCH_STATUS_TODO,
} from '../../batches/utils';

type Props = {
  activeBatches: CardActiveBatch[];
};

type Phase = CardActiveBatch['phase'];
export type Status = keyof Omit<CardActiveBatch, 'phase'>;

const icons = [
  { src: TranslationBatchIcon, alt: 'Translation batch icon' },
  { src: TestingBatchIcon, alt: 'Testing batch icon' },
  { src: ReviewBatchIcon, alt: 'Review batch icon' },
  { src: ValidationBatchIcon, alt: 'Validation batch icon' },
];

export const statusObj: Record<Status, { color: string; label: string }> = {
  ['to do']: {
    color: 'status1.0',
    label: 'To Do',
  },
  ['in progress']: {
    color: 'status1.1',
    label: 'In Progress',
  },
  ready: {
    color: 'status1.2',
    label: 'Ready',
  },
};

const phases: Phase[] = [
  BATCH_PHASE_TRANSLATION,
  BATCH_PHASE_TESTING,
  BATCH_PHASE_REVIEW,
  BATCH_PHASE_VALIDATION,
];

const getBatchesMap = (activeBatches: CardActiveBatch[]) => {
  const defaultBatchMap = Object.fromEntries(
    phases.map((phase) => {
      return [
        phase,
        Object.fromEntries(
          Object.keys(statusObj).map((status) => {
            return [status, 0];
          })
        ),
      ];
    })
  );

  return activeBatches.reduce((accum, batch) => {
    const {
      phase = '',
      [BATCH_STATUS_TODO]: toDo = 0,
      [BATCH_STATUS_INPROGRESS]: inProgress = 0,
      [BATCH_STATUS_READY]: ready = 0,
    } = batch || {};

    const {
      [BATCH_STATUS_TODO]: accumTodo = 0,
      [BATCH_STATUS_INPROGRESS]: accumInProgress = 0,
      [BATCH_STATUS_READY]: accumReady = 0,
    } = accum[phase] || {};

    accum[phase] = {
      [BATCH_STATUS_TODO]: toDo + accumTodo,
      [BATCH_STATUS_INPROGRESS]: inProgress + accumInProgress,
      [BATCH_STATUS_READY]: ready + accumReady,
    };
    return accum;
  }, defaultBatchMap);
};

export const ActiveBatches = ({ activeBatches }: Props) => {
  const batchesMap = getBatchesMap(activeBatches);

  return (
    <Stack gap="1rem">
      {Object.entries(batchesMap).map(([phase, values], index) => {
        const statuses = Object.entries(values) as [
          status: Status,
          count: number,
        ][];

        return (
          <React.Fragment key={phase}>
            <Flex gap="0.75rem">
              <Group gap="0.5rem" align="center">
                <Image
                  w={22}
                  h={22}
                  src={icons[index].src}
                  alt={icons[index].alt}
                />
                <Text
                  w="7rem"
                  style={{ verticalAlign: 'middle' }}
                  fw={700}
                  tt="uppercase"
                >
                  {phase.toUpperCase()}
                </Text>
              </Group>
              <BatchesProgressBar flex={1} w="100%" statuses={statuses} />
            </Flex>
            <Divider
              display={index === phases.length - 1 ? 'none' : 'block'}
              size="sm"
              orientation="horizontal"
            />
          </React.Fragment>
        );
      })}
    </Stack>
  );
};
