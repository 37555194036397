import { dashboardRC } from '@glweb/constants';
import { Text } from '@mantine/core';
import React from 'react';
import { CustomModal, useGetNavigationStatus } from 'ui';
type ModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  encodedProjectKey: string;
};

const SkipInjectionModal = ({
  isModalOpen,
  setIsModalOpen,
  encodedProjectKey,
}: ModalProps) => {
  const isNavigatingAway = useGetNavigationStatus().isNavigatingAway;

  return (
    <CustomModal
      opened={isModalOpen || isNavigatingAway}
      onClose={() => !isNavigatingAway && setIsModalOpen(false)}
      footerActions={[
        {
          label: 'Confirm',
          to: `/${encodedProjectKey}/${dashboardRC.PAGE_DASHBOARD}`,
          loading: isNavigatingAway,
        },
      ]}
    >
      <Text fz="md" ta="center">
        Skip the injection and verification step for now and continue to the
        dashboard.
        <br />
        <br />
        You'll have to inject the javascript on your site to preview your
        translations and enable all the functionality of the dashboard.
      </Text>
    </CustomModal>
  );
};

export default SkipInjectionModal;
