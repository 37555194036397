import { segmentIsIgnorable } from '../functions/segmentsFunctions';

export class BlockSegmentsMap {
  blocks: {
    [blockHash: string]: {
      source: Jliff[];
      segments: string[];
    };
  };
  segments: {
    [segmentHash: string]: {
      blocks: string[];
    };
  };

  constructor() {
    this.blocks = {};
    this.segments = {};
  }

  get isEmpty() {
    return Object.keys(this.blocks).length === 0;
  }
  reset() {
    this.blocks = {};
    this.segments = {};
  }

  hasBlock(bHash: string) {
    return !!this.blocks[bHash];
  }

  addBlocks({
    blocksData,
    blockSegments,
  }: {
    blocksData: ContextData['blocksData'];
    blockSegments: BlockSegment;
  }) {
    // convert blocksData array to record with block hash as key
    const blocksDataObj =
      blocksData && blocksData.length
        ? blocksData.reduce((acc: { [blockHash: string]: Jliff[] }, block) => {
            acc[block.block_hash] = block.source;
            return acc;
          }, {})
        : {};
    Object.keys(blockSegments).forEach((bHash) => {
      if (!this.blocks[bHash]) {
        const source = blocksDataObj[bHash];
        // filter out ignorable segments
        const validSegments =
          blockSegments[bHash]
            .filter((s) => !segmentIsIgnorable(s))
            .map((s) => s.segment_hash) || [];
        // remove duplicates
        if (validSegments.length === 0) {
          console.warn(
            'BlockSegmentsMap: no segments for block',
            bHash,
            blockSegments[bHash]
          );
        } else {
          const segments = Array.from(new Set(validSegments));
          this.blocks[bHash] = {
            source,
            segments,
          };
          segments.forEach((sHash) => {
            if (!this.segments[sHash]) {
              this.segments[sHash] = {
                blocks: [bHash],
              };
            } else if (!this.segments[sHash].blocks.includes(bHash)) {
              this.segments[sHash].blocks.push(bHash);
            }
          });
        }
      }
    });
    // console.log('addBlocks:', this);
  }

  setBlocks({
    blocksData,
    blockSegments,
  }: {
    blocksData: ContextData['blocksData'];
    blockSegments: BlockSegment;
  }) {
    this.reset();
    this.addBlocks({ blocksData, blockSegments });
  }

  // return all unique block hashes for given segments hashes
  getSegmentsBlocks(hashes: string[]) {
    if (!hashes || !hashes.length) return [];
    if (hashes.length === 1) return this.segments[hashes[0]]?.blocks || [];

    const allHashes = new Set<string>([]);
    hashes.forEach((sHash) => {
      const blocks = this.segments[sHash]?.blocks || [];
      blocks.forEach(allHashes.add, allHashes);
    });
    return Array.from(allHashes);
  }

  getBlockSegments(bHash: string) {
    return this.blocks[bHash]?.segments || [];
  }

  // retrun all unique segment hashes for given blocks and segments hashes
  getSegmentsHashes(hashes?: { blocks?: string[]; segments?: string[] }) {
    if (!hashes) return [];
    if (!hashes.blocks) return hashes.segments || [];
    const allHashes = new Set<string>(hashes.segments || []);
    hashes.blocks.forEach((bHash) => {
      const segments = this.getBlockSegments(bHash);
      segments.forEach(allHashes.add, allHashes);
    });
    return Array.from(allHashes);
  }
}
