import {
  Box,
  Button,
  Flex,
  Menu,
  ScrollArea,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { saveSelectedProjectKey } from 'helpers';
import { useRef, useState } from 'react';
import { MdArrowDropDown, MdSearch } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useGLGOParams, useUserProvider, useViewSize } from 'ui';
import classes from './ProjectSelector.module.css';
import { CircleAvatar } from './shared/CircleAvatar';

export const ProjectSelector = () => {
  const { projectKey } = useGLGOParams();
  const navigate = useNavigate();
  const { colors } = useMantineTheme();
  const { isMobileSize } = useViewSize();

  // const projects = useUserProjects();
  const { userProjects } = useUserProvider();
  userProjects.sort((p1, p2) => p1.project_name.localeCompare(p2.project_name));

  const activeProject = userProjects?.find((p) => p.project_key === projectKey);
  // TODD: Handle the case where activeProject is undefined
  const [searchText, setSearchText] = useState('');
  const [opened, setOpened] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const filteredProjects = userProjects?.filter((p) => {
    const name = p.project_name.toLowerCase();
    const origin = p.origin_name.toLowerCase();
    const search = searchText.toLowerCase();

    const isRelevantProject = p.project_type !== 'PROXY';
    // Filter out the active project, proxy & glnow projects, and any project that doesn't match the search
    // Update: GLNOW projects now show up on staging and development per GLWEB-3337
    return (
      [name, origin].some((s) => s.includes(search)) &&
      p.project_key !== projectKey &&
      isRelevantProject
    );
  });

  const handleProjectSelect = (projectKey: string) => {
    saveSelectedProjectKey(projectKey);
    setSearchText('');
    navigate('.');
  };

  if (activeProject && userProjects.length > 1) {
    const projectName = activeProject.project_name;
    return (
      <Menu
        opened={opened}
        onChange={setOpened}
        width={320}
        offset={{ mainAxis: 5 }}
        position="bottom-start"
        data-testid="pw-project-selector"
        onOpen={() => setTimeout(() => searchInputRef.current?.focus(), 10)}
      >
        <Menu.Target>
          <Tooltip
            label={projectName}
            disabled={opened || (!isMobileSize && projectName.length <= 40)}
            withArrow={false}
          >
            <Button
              p={0}
              maw={400}
              variant="transparent"
              leftSection={
                <CircleAvatar name={projectName} backgroundColor="#6ED6CC" />
              }
              rightSection={
                isMobileSize ? undefined : (
                  <MdArrowDropDown color={colors.text[0]} size={24} />
                )
              }
              c="text.0"
              fw={700}
              classNames={{
                label: classes.label,
              }}
            >
              {projectName}
            </Button>
          </Tooltip>
        </Menu.Target>
        <Menu.Dropdown>
          <TextInput
            variant="transparent"
            mb={10}
            ref={searchInputRef}
            value={searchText}
            placeholder="Search..."
            rightSection={<MdSearch size={20} />}
            onChange={(e) => {
              // setPrevSearchText(searchText);
              setSearchText(e?.target?.value || '');
            }}
          />
          <ScrollArea
            w="100%"
            scrollbars="y"
            className="scrollArea"
            offsetScrollbars
          >
            <Box w="100%" mah={158}>
              {filteredProjects.map((project) => {
                const { project_key = '', project_name = '' } = project;
                return (
                  <Menu.Item
                    w="100%"
                    key={project_key}
                    onClick={() => handleProjectSelect(project_key)}
                  >
                    <Text
                      w="100%"
                      c="inherit"
                      fz="inherit"
                      fw="inherit"
                      truncate
                    >
                      {project_name}
                    </Text>
                  </Menu.Item>
                );
              })}
            </Box>
          </ScrollArea>
        </Menu.Dropdown>
      </Menu>
    );
  }

  if (activeProject && userProjects.length === 1) {
    const projectName = activeProject.project_name;

    return (
      <Tooltip
        label={projectName}
        disabled={!isMobileSize && projectName.length <= 40}
        withArrow={false}
      >
        <Flex align="center" gap={10}>
          <CircleAvatar name={projectName} backgroundColor="#6ED6CC" />
          <Text
            display={{ base: 'none', md: 'block' }}
            maw={200}
            fw={700}
            c="text.0"
            truncate
          >
            {projectName}
          </Text>
        </Flex>
      </Tooltip>
    );
  }

  return <div data-testid="empty" />;
};

export default ProjectSelector;
