import React from 'react';
import { Box, Flex, Text } from '@mantine/core';
import { useSegmentEditorContext } from '../../context/SegmentEditorProvider';

import { isFormattingTag } from '../Jliff/jliffFunctions';
import { Action, ActionBar } from '../Generic/ActionBar';

import { Insertable } from './InsertElement';
import { InsertMore } from './InsertMore';

import {
  BoldIcon,
  CommandIcon,
  ItalicIcon,
  RevealTagsIcon,
  UnderlineIcon,
} from '../../icons/ActionIcons';
import { getTextStyle } from '../Jliff/Jliff.style';
import QuickAction from '../Generic/QuickAction';

import classes from './TopBar.module.css';

const icons = {
  b: <BoldIcon />,
  i: <ItalicIcon />,
  u: <UnderlineIcon />,
} as const;

const labels = {
  b: 'Bold',
  i: 'Italic',
  u: 'Underline',
} as const;

const quickTags = Object.keys(icons);

type ActionsProps = {
  isTarget: boolean;
  isSelected?: boolean;
  addElement?: (insert: Insertable) => void;
  removeElement?: (tag: string) => void;
  haveTags?: boolean;
  activeTags?: string[];
  showAllTags: boolean;
  setShowAllTags: (showAllTags: boolean) => void;
};

export const TopBar = ({
  isTarget,
  isSelected: isSegmentSelected,
  haveTags,
  addElement,
  removeElement,
  activeTags = [],
  showAllTags,
  setShowAllTags,
}: ActionsProps) => {
  const { settings } = useSegmentEditorContext();
  const { disableTagEdit, viewOnly } = settings;
  const additionalTags =
    activeTags
      ?.filter((tag) => !quickTags.includes(tag))
      .reduce(
        (acc, tag) => (acc.includes(tag) ? acc : [...acc, tag]),
        [] as string[]
      ) || [];

  const quickAction = (tag: string) => {
    if (activeTags?.includes(tag)) {
      removeElement && removeElement(tag);
    } else if (addElement) {
      addElement({ type: 'tag', values: [tag] });
    }
  };

  const QuickActionLabel = ({
    iconKey,
    icon,
  }: {
    iconKey: string;
    icon: React.ReactNode;
  }) => {
    const label = labels[iconKey as keyof typeof labels];
    if (label) {
      return (
        <Flex align="center" justify="center" gap={10}>
          <Text c="inherit">{label}</Text>
          <Flex gap={3} align="center" justify="center" c="gray.2">
            <CommandIcon size={10} />
            <Box tt="uppercase">{iconKey}</Box>
          </Flex>
        </Flex>
      );
    }
    return <></>;
  };

  const actions: Action[] = quickTags.map((tag) => {
    const icon = icons[tag as keyof typeof icons];
    return {
      icon: icon,
      onClick: () => quickAction(tag),
      disabled: disableTagEdit || viewOnly,
      active: activeTags?.includes(tag),
      label: <QuickActionLabel iconKey={tag} icon={icon} />,
    };
  });

  const additionalActions: Action[] = additionalTags.map((tag) => {
    const isFormatting = isFormattingTag(tag);
    return {
      icon: (
        <Box
          opacity={isFormatting ? 1 : 0.7}
          style={{
            cursor: isFormatting ? 'pointer' : 'default',
            ...getTextStyle([tag]),
          }}
        >
          {tag.charAt(0).toUpperCase() + tag.substring(1, 2)}
        </Box>
      ),
      onClick: () => quickAction(tag),
      disabled: disableTagEdit || viewOnly,
      active: isFormattingTag(tag),
    };
  });

  return (
    <Flex
      className={`${classes.topBar} ${isTarget ? classes.target : classes.source}`}
    >
      {additionalTags.length > 0 && <ActionBar actions={additionalActions} />}
      {haveTags ? (
        <>
          <QuickAction
            icon={<RevealTagsIcon />}
            isActive={showAllTags}
            onClick={() => setShowAllTags(!showAllTags)}
            label="Show all Tags"
          />
          {isTarget && <Box className={classes.divider} />}
        </>
      ) : (
        <div></div>
      )}
      {!!addElement && (
        <Flex>
          <ActionBar actions={actions} />
          <InsertMore addElement={addElement} />
        </Flex>
      )}
    </Flex>
  );
};
