import { Xapis } from '@glweb/xapis-client';
import { queryOptions } from '@tanstack/react-query';

export const languageCodesQuery = <T>(
  endpoint: 'google' | 'ty',
  options?: Partial<T>
) => {
  return queryOptions({
    queryKey: ['languageCodes', endpoint],
    queryFn: () => getQueryData(endpoint),
    staleTime: 1000 * 60,
    ...options,
  });
};

const getQueryData = (endpoint: 'google' | 'ty') => {
  return Xapis.LanguageCodes.get(endpoint);
};
