import { CustomModal } from 'ui';
import { Textarea } from '@mantine/core';
import React from 'react';

type Props = {
  batch: HistoryBatch | undefined;
  opened: boolean;
  onClose: () => void;
};

const BatchCommentModal = ({ opened, batch, onClose }: Props) => (
  <CustomModal
    opened={opened}
    onClose={onClose}
    title={`${batch?.batch_name ?? ''} |  ${batch?.target_language_name ?? ''}`}
  >
    <Textarea
      placeholder="Additional comments"
      value={batch?.batch_comment ?? ''}
      disabled
      data-disabled={false}
      autosize
      minRows={3}
      w="100%"
      p={6}
      styles={{
        input: {
          cursor: 'default',
          backgroundColor: 'white',
        },
      }}
    />
  </CustomModal>
);

export default BatchCommentModal;
