import { dashboardRC } from '@glweb/constants';
import { Xapis } from '@glweb/xapis-client/src/xapis-wrappers/xapis';
import { Card } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { isSuccessStatus } from 'helpers';
import { MRT_RowSelectionState } from 'mantine-react-table';
import { useState } from 'react';
import { failureNotification, Header, successNotification } from 'ui';
import { ActiveBatchesTable } from '../../components/batches/active-batches/ActiveBatchesTable';
import ActiveBatchFilters from '../../components/batches/active-batches/filters/ActiveBatchFilters';
import { CancelBatchesModal } from '../../components/batches/CancelBatchesModal';
import { GradeBatchModal } from '../../components/batches/GradeBatchModal';
import EditBatchModal from '../../components/batches/EditBatchModal';
import { useFilteredActiveBatches } from '../../queries/batchesQueries';
import { CompleteBatchesModal } from '../../components/batches/batch-history/CompleteBatchesModal';
export type BatchFieldOptions = 'phase' | 'phase_status' | 'assigned_user';

const ActiveBatchesPage = () => {
  const { batches, assigneeOptions } = useFilteredActiveBatches();

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>(
    {} as MRT_RowSelectionState
  );

  const [
    cancelBatchModalOpened,
    { open: openCancelModal, close: closeCancelModal },
  ] = useDisclosure();
  const [
    completeBatchesModalOpened,
    { open: openCompleteModal, close: closeCompleteBatchesModal },
  ] = useDisclosure();
  const [
    gradeBatchModalOpened,
    { open: openGradingModal, close: closeGradingModal },
  ] = useDisclosure();

  const [
    editBatchModalOpened,
    { open: openEditModal, close: closeEditBatchModal },
  ] = useDisclosure();

  const [batchKeysToChange, setBatchKeysToChange] = useState<string[]>([]);
  const [editingBatchKey, setEditingBatchKey] = useState('');
  const [existingBatchComment, setExistingBatchComment] = useState('');

  const openCancelBatchesModal = (batches: string[]) => {
    setBatchKeysToChange(batches);
    openCancelModal();
  };

  const openCompleteBatchesModal = (batches: string[]) => {
    setBatchKeysToChange(batches);
    openCompleteModal();
  };

  const openEditBatchModal = (batch: string) => {
    setEditingBatchKey(batch);
    openEditModal();
  };
  const openGradingBatchModal = (batch: string) => {
    setBatchKeysToChange([batch]);
    setExistingBatchComment(
      batches.find(({ batch_key }) => batch_key === batch)?.batch_comment ?? ''
    );
    openGradingModal();
  };

  const handleBatchChange = (
    batchKeys: string[],
    params: Partial<Record<Partial<BatchFieldOptions>, string>>
  ) => {
    const batchesToChange = batches.filter(({ batch_key }) =>
      batchKeys.includes(batch_key)
    );
    const organizedBatches = batchesToChange.reduce(
      (acc, batch) => {
        const { translation_key, batch_key } = batch;
        if (!acc[translation_key]) {
          return { ...acc, [translation_key]: [batch_key] };
        }
        acc[translation_key].push(batch_key);
        return { ...acc };
      },
      {} as Record<string, string[]>
    );

    Promise.allSettled(
      Object.entries(organizedBatches).map(([translationKey, batchList]) => {
        return Xapis.Batches.patch({
          translationKey,
          batchKeyList: batchList.join(','),
          params,
        });
      })
    )
      .then((xapisBatchPatchResults) => {
        xapisBatchPatchResults.forEach((xapisBatchPatchResult) => {
          if (xapisBatchPatchResult.status === 'fulfilled') {
            const xapisBatchResponse = xapisBatchPatchResult.value || {};
            const { status, data } = xapisBatchResponse;
            const hasBatchNames = Boolean(data?.batches[0]?.batch_name);

            if (!isSuccessStatus(status)) {
              hasBatchNames
                ? failureNotification(
                    xapisBatchResponse,
                    `Batch${data.batches.length > 1 ? 'es' : ''} (${data.batches.map(({ batch_name }: ActiveBatch) => batch_name).join(', ')}) failed.`
                  )
                : failureNotification(xapisBatchResponse);
            } else {
              hasBatchNames
                ? successNotification(
                    xapisBatchResponse,
                    `Batch${data.batches.length > 1 ? 'es' : ''} (${data.batches.map(({ batch_name }: ActiveBatch) => batch_name).join(', ')}) updated.`
                  )
                : successNotification(xapisBatchResponse);
            }
          } else {
            failureNotification(xapisBatchPatchResult.reason);
          }
        });
      })
      .finally(() => {
        const updatedPhaseStatus = params['phase_status']?.toLowerCase();
        if (['completed', 'cancelled'].includes(updatedPhaseStatus ?? '')) {
          setRowSelection({} as MRT_RowSelectionState);
        }
        closeCompleteBatchesModal();
        closeCancelModal();
      });
  };

  return (
    <>
      <Header title={dashboardRC.PAGE_TITLE_ACTIVE_BATCHES} />
      <ActiveBatchFilters
        selectedBatches={Object.entries(rowSelection)
          .filter(([, value]) => value)
          .map(([key, ,]) => key)}
        assigneeOptions={assigneeOptions}
        handleBatchChange={handleBatchChange}
        openCancelBatchModal={openCancelBatchesModal}
        openCompleteBatchesModal={openCompleteBatchesModal}
        openGradeBatchModal={openGradingBatchModal}
        openEditBatchModal={openEditBatchModal}
      />
      <Card p={0} style={{ boxShadow: 'none' }} mt={20}>
        <ActiveBatchesTable
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          handleBatchChange={handleBatchChange}
          batches={batches}
          openCancelBatchesModal={openCancelBatchesModal}
          openCompleteBatchesModal={openCompleteBatchesModal}
          openGradingBatchModal={openGradingBatchModal}
          openEditBatchModal={openEditBatchModal}
        />
      </Card>
      <CancelBatchesModal
        hasMultipleBatches={batchKeysToChange.length > 1}
        cancelBatch={(params) => handleBatchChange(batchKeysToChange, params)}
        opened={cancelBatchModalOpened}
        close={closeCancelModal}
      />
      <CompleteBatchesModal
        hasMultipleBatches={batchKeysToChange.length > 1}
        completeBatches={(params) =>
          handleBatchChange(batchKeysToChange, params)
        }
        opened={completeBatchesModalOpened}
        close={closeCompleteBatchesModal}
      />
      <GradeBatchModal
        editBatch={(params) => handleBatchChange(batchKeysToChange, params)}
        opened={gradeBatchModalOpened}
        close={closeGradingModal}
        existingComment={existingBatchComment}
      />
      <EditBatchModal
        batch={
          batches.find(({ batch_key }) => batch_key === editingBatchKey) ??
          ({} as ActiveBatch)
        }
        editBatch={(params) => handleBatchChange([editingBatchKey], params)}
        opened={editBatchModalOpened}
        close={closeEditBatchModal}
      />
    </>
  );
};

export default ActiveBatchesPage;
