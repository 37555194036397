export const LOCAL = 'Local';
export const STAGING = 'Staging';
export const RC = 'RC';
export const CUSTOM = 'Custom';
export const LIVE = 'Live';
export const hostLabels = [LOCAL, STAGING, RC, LIVE] as const;
export type HostLabel = (typeof hostLabels)[number];

// Change to true to skip SSO in LOCAL environment
export const SKIP_SSO = false; // Change to true to skip SSO

export const STAGING_SSO_URL = 'https://sso-stg.transperfect.com';
export const LIVE_SSO_URL = 'https://sso.transperfect.com';
export const LOCAL_XAPIS_URL = `http://localhost/xapis`;
export const STAGING_XAPIS_URL = `https://api.onelink-preview.com/xapis`;
export const RC_XAPIS_URL = `https://rc.onelink-edge.com/xapis`;
export const LIVE_XAPIS_URL = `https://www.onelink-edge.com/xapis`;
export const LOCAL_NOTIFY_URL = 'http://localhost:3200/notify';
export const STAGING_NOTIFY_URL = 'https://notify.onelinkjs-staging.com/notify';
export const LIVE_NOTIFY_URL = 'https://notify.onelinkjs.com/notify';
export const LOCAL_GLGO_MARKETING_URL = 'http://localhost:3000';
export const STAGING_GLGO_MARKETING_URL =
  'https://www.globallinkgo-staging.com';
export const STAGING_WP_URL =
  'https://glgostaging.wpenginepowered.com/wp-json/wp/v2';
export const LIVE_GLGO_MARKETING_URL = 'https://www.globallinkweb.com';
export const LOCAL_GLGO_DASHBOARD_URL = 'http://localhost:4000';
export const STAGING_GLGO_DASHBOARD_URL =
  'https://dashboard.globallinkgo-staging.com';
export const RC_GLGO_DASHBOARD_URL = 'https://dashboard.globallinkgo-rc.com';
export const LIVE_GLGO_DASHBOARD_URL = 'https://dashboard.globallinkweb.com';
export const STAGING_MOXIE_INJECTION_URL =
  'https://api.onelink-preview.com/moxie.min.js';
export const RC_MOXIE_INJECTION_URL =
  'https://rc.onelink-edge.com/moxie.min.js';
export const LIVE_MOXIE_INJECTION_URL =
  'https://www.onelink-edge.com/moxie.min.js';
export const LIVE_XPAY_URL = 'https://xpay.onelinkjs.com';
export const LIVE_WP_URL =
  'https://globallinkgo.wpenginepowered.com/wp-json/wp/v2';

/* Analytics  */
export const REMARKET_STATS_BASEURL =
  'https://a.remarketstats.com/px/smart/?c=25d8c5efaef6cd7&seg=';

const STAGING_GLWEB_MEASUREMENT_ID = 'G-QWP4YP33KH';
const PROD_GLWEB_MEASUREMENT_ID = 'G-604S1YKBV4';

/* External Links */
const MAC_GLGO_DESKTOP_DMG =
  'https://download.globallinkweb.com/GLWeb-Desktop-latest.dmg';
const WIN_GLGO_DESKTOP_EXE =
  'https://download.globallinkweb.com/GLWeb-Desktop-latest.exe';

const STAGING_MAC_GLGO_DESKTOP_DMG =
  'http://glweb-marketing-staging.s3-website-us-east-1.amazonaws.com/files/GLWeb-Desktop-latest.dmg';
const STAGING_WIN_GLGO_DESKTOP_EXE =
  'http://glweb-marketing-staging.s3-website-us-east-1.amazonaws.com/files/GLWeb-Desktop-latest.exe';

const RC_MAC_GLGO_DESKTOP_DMG =
  'http://glweb-marketing-rc.s3-website-us-east-1.amazonaws.com/files/GLWeb-Desktop-latest.dmg';
const RC_WIN_GLGO_DESKTOP_EXE =
  'http://glweb-marketing-rc.s3-website-us-east-1.amazonaws.com/files/GLWeb-Desktop-latest.exe';

export const XAPIS_PAY_KEY_TEST = 'E6D1-BF7F-A10B-9E5E';
const XAPIS_PAY_KEY_PROD = 'BE8E-3B10-2ECF-CE27';

export const XAPIS_PROJECT_KEY_TEST = '354E-1001-7F91-341B';
export const XAPIS_USER_KEY_TEST = '1D65-0B5B-64F8-C9A7';

export const DIGITAL_RIVER_KEY_TEST =
  'pk_test_b4eeb52619c743fe8cf86b781430687a';
const DIGITAL_RIVER_KEY_PROD = 'pk_1058d12b2ee7401b9e47e6dde8865332';

export const GLWEB_SUPPORT_URL = 'https://support.globallinkweb.com';

export type HostEntry = {
  label: HostLabel;
  glgoDashboardUrl: string;
  glgoMarketingUrl: string;
  notifyUrl: string;
  xapisUrl: string;
  ssoUrl: string;
  payKey: string;
  digitalRiverKey: string;
  moxieInjectionUrl: string;
  googleAnalyticsId: string;
  releaseNotesURL: string;
  desktopDownloadMacUrl: string;
  desktopDownloadExeUrl: string;
  sugar: typeof sugarConstants;
};

export type SugarFields = (typeof sugarConstants)['fields'];

const LIVE_SUGAR_USERID = '00540000000oP0VAAU';
const LIVE_SUGAR_CAMPAIGN_ID = '62758b16-e454-11e7-bfe8-0699afa2cd79';

const sugarConstants = {
  url: LIVE_XAPIS_URL + '/CRM/sugar',
  fields: {
    fq_email_distro_c: 'partnerlink@transperfect.com',
    assigned_user_id: LIVE_SUGAR_USERID,
    lead_source: 'Website Form',
    eng_event_name_c: 'GlobalLink WEB Create Account',
    engagement_type_c: 'Form_CreateAccount',
    eng_new_check_c: '1',
    fq_check_c: '1',
    eng_origin_site_c: 'GlobalLink Web',
    eng_vertical_c: 'Tech_GL',
    primary_address_city: '',
    primary_address_state: '',
    primary_address_country: '',
    primary_address_postalcode: '',
    fq_all_source_c: '',
    phone_work: '',
    campaign_id: LIVE_SUGAR_CAMPAIGN_ID,
    team_id: '1',
    team_set_id: '1',
    title: '',
    account_name: '',
    fq_interest_c: '',
    fq_other_comment_c: '',
    privacy_policy_c: 1,
  } as const,
};

export type HostList = {
  [key in HostLabel]: HostEntry;
};

// Set this in a browser's application storage if you wish to override the environment (ex. HOST_OVERRIDE = LIVE)
export const HOST_OVERRIDE = 'HOST_OVERRIDE';
// Set inside WrapperConfig.tsx, determines the environment for all APIs and Links
export const APP_ENVIRONMENT = 'APP_ENVIRONMENT';

export const hostConstants: HostList = {
  [LOCAL]: {
    label: LOCAL,
    glgoDashboardUrl: LOCAL_GLGO_DASHBOARD_URL,
    glgoMarketingUrl: LOCAL_GLGO_MARKETING_URL,
    notifyUrl: STAGING_NOTIFY_URL, // Can use LOCAL_NOTIFY if you want
    xapisUrl: STAGING_XAPIS_URL, // Can use LOCAL_API_URL if you want
    ssoUrl: STAGING_SSO_URL,
    payKey: XAPIS_PAY_KEY_TEST,
    digitalRiverKey: DIGITAL_RIVER_KEY_TEST,
    moxieInjectionUrl: STAGING_MOXIE_INJECTION_URL,
    googleAnalyticsId: STAGING_GLWEB_MEASUREMENT_ID,
    releaseNotesURL: STAGING_WP_URL,
    desktopDownloadMacUrl: STAGING_MAC_GLGO_DESKTOP_DMG,
    desktopDownloadExeUrl: STAGING_WIN_GLGO_DESKTOP_EXE,
    sugar: sugarConstants,
  },
  [STAGING]: {
    label: STAGING,
    glgoDashboardUrl: STAGING_GLGO_DASHBOARD_URL,
    glgoMarketingUrl: STAGING_GLGO_MARKETING_URL,
    notifyUrl: STAGING_NOTIFY_URL,
    xapisUrl: STAGING_XAPIS_URL,
    ssoUrl: LIVE_SSO_URL,
    payKey: XAPIS_PAY_KEY_TEST,
    digitalRiverKey: DIGITAL_RIVER_KEY_TEST,
    moxieInjectionUrl: STAGING_MOXIE_INJECTION_URL,
    googleAnalyticsId: STAGING_GLWEB_MEASUREMENT_ID,
    releaseNotesURL: STAGING_WP_URL,
    desktopDownloadMacUrl: STAGING_MAC_GLGO_DESKTOP_DMG,
    desktopDownloadExeUrl: STAGING_WIN_GLGO_DESKTOP_EXE,
    sugar: sugarConstants,
  },
  [RC]: {
    label: RC,
    glgoDashboardUrl: RC_GLGO_DASHBOARD_URL,
    glgoMarketingUrl: STAGING_GLGO_MARKETING_URL,
    notifyUrl: STAGING_NOTIFY_URL,
    xapisUrl: RC_XAPIS_URL,
    ssoUrl: LIVE_SSO_URL,
    payKey: XAPIS_PAY_KEY_TEST,
    digitalRiverKey: DIGITAL_RIVER_KEY_TEST,
    moxieInjectionUrl: RC_MOXIE_INJECTION_URL,
    googleAnalyticsId: STAGING_GLWEB_MEASUREMENT_ID,
    releaseNotesURL: STAGING_WP_URL,
    desktopDownloadMacUrl: RC_MAC_GLGO_DESKTOP_DMG,
    desktopDownloadExeUrl: RC_WIN_GLGO_DESKTOP_EXE,
    sugar: sugarConstants,
  },
  [LIVE]: {
    label: LIVE,
    glgoDashboardUrl: LIVE_GLGO_DASHBOARD_URL,
    glgoMarketingUrl: LIVE_GLGO_MARKETING_URL,
    notifyUrl: LIVE_NOTIFY_URL,
    xapisUrl: LIVE_XAPIS_URL,
    ssoUrl: LIVE_SSO_URL,
    payKey: XAPIS_PAY_KEY_PROD,
    digitalRiverKey: DIGITAL_RIVER_KEY_PROD,
    moxieInjectionUrl: LIVE_MOXIE_INJECTION_URL,
    googleAnalyticsId: PROD_GLWEB_MEASUREMENT_ID,
    releaseNotesURL: LIVE_WP_URL,
    desktopDownloadMacUrl: MAC_GLGO_DESKTOP_DMG,
    desktopDownloadExeUrl: WIN_GLGO_DESKTOP_EXE,
    sugar: sugarConstants,
  },
};
const { VITE_IS_GLWEB_DESKTOP } = import.meta.env;

// This function is NOT being used in GLWEB. It kept here for XPAY.
const getXapisEnv = (): HostLabel => {
  // Use "typeof X === "undefined"" instead of "!X" because of build bugs
  if (typeof window === 'undefined' || typeof localStorage === 'undefined') {
    return STAGING;
  }

  const hostOverride = sessionStorage.getItem(HOST_OVERRIDE) || '';
  if (hostLabels.includes(hostOverride as HostLabel)) {
    return hostOverride as HostLabel;
  }

  const xapisEnv = sessionStorage.getItem(APP_ENVIRONMENT) || '';
  if (!xapisEnv) {
    if (VITE_IS_GLWEB_DESKTOP) {
      return LOCAL;
    } else {
      const websiteUrl = window.location.href.toLocaleLowerCase();
      if (websiteUrl.includes('localhost')) {
        sessionStorage.setItem(APP_ENVIRONMENT, LOCAL);
        return LOCAL;
      } else if (websiteUrl.includes('staging')) {
        sessionStorage.setItem(APP_ENVIRONMENT, STAGING);
        return STAGING;
      } else if (websiteUrl.includes('rc')) {
        sessionStorage.setItem(APP_ENVIRONMENT, RC);
        return RC;
      } else if (
        websiteUrl.includes('globallinkgo') ||
        websiteUrl.includes('globallinkweb')
      ) {
        sessionStorage.setItem(APP_ENVIRONMENT, LIVE);
        return LIVE;
      } else {
        sessionStorage.setItem(APP_ENVIRONMENT, LIVE);
        return LIVE;
      }
    }
  }
  return xapisEnv as HostLabel;
};

const NOT_FOUND = 'NOT_FOUND';
const notFoundEntry: HostEntry = {
  label: NOT_FOUND as HostLabel,
  glgoDashboardUrl: NOT_FOUND,
  glgoMarketingUrl: NOT_FOUND,
  notifyUrl: NOT_FOUND,
  xapisUrl: NOT_FOUND,
  ssoUrl: NOT_FOUND,
  payKey: NOT_FOUND,
  digitalRiverKey: NOT_FOUND,
  moxieInjectionUrl: NOT_FOUND,
  googleAnalyticsId: NOT_FOUND,
  releaseNotesURL: NOT_FOUND,
  desktopDownloadMacUrl: NOT_FOUND,
  desktopDownloadExeUrl: NOT_FOUND,
  sugar: {} as typeof sugarConstants,
};

// This function is NOT being used in GLWEB. It kept here for XPAY.
export const getHostConstants = (label?: string): HostEntry => {
  if (!label) {
    const xapisEnv = getXapisEnv();
    if (!xapisEnv) {
      return notFoundEntry;
    }
    return getHostConstants(xapisEnv);
  }

  const hostConstant = hostConstants[label as HostLabel];
  if (!hostConstant) {
    return notFoundEntry;
  }

  return hostConstant;
};
