import ConfirmMethodModal from './ConfirmMethodModal';
import { Divider, Stack } from '@mantine/core';
import { Header } from 'ui';
import DomainSettings from './DomainSettings';
import DeploymentMethod from './DeploymentMethod';
import Injection from './Injection';
import LanguageConfiguration from './LanguageConfiguration';

const Installation = () => {
  return (
    <>
      <Header title="Deployment Settings" />
      <Stack gap={20} w={'70%'} miw={550} maw={1000}>
        <Divider />
        <DomainSettings />
        <DeploymentMethod />
        <LanguageConfiguration />
        <Injection />
        <ConfirmMethodModal />
      </Stack>
    </>
  );
};

export default Installation;
