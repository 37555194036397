import {
  ProjectUserPermissionMap,
  USER_ROLE_GLGO_ADMIN,
  USER_ROLE_TRANSPERFECT_ADMIN,
  USER_ROLE_TRANSPERFECT_USER,
} from 'helpers/src/projectPermissions';

export const filterOutTPRoles = (map: ProjectUserPermissionMap) =>
  Object.fromEntries(
    Object.entries(map).filter(
      ([, { rolePermissionSet }]: [
        string,
        { rolePermissionSet: Set<string> },
      ]) =>
        !rolePermissionSet.has(USER_ROLE_TRANSPERFECT_USER) &&
        !rolePermissionSet.has(USER_ROLE_TRANSPERFECT_ADMIN) &&
        !rolePermissionSet.has(USER_ROLE_GLGO_ADMIN)
    )
  );
