import { createStore } from 'zustand/vanilla';
import { UndoRedo } from '../classes/UndoRedo';

export type EditHistoryStore = {
  undoRedo: UndoRedo;
  segmentReview: SegmentReview | null;

  actions: {
    setUndoRedo: (undoRedo: UndoRedo) => void;
    setSegmentReview: (reviewData: Partial<SegmentReview> | null) => void;
  };
};

export function createEditHistoryStore(originalTargetJliff: Jliff[]) {
  return createStore<EditHistoryStore>((set, get) => ({
    undoRedo: new UndoRedo([...originalTargetJliff]),
    segmentReview: null,

    actions: {
      setUndoRedo: (undoRedo) => set({ undoRedo }),
      setSegmentReview: (reviewData: Partial<SegmentReview> | null) => {
        // null means don't save this value
        if (reviewData !== null) {
          const updatedReview = (get().segmentReview || {}) as SegmentReview;
          Object.entries(reviewData).forEach(([key, value]) => {
            if (value === null) {
              delete updatedReview[key as keyof SegmentReview];
            } else {
              (updatedReview as any)[key] = value;
            }
          });
          set({
            segmentReview:
              Object.keys(updatedReview).length > 0 ? updatedReview : null,
          });
        } else {
          set({ segmentReview: null });
        }
      },
    },
  }));
}
