import createDifferenceSet from './createDifferenceSet';

const globalRouterConstants = {
  PAGE_ROOT: '/',
};

export const GRC = globalRouterConstants;

// Specific XAPIS HTTP response status code when a resource is already made so no further action is taken (usually for POST)
export const ALREADY_CREATED_CODE = 208;

export const NOOP = () => undefined;
export const LAST_PROJECT_KEY_COOKIE = 'glgoLastProjectKey';

export const GLGO_PROJECT_TYPE = 'GLGO';
export const GLGO_ENTERPRISE_PROJECT_TYPE = 'GLGO-ENTERPRISE';
export const GLWEB_PROJECT_TYPE = 'GLWEB';
export const OLJS_PROJECT_TYPE = 'OLJS';
export const GLNOW_PROJECT_TYPE = 'GLNOW';

export const AllProjectTypesSet = new Set([
  GLGO_PROJECT_TYPE,
  GLGO_ENTERPRISE_PROJECT_TYPE,
  GLWEB_PROJECT_TYPE,
  OLJS_PROJECT_TYPE,
  GLNOW_PROJECT_TYPE,
]);

export const nonGLNOWProjectTypesSet = createDifferenceSet(
  AllProjectTypesSet,
  new Set([GLNOW_PROJECT_TYPE])
);

export default GRC;
