import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Flex, MantineProvider, Text } from '@mantine/core';
import { notifications, Notifications } from '@mantine/notifications';
import { StoreApi } from 'zustand';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

import { useSegmentEditorContext } from './context/SegmentEditorProvider';
import {
  fetchBlockSegmentData,
  saveSegmentsTranslation,
  deleteSegmentTranslation,
  fetchBatchSegments,
} from './http/dataAPIs';
import { createHttpService, setAccessToken } from './http/http';
// Stores
import {
  useSegmentsAttributes,
  useSegmentsStateActions,
} from './store/SegmentsState';
import { useDashboardActions, useFilters } from './store/DashboardStore';
import {
  EditHistoryStore,
  createEditHistoryStore,
} from './store/EditHistoryStore';
// Types
import { SortBy } from './types/editor';
// Classes
import { SList } from './classes/segmentList';
import { BlockSegmentsMap } from './classes/BlockSegmentsMap';
// Components
import EditorDashboard from './components/Dashboard/EditorDashboard';
import Segment from './components/Segment/Segment';
import SortSegments from './components/Dashboard/SortSegments';
import SearchSegments from './components/Dashboard/SearchSegments';
import { Wait } from './components/Generic/Wait';
import { UnsavedChangesModal } from './components/UnsavedChangesModal/UnsavedChangesModal';
import { AlertIcon } from './icons/IndicatorIcons';
// Functions
import {
  blocksToSegments,
  filterSegments,
  generateSegmentsObject,
  getFiltersOptions,
  processBatchSegments,
  processSegmentResponse,
  sortSegments,
} from './functions/segmentsFunctions';
import { getSegments } from './components/Dashboard/getSegmentsPage';
// Styling
import { segmentEditorTheme } from './theme/theme';
import '@mantine/dates/styles.layer.css';
import classes from './SegmentEditor.module.css';
import { notificationColors } from './theme/utils';
import { assignTestValues } from './utils/devUtils';
import { BatchTopBar } from './components/Batch/BatchTopBar';
import {
  useBatchReviewActions,
  useBatchReviewState,
} from './store/BatchReviewStore';
import { Xapis } from './http/xapis';
import { isSuccessStatus } from 'helpers';

export interface UpdateUnsavedList {
  (
    sHash: string,
    action: {
      type: 'SET_TO_LIVE' | 'SET_JLIFF' | 'REMOVE_JLIFF' | 'REMOVE_FROM_LIST';
      payload?: { target?: Jliff[] | null; setToLive?: boolean };
    }
  ): void;
}

export interface SegmentEditorProps {
  filters?: {
    segments?: string[];
    blocks?: string[];
  };
  contextData: ContextData | null;
}

export interface SegmentEditorRef {
  checkForUnsavedChanges: () => Promise<boolean>;
}

function SegmentEditorInternal(
  { contextData, filters }: SegmentEditorProps,
  ref: React.Ref<SegmentEditorRef>
) {
  const { reviewedSegments } = useBatchReviewState();
  const {
    updateUnsavedReviews,
    updateReviewedSegments,
    initReviewedSegments,
    clearUnsavedReviews,
  } = useBatchReviewActions();
  const { setFiltersOptions, setExternalFilter } = useDashboardActions();
  const {
    resetUnsaved,
    addModified,
    removeModified,
    addSetToLive,
    removeSetToLive,
    setSaved,
    setFailedSave,
    resetSegmentsState,
    setAttributes,
  } = useSegmentsStateActions();

  const {
    contextAPI = {},
    target,
    settings,
    batch,
    userKey,
  } = useSegmentEditorContext();

  const { translation_key, num_segment_translations } = target;
  const isBatchMode = Boolean(
    batch &&
      batch.translationKey === translation_key &&
      batch.urlHash &&
      batch.batchKey
  );
  const { contextMode, accessToken, xapisHost, viewOnly } = settings;
  const isInScope =
    !contextMode || !contextData?.site ? true : contextData.site.isInScope;
  setAccessToken(accessToken);
  createHttpService(xapisHost);
  const { onTranslationChange, onSelect, onReviewChange } = contextAPI;
  const onSelectSegment = (sHash: string) => {
    if (typeof onSelect === 'function') {
      onSelect({
        segmentHash: sHash,
        blocks: blockSegmentMap.current.getSegmentsBlocks([sHash]),
      });
    }
  };

  const currentUrl = useRef<string>('');
  const unsavedSegments = useRef(new SList());
  const jliffStores = useRef<Record<string, StoreApi<EditHistoryStore>>>({});

  const [loadingSegments, setLoadingSegments] = useState(false);

  const [saving, setSaving] = useState(false);
  const segmentsAttributes = useSegmentsAttributes();
  const [segmentsObj, setSegments] = useState<SegmentsObj>({});
  const blockSegmentMap = useRef(new BlockSegmentsMap());
  const hasSegmentsAndMap =
    Object.keys(segmentsObj).length > 0 && !blockSegmentMap.current.isEmpty;
  const [sortBy, setSortBy] = useState<SortBy>({
    field: 'date',
    ascending: true,
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState('');
  const filterBy = useFilters();
  const searchBy = contextMode
    ? { text: searchText } // Use local state for searching text
    : { text: searchParams.get('s') || '' }; // Use URL search params
  const allSegmentsHashes = Object.keys(segmentsObj);
  const externalFilter = blockSegmentMap.current
    .getSegmentsHashes(filters)
    .filter((sHash) => allSegmentsHashes.includes(sHash));
  // Number of segment translations from /Segment API
  const [numSegmentTranslations, setNumSegmentTranslations] = useState(
    num_segment_translations
  );
  const batchReviewProgress =
    isBatchMode && allSegmentsHashes.length > 0
      ? Math.round((100 * reviewedSegments.size) / allSegmentsHashes.length)
      : 0;

  const virtuoso = useRef<VirtuosoHandle>(null);

  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const [modalCloseResolver, setModalCloseResolver] = useState<
    (() => void) | null
  >(null);

  const initSegment = (segment: Segment) => {
    const sHash = segment.segment_hash;
    const targetJliff = segment.target_jliff || segment.segment_jliff || [];

    const store = createEditHistoryStore(targetJliff);
    store.subscribe((state) => {
      // Maintain list of unsaved segments reviews
      updateUnsavedReviews(sHash, state.segmentReview !== null);
    });
    jliffStores.current[sHash] = store;
  };
  const resetSegment = (segment: Segment) => {
    // Reset translation history
    const sHash = segment.segment_hash;
    const targetJliff = segment.target_jliff || segment.segment_jliff || [];

    const store = jliffStores.current[sHash];
    if (!store) {
      console.warn('resetSegment: store is null', sHash);
      return;
    }
    const state = store.getState();
    state.undoRedo.reset(targetJliff);
  };

  const updateSegmentsObject = useCallback(
    (
      state: SegmentsObj,
      action: {
        type: 'ADD' | 'RESET' | 'UPDATE' | 'DELETE';
        payload: { segments: SegmentsObj; attributes?: BlockAttributes | null };
      }
    ) => {
      const {
        type,
        payload: { segments, attributes },
      } = action;
      let newSegmentsObj = { ...state };

      switch (type) {
        case 'RESET': {
          // Clear sets when resetting segments
          clearUnsavedReviews();
          const reviewed = [] as string[];

          newSegmentsObj = { ...segments };
          Object.entries(segments).forEach(([sHash, segment]) => {
            // Reset the store for the segment
            if (isBatchMode && (segment as BatchSegment).is_reviewed) {
              reviewed.push(sHash);
            }
            initSegment(segment);
          });
          initReviewedSegments(reviewed);
          resetSegmentsState(attributes);
          break;
        }
        case 'ADD': {
          const reviewed = Array.from(reviewedSegments);
          Object.entries(segments).forEach(([sHash, segment]) => {
            if (!newSegmentsObj[sHash]) {
              newSegmentsObj[sHash] = segment;
              if (isBatchMode && (segment as BatchSegment).is_reviewed) {
                reviewed.push(sHash);
              }
              initSegment(segment);
            }
          });
          initReviewedSegments(reviewed);
          setAttributes(attributes);
          break;
        }
        case 'UPDATE': {
          Object.entries(segments).forEach(([sHash, segment]) => {
            if (!newSegmentsObj[sHash]) {
              console.warn('updateSegmentsObject: segment not found:', sHash);
            }
            // Override segments object with new segments
            newSegmentsObj[sHash] = segment;
            resetSegment(segment);
          });
          break;
        }
        case 'DELETE': {
          if (!segments) return;
          Object.keys(segments).forEach((sHash) => {
            delete newSegmentsObj[sHash];
            delete jliffStores.current[sHash];
            // Clean up sets when deleting segments
            updateUnsavedReviews(sHash, false);
            updateReviewedSegments(sHash, false);
          });
          break;
        }
        default:
          break;
      }
      // Update filters options
      if (Object.keys(newSegmentsObj).length) {
        setFiltersOptions(getFiltersOptions(newSegmentsObj, attributes));
      }

      setSegments({ ...newSegmentsObj });
    },
    [resetSegmentsState] // Keep existing dependencies
  );
  // -----------------------------------------------------
  // Callback function run when context data is received
  async function handleContextData(data: ContextData | null) {
    if (!contextMode || !data) return;

    const { blocksData, site, attributes } = data;

    if (isBatchMode) {
      // In Batch mode, we should not update the segments object
      // only update attributes
      // and create a blockSegmentMap
      if (attributes) {
        setAttributes(attributes);
        setFiltersOptions(getFiltersOptions(segmentsObj, attributes));
      }
      // Ignore blocks found by moxie (only care about segments in the batch)
      if (data.blocksData) {
        return;
      }
      const response = await fetchBlockSegmentData(site.urlHash, null);
      if (response.status === 200 && response.blockSegments) {
        const { blockSegments } = response;
        blockSegmentMap.current.setBlocks({ blocksData, blockSegments });
      }
      return;
    }

    const newBlocks = blocksData
      ? blocksData.filter(
          (block) => !blockSegmentMap.current.hasBlock(block.block_hash)
        )
      : null;

    if (blocksData && !newBlocks?.length) {
      if (attributes) {
        // attributes can change even if no new blocks
        setAttributes(attributes);
        setFiltersOptions(getFiltersOptions(segmentsObj, attributes));
      }
      setLoadingSegments(false);
      return;
    }

    !allSegmentsHashes.length && setLoadingSegments(true);
    const response = await fetchBlockSegmentData(site.urlHash, newBlocks);

    // If status = 200, convert blocks to segments and add to state
    if (response.status === 200 && response.blockSegments) {
      const { blockSegments } = response;
      const isSameUrl = currentUrl.current === site.url;
      const createBlockMap = isSameUrl
        ? blockSegmentMap.current.addBlocks.bind(blockSegmentMap.current)
        : blockSegmentMap.current.setBlocks.bind(blockSegmentMap.current);
      // Update blocksSegmentsMap
      createBlockMap({ blockSegments, blocksData });
      // In batch mode, don't update segments object
      // Update segments object
      const action = isSameUrl ? 'ADD' : 'RESET';
      const newSegments = blocksToSegments(blockSegments);
      updateSegmentsObject(segmentsObj, {
        type: action,
        payload: { segments: newSegments, attributes },
      });
      currentUrl.current = site.url;
    } else {
      console.error(
        'SE::Error getting blocks:',
        response.status,
        response.error
      );
    }
    setLoadingSegments(false);
  }

  const handleSearchChange = (text: string) => {
    if (!contextMode) {
      // Use URL search params for search text on server
      const updatedParams = new URLSearchParams(searchParams);
      text ? updatedParams.set('s', text) : updatedParams.delete('s');
      setSearchParams(updatedParams);
      // Fetch first page of segments with search text
      fetchSegmentsPage('first', updatedParams);
    } else {
      // Use local state for search segments in memory
      setSearchText(text);
    }
  };

  // Fetch page of segments from /Segment API
  const fetchSegmentsPage = (
    page: 'first' | 'next',
    params?: URLSearchParams
  ) => {
    getSegments(translation_key, page, params || searchParams)
      .then((res) => {
        const { data, ignore } = res;
        if (ignore || !data) return; // Stale response
        setNumSegmentTranslations(data.rows_total);
        const segments = processSegmentResponse(data.segments);
        if (page === 'first') {
          // Reset infinite scroll
          virtuoso.current?.scrollToIndex({ index: 0 });
          updateSegmentsObject(
            {},
            {
              type: 'RESET',
              payload: { segments },
            }
          );
        } else {
          updateSegmentsObject(segmentsObj, {
            type: 'ADD',
            payload: { segments },
          });
        }
      })
      .catch(() => {
        notifications.show({
          message: 'Error loading segments.',
          color: notificationColors('error'),
        });
      })
      .finally(() => {
        setLoadingSegments(false);
      });
  };

  const getBatchSegments = () => {
    fetchBatchSegments({
      batchKey: batch!.batchKey,
      urlHash: batch!.urlHash,
      tKey: batch!.translationKey,
      userKey,
    })
      .then((res) => {
        const { data } = res;
        const segments = processBatchSegments(data?.segments || []);
        updateSegmentsObject(
          {},
          {
            type: 'RESET',
            payload: { segments },
          }
        );
      })
      .finally(() => {
        setLoadingSegments(false);
      });
  };

  useEffect(() => {
    if (!contextMode || !isBatchMode || !onReviewChange) return;
    // Mark blocks in website in batch mode
    // Only blocks that contain batch segments should be marked
    const batchBlocks = blockSegmentMap.current.getSegmentsBlocks(
      Object.keys(segmentsObj)
    );
    const blocks = blockSegmentMap.current.getSegmentsBlocks(
      Array.from(reviewedSegments)
    );
    const reviewedBlocks = blocks.filter((b) =>
      blockSegmentMap.current
        .getBlockSegments(b)
        .every((s) => reviewedSegments.has(s))
    );
    // if (contextMode && onReviewChange) {
    onReviewChange({ reviewedBlocks, blockHashes: batchBlocks });
    // }
    // Update website each time the reviewed segments change
  }, [hasSegmentsAndMap, reviewedSegments.size]);

  useEffect(() => {
    handleContextData(contextData);
  }, [contextData]);

  useEffect(() => {
    setExternalFilter(externalFilter);
  }, [externalFilter, setExternalFilter]);

  // fetch segments in batch mode or when out of context mode
  useEffect(() => {
    if (!contextMode || isBatchMode) {
      setLoadingSegments(true);
      isBatchMode ? getBatchSegments() : fetchSegmentsPage('first');
    }
  }, []);
  // ---- End of useEffects ----

  function previewAllSegments(segments: Segment[]) {
    if (!onTranslationChange) return undefined;
    // Get all segments with modified target
    const changedSegments = unsavedSegments.current.segments.filter(
      (s) => s.target
    );
    // Add segments if it's not in the list
    if (segments?.length) {
      segments.forEach((segment) => {
        if (
          !changedSegments.find((s) => s.segment_hash === segment.segment_hash)
        ) {
          changedSegments.push({
            segment_hash: segment.segment_hash,
            target: segment.target_jliff,
            setToLive: false,
          });
        }
      });
    }

    if (!changedSegments.length) {
      return;
    }

    const segmentsToPreview: PreviewSegment[] = changedSegments.map((s) => ({
      segment_hash: s.segment_hash,
      target: s.target,
      is_staging: segmentsObj[s.segment_hash]?.is_staging,
    }));
    const blocks = blockSegmentMap.current.getSegmentsBlocks(
      segments.map((s) => s.segment_hash)
    );
    onTranslationChange({ segments: segmentsToPreview, blocks });
  }

  // Manage unsaved segments list
  function updateUnsavedList(
    sHash: string,
    action: {
      type: 'SET_TO_LIVE' | 'SET_JLIFF' | 'REMOVE_JLIFF' | 'REMOVE_FROM_LIST';
      payload?: { target?: Jliff[] | null; setToLive?: boolean };
    }
  ) {
    if (!sHash) {
      console.warn('updateUnsavedList: segment hash is missing');
      return;
    }
    const { type, payload } = action;
    const segment = segmentsObj[sHash];
    if (!segment) {
      console.warn('updateUnsavedList: segment is null');
      return;
    }
    const list = unsavedSegments.current;

    switch (type) {
      case 'SET_TO_LIVE':
        if (!payload || payload.setToLive === undefined) {
          console.warn(
            'updateUnsavedList.SET_TO_LIVE: payload is not a boolean'
          );
          return;
        }
        list.setToLive(sHash, payload.setToLive);
        if (payload.setToLive) addSetToLive(sHash);
        else removeSetToLive(sHash);
        break;
      case 'SET_JLIFF':
        if (!payload || !payload.target) {
          console.warn('updateUnsavedList.SET_JLIFF: payload.target is null');
          return;
        }
        list.setTarget(sHash, payload.target);
        addModified(sHash); // Add to modified list
        break;
      case 'REMOVE_JLIFF':
        if (!list.has(sHash)) return; // If not in list, do nothing
        if (list.get(sHash)?.setToLive) {
          // If setToLive = true, set target to null
          list.setTarget(sHash, null);
        } else {
          // If setToLive = false, remove from list
          list.remove(sHash);
        }
        removeModified(sHash);
        break;
      case 'REMOVE_FROM_LIST':
        if (!list.has(sHash)) return; // If not in list, do nothing
        list.remove(sHash);
        break;
      default:
        console.warn('updateUnsavedList: unknown action:', action);
        return;
    }
  }

  useImperativeHandle(ref, () => ({
    checkForUnsavedChanges: async () => {
      if (unsavedSegments.current.length > 0) {
        setShowUnsavedModal(true);

        // Wait for user to resolve modal (save/discard)
        await new Promise((resolve) => {
          setModalCloseResolver(() => resolve);
        });
      }
      return unsavedSegments.current.length === 0;
    },
  }));

  function resetUnsavedList() {
    unsavedSegments.current.reset();
    resetUnsaved();
  }

  const closeModal = () => {
    setShowUnsavedModal(false);
    if (modalCloseResolver) {
      modalCloseResolver();
      setModalCloseResolver(null);
    }
  };

  const handleSave = async () => {
    // Save your changes here...
    await saveAllSegments();
    closeModal();
  };

  const handleDiscard = () => {
    resetUnsavedList();
    closeModal();
  };

  async function deleteSegment(segmentHash: string) {
    const res = await deleteSegmentTranslation(segmentsObj[segmentHash]);
    if (res.status !== 201) {
      notifications.show({
        message: `${res.data}`,
        color: notificationColors('error'),
      });
      return;
    }
    const deletedSegments = res.data as Segment[];
    const segments = generateSegmentsObject(res.data);
    notifications.show({
      message: 'Segment deleted successfully.',
      color: notificationColors('success'),
    });
    // Remove from unsaved list
    deletedSegments.forEach((s) => {
      updateUnsavedList(s.segment_hash, { type: 'REMOVE_FROM_LIST' });
    });
    // Update segments object
    if (contextMode) {
      updateSegmentsObject(segmentsObj, {
        type: 'UPDATE',
        payload: { segments },
      });
      previewAllSegments(deletedSegments);
    } else {
      updateSegmentsObject(segmentsObj, {
        type: 'DELETE',
        payload: { segments },
      });
    }
  }

  async function saveAllSegments(): Promise<void> {
    if (viewOnly) {
      console.error('View Only mode: Save not allowed.');
      return;
    }

    const translationsToSave = unsavedSegments.current.segments;
    const reviewsToSave = Object.keys(jliffStores.current).reduce<
      BatchSegmentData[]
    >((acc, sHash) => {
      const store = jliffStores.current[sHash];
      const state = store?.getState();
      // TODO: Build translationsToSave array here instead of using unsavedSegments
      if (!state?.segmentReview) {
        return acc;
      } else {
        return [
          ...acc,
          {
            segment_hash: sHash,
            ...state.segmentReview,
          } satisfies BatchSegmentData,
        ];
      }
    }, []);

    if (!translationsToSave.length && !reviewsToSave.length) {
      return;
    }

    setSaving(true);

    if (translationsToSave.length) {
      const { data, status } = await saveSegmentsTranslation(
        translationsToSave,
        segmentsObj
      );

      if (status === 200) {
        const saveResponse = data as SegmentContentResponse;
        let saveMessage = '';

        if (saveResponse.segments.length) {
          const newSegments: SegmentsObj = saveResponse.segments.reduce(
            (acc, s) => ({ ...acc, [s.segment_hash]: s }),
            {}
          );
          updateSegmentsObject(segmentsObj, {
            type: 'UPDATE',
            payload: { segments: newSegments },
          });
          const hashes = saveResponse.segments.map((s) => {
            updateUnsavedList(s.segment_hash, { type: 'REMOVE_FROM_LIST' });
            return s.segment_hash;
          });
          setSaved(hashes);
        }

        if (saveResponse.failed?.length) {
          notifications.show({
            message: `Error: ${saveResponse.failed.length} segments not saved!`,
            color: notificationColors('error'),
          });
          setFailedSave(saveResponse.failed);
        } else {
          const savedSegmentsCount = saveResponse.segments.length;
          saveMessage = `${savedSegmentsCount} segment${
            savedSegmentsCount === 1 ? '' : 's'
          } saved`;
        }

        if (saveMessage) {
          notifications.show({
            message: saveMessage,
            color: notificationColors('success'),
          });
        }
      } else {
        console.error(`Error (${status}) saving segments:`, data);
      }
    }
    if (reviewsToSave.length) {
      if (!batch?.translationKey || !batch?.batchKey) {
        console.error('SE::saveAllSegments: batch key is missing');
        return;
      }
      const { data, status } = await Xapis.BatchSegment.put(
        batch.translationKey,
        batch.batchKey,
        userKey,
        reviewsToSave
      );

      const numSavedReviews = data?.segment_rows_affected || 0;
      if (
        !data ||
        !isSuccessStatus(status) ||
        numSavedReviews !== reviewsToSave.length
      ) {
        notifications.show({
          message: `Error saving reviews: ${numSavedReviews}/${reviewsToSave.length} saved`,
          color: notificationColors('error'),
        });
      } else {
        notifications.show({
          message: `${numSavedReviews}/${reviewsToSave.length} review${reviewsToSave.length === 1 ? '' : 's'} saved`,
          color: notificationColors('success'),
        });
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve(true);
          }, 0);
        });
        getBatchSegments();
      }
    }
    setSaving(false);
  }

  const segmentsHashes =
    externalFilter.length > 0 ? externalFilter : allSegmentsHashes;

  const filteredSegments = contextMode
    ? filterSegments(
        segmentsObj,
        segmentsHashes,
        filterBy,
        searchBy,
        segmentsAttributes
      )
    : segmentsHashes;

  const undefinedStores = filteredSegments.filter(
    (sHash) => !jliffStores.current[sHash]
  );
  if (undefinedStores.length)
    console.warn(
      'SE::undefinedStores:',
      undefinedStores.length,
      undefinedStores
    );

  const sortedSegments = sortSegments(
    segmentsObj,
    filteredSegments,
    segmentsAttributes,
    sortBy
  ).filter((sHash) => jliffStores.current[sHash]); // Filter out segments without a store

  const approveAllBatchSegments = useCallback(() => {
    filteredSegments.forEach((sHash) => {
      const segment = segmentsObj[sHash];
      const store = jliffStores.current[sHash];
      if (!store || !segment) return;
      store.getState().actions.setSegmentReview({
        is_reviewed: (segment as BatchSegment).is_reviewed ? null : true,
      });
      updateReviewedSegments(sHash, true);
    });
  }, [segmentsObj, filteredSegments]);

  function segmentForHash(sHash: string) {
    const segment = segmentsObj[sHash];
    if (unsavedSegments.current.isModified(sHash)) {
      segment.target_jliff = unsavedSegments.current.getTarget(sHash);
    }

    if (!segment) {
      console.warn('Segment is undefined:', sHash);
    }
    return segment;
  }

  const segmentForIndex = (index: number) => {
    const sHash = sortedSegments[index];
    return segmentForHash(sHash);
  };

  const SegmentRow = ({ index }: { index: number }) => {
    const segment = segmentForIndex(index);

    return segment && jliffStores.current[segment.segment_hash] ? (
      <Segment
        key={segment.segment_hash}
        jliffStore={jliffStores.current[segment.segment_hash]}
        segment={segment}
        onSelect={onSelectSegment}
        filterText={searchBy.text}
        previewAllSegments={previewAllSegments}
        deleteSegment={deleteSegment}
        updateUnsavedList={updateUnsavedList}
        index={index}
      />
    ) : null;
  };

  if (loadingSegments) {
    return <Wait loader="dots">Loading segments ...</Wait>;
  }

  return (
    <MantineProvider theme={segmentEditorTheme}>
      <Notifications position={'top-right'} />
      <Box className={classes.segmentEditor}>
        {saving && <Wait loader="dots">Saving segments ...</Wait>}
        {isBatchMode && (
          <BatchTopBar
            progress={batchReviewProgress}
            onApproveAll={approveAllBatchSegments}
          />
        )}
        <EditorDashboard
          onSaveSegments={saveAllSegments}
          SearchBarAndFilterMenu={
            <SearchSegments
              filterCount={(filters: typeof filterBy) =>
                filterSegments(
                  segmentsObj,
                  segmentsHashes,
                  filters,
                  searchBy,
                  segmentsAttributes
                ).length
              }
              segmentsCount={{
                total: contextMode
                  ? allSegmentsHashes.length
                  : numSegmentTranslations,
                filtered: filteredSegments.length,
              }}
              refetchSegments={(query: URLSearchParams) =>
                fetchSegmentsPage('first', query)
              }
              onChange={handleSearchChange}
              searchBy={searchBy}
            />
          }
          SortByButtonsAndMenu={
            <SortSegments setSortBy={setSortBy} sortBy={sortBy} />
          }
        />
        <UnsavedChangesModal
          open={showUnsavedModal}
          onCancel={closeModal}
          onSave={handleSave}
          onDiscard={handleDiscard}
        />
        {isInScope ? (
          <Virtuoso
            className={classes.list}
            tabIndex={undefined}
            ref={virtuoso}
            computeItemKey={(index: number) =>
              segmentForIndex(index)?.segment_hash
            }
            totalCount={sortedSegments.length}
            itemContent={(index: number) => <SegmentRow index={index} />}
            endReached={
              contextMode ? undefined : () => fetchSegmentsPage('next')
            }
            increaseViewportBy={400}
          />
        ) : (
          <Flex className={classes.outOfScope}>
            <AlertIcon />
            <Text fw={600}>This page is out of scope.</Text>
          </Flex>
        )}
      </Box>
    </MantineProvider>
  );
}

export const SegmentEditor = forwardRef(SegmentEditorInternal);
