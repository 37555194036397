import { useState, useEffect } from 'react';
import { Box, Popover, Flex, Title } from '@mantine/core';
import { GlossaryTable } from './GlossaryTable';
import { GlossaryInfo } from './GlossaryInfo';
import { InfoIcon } from '../../icons/IndicatorIcons';
import { OpenLink, CloseLink, CloseIcon } from '../../icons/ActionIcons';
import classes from './GlossaryPopover.module.css';
import { getColor } from '../../theme/utils';

type Props = {
  glossary: GlossaryItem[];
  isSelected: boolean;
};

export const GlossaryPopover = ({ glossary, isSelected }: Props) => {
  const [showGlossary, setShowGlossary] = useState(false);
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    if (!isSelected) {
      setShowGlossary(false);
      setShowTable(false);
    }
  }, [isSelected]);

  return glossary.length ? (
    <Popover
      withinPortal
      withArrow
      closeOnClickOutside
      opened={showGlossary}
      position="bottom-start"
      shadow="md"
      styles={{
        arrow: {
          zIndex: -50,
          border: 'none',
        },
      }}
    >
      <Popover.Target>
        <Box
          onClick={() => {
            setShowGlossary(true);
          }}
          onMouseEnter={() => {
            if (!isSelected) setShowGlossary(true);
          }}
          onMouseLeave={() => {
            if (!isSelected) setShowGlossary(false);
          }}
          mt={18}
          style={{
            cursor: 'pointer',
          }}
        >
          <InfoIcon color={getColor('blue', 7)} />
        </Box>
      </Popover.Target>
      <Popover.Dropdown>
        <Flex className={classes.popover}>
          <Flex className={classes.header}>
            <Title order={6}>Glossary Terms</Title>
            {isSelected && (
              <Flex justify={'space-between'} style={{ flexGrow: 1 }}>
                <Box
                  c="blue.7"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowTable((p) => !p)}
                >
                  {showTable ? <CloseLink /> : <OpenLink />}
                </Box>
                <Box
                  onClick={() => {
                    setShowGlossary(false);
                    setShowTable(false);
                  }}
                  c="gray.6"
                  mt={-5}
                  mr={-10}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <CloseIcon size={15} />
                </Box>
              </Flex>
            )}
          </Flex>
          {!showTable ? (
            <GlossaryInfo glossary={glossary} />
          ) : (
            <GlossaryTable />
          )}
        </Flex>
      </Popover.Dropdown>
    </Popover>
  ) : (
    <Box w={20}></Box>
  );
};
