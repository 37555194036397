import { AddSnippetBody, AddSnippetSideMenu } from './AddSnippetStep';
import { CopySnippetBody, CopySnippetSideMenu } from './CopySnippetStep';
import { CreateProjectBody, CreateProjectSideMenu } from './CreateProjectStep';
import {
  CustomizeAppearanceBody,
  CustomizeAppearanceSideMenu,
} from './CustomizeAppearanceStep';
import { GetStartedBody, GetStartedSideMenu } from './GetStartedStep';
import { GoGlobalBody, GoGlobalSideMenu } from './GoGlobalStep';
import { MobileStepsSwitch } from './MobileStepsSwitch';
import { PreviewBody, PreviewSideMenu } from './PreviewStep';
import { useSetupStepsContext } from './ProjectSetupProvider';
import {
  SelectLanguagesBody,
  SelectLanguagesSideMenu,
} from './SelectLanguagesStep';
import { useViewSize } from 'ui';

const StepsSwitch = ({
  contentType,
}: {
  contentType: 'body' | 'side-menu' | 'mobile';
}) => {
  const { isDesktopSize } = useViewSize();
  const { activeStep } = useSetupStepsContext();

  if (!isDesktopSize && contentType === 'mobile') {
    return <MobileStepsSwitch />;
  }

  if (!isDesktopSize && contentType !== 'mobile') {
    return <></>;
  }

  return [
    {
      ['side-menu']: <GetStartedSideMenu />,
      ['body']: <GetStartedBody />,
    },
    {
      ['side-menu']: <CreateProjectSideMenu />,
      ['body']: <CreateProjectBody />,
    },
    {
      ['side-menu']: <SelectLanguagesSideMenu />,
      ['body']: <SelectLanguagesBody />,
    },
    {
      ['side-menu']: <CustomizeAppearanceSideMenu />,
      ['body']: <CustomizeAppearanceBody />,
    },
    {
      ['side-menu']: <AddSnippetSideMenu />,
      ['body']: <AddSnippetBody />,
    },
    {
      ['side-menu']: <CopySnippetSideMenu />,
      ['body']: <CopySnippetBody />,
    },
    {
      ['side-menu']: <PreviewSideMenu />,
      ['body']: <PreviewBody />,
    },
    {
      ['side-menu']: <GoGlobalSideMenu />,
      ['body']: <GoGlobalBody />,
    },
  ][activeStep][contentType as 'side-menu' | 'body'];
};

export default StepsSwitch;
