import React from 'react';
import { useSetupStepsContext } from './ProjectSetupProvider';
import { MobileGetStartedStep } from './GetStartedStep';
import { MobileCreateProjectStep } from './CreateProjectStep';
import { MobileSelectLanguagesStep } from './SelectLanguagesStep';
import { MobileCustomizeAppearanceStep } from './CustomizeAppearanceStep';
import { MobileAddSnippetStep } from './AddSnippetStep';
import { MobileCopySnippetStep } from './CopySnippetStep';
import { MobilePreviewStep } from './PreviewStep';
import { MobileGoGlobalStep } from './GoGlobalStep';

export const MobileStepsSwitch = () => {
  const { activeStep } = useSetupStepsContext();

  return [
    <MobileGetStartedStep />,
    <MobileCreateProjectStep />,
    <MobileSelectLanguagesStep />,
    <MobileCustomizeAppearanceStep />,
    <MobileAddSnippetStep />,
    <MobileCopySnippetStep />,
    <MobilePreviewStep />,
    <MobileGoGlobalStep />,
    <MobileGetStartedStep />,
  ][activeStep];
};
