import { Flex, Stack } from '@mantine/core';
import { Header, useViewSize } from 'ui';
import { BasicDetailsCard } from '../../components/batches/batch-details/BasicDetailsCard';
import { BatchDetailsFilters } from '../../components/batches/batch-details/BatchDetailsFilters';
import { UrlsTable } from '../../components/batches/batch-details/UrlsTable';
import QuerySearchBar from '../../components/batches/QuerySearchBar';

const BatchDetailsPage = () => {
  const { isMobileSize } = useViewSize();

  return (
    <>
      {isMobileSize ? (
        <>
          <Header title="Batch Details" />
          <Stack gap={20}>
            <Flex gap={20}>
              <BasicDetailsCard />
            </Flex>
            <BatchDetailsFilters />
            <QuerySearchBar />
            <UrlsTable />
          </Stack>
        </>
      ) : (
        <Flex gap={20}>
          <Stack miw={175}>
            <Header title="Batch Details" />
            <BasicDetailsCard />
          </Stack>
          <Stack w="100%">
            <BatchDetailsFilters />
            <QuerySearchBar />
            <UrlsTable />
          </Stack>
        </Flex>
      )}
    </>
  );
};
export default BatchDetailsPage;
