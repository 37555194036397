/* eslint-disable react-refresh/only-export-components */
import { createContext, useContext, useEffect, useState } from 'react';
import {
  BOTTOM_LEFT_POSITION,
  getCSSPreferences,
  LanguageSelectorPosition,
  LanguageSelectorTheme,
} from './LanguageSelectorAppearances';
import { decodeProjectId, isValidKey, isValidPID, NOOP } from 'helpers';
import { ProjectSetupContext, ProjectSetupStatus, Url, UrlObj } from './types';
import { DARK_MODE_THEME } from '../settings/selector-customization/CustomThemeButton';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { defaultProject, projectQuery } from '@glweb/xapis-client';
import { languageCodesQuery } from '../../queries/languageCodesQuery';

const defaultContext: ProjectSetupContext = {
  project: defaultProject,
  language_codes: [],
  activeStep: 0,
  setActiveStep: NOOP,
  url: { protocol: 'https', domain: '' },
  setUrl: NOOP,
  fullUrl: 'https://',
  themePosition: { theme: DARK_MODE_THEME, position: BOTTOM_LEFT_POSITION },
  setThemePosition: NOOP,
  sourceTargetLangCodes: { source: 'EN-US', targets: [] },
  setSourceTargetLangCodes: NOOP,
  status: {
    isSnippetCopied: false,
    isCreatingProject: false,
  },
  updateStatus: NOOP,
};

const SetupStepsContext = createContext<ProjectSetupContext>(defaultContext);

export const ProjectSetupProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('id') ?? '';
  const projectKey = decodeProjectId(projectId);

  const shouldFetchProject = isValidPID(projectId) && isValidKey(projectKey);

  const {
    data: {
      project = defaultProject,
      project: { translations = [] } = defaultProject,
    } = {},
  } = (useQuery(
    projectQuery(projectKey, false, { enabled: shouldFetchProject })
  ) || {}) as { data: { project: ProjectKey } };

  const { data: { language_codes = [] } = {} } = useQuery(
    languageCodesQuery('google', { enabled: !projectKey })
  );

  const {
    theme: currentTheme = DARK_MODE_THEME,
    position: currentPosition = BOTTOM_LEFT_POSITION,
  } = getCSSPreferences(translations);

  const [activeStep, setActiveStep] = useState(0);
  const [url, setUrl] = useState<UrlObj>({ protocol: 'https', domain: '' });
  const [themePosition, setThemePosition] = useState<{
    theme: LanguageSelectorTheme;
    position: LanguageSelectorPosition;
  }>({
    theme: currentTheme,
    position: currentPosition,
  });
  const [sourceTargetLangCodes, setSourceTargetLangCodes] = useState<{
    source: string;
    targets: string[];
  }>({
    source: 'EN-US',
    targets: [],
  });
  const [status, setStatus] = useState({
    isSnippetCopied: false,
    isCreatingProject: false,
  });

  const fullUrl: Url = `${url.protocol}://${url.domain}`;

  const updateStatus = (value: boolean, key: keyof ProjectSetupStatus) => {
    setStatus((prevStatus) => ({ ...prevStatus, [key]: value }));
  };

  // invalidate projectQuery so subscriptions will be fetched on Dashboard
  useEffect(() => {
    return () => {
      if (projectKey) {
        queryClient.invalidateQueries({ queryKey: ['project', projectKey] });
      }
    };
  }, [projectKey, queryClient]);

  return (
    <SetupStepsContext.Provider
      value={{
        project,
        language_codes,
        activeStep,
        setActiveStep,
        url,
        setUrl,
        fullUrl,
        themePosition,
        setThemePosition,
        sourceTargetLangCodes,
        setSourceTargetLangCodes,
        status,
        updateStatus,
      }}
    >
      {children}
    </SetupStepsContext.Provider>
  );
};

export const useSetupStepsContext = () => {
  const context = useContext(SetupStepsContext);
  if (!context) throw new Error('SetupStepsContext not found');
  return context;
};
