import { dashboardRC } from '@glweb/constants';
import { RouteObject } from 'react-router-dom';
import { CustomCssLanding } from 'ui';
import addLanguagesAction from '../../components/settings/manage-languages/addLanguagesAction';
import ManageLanguages from '../../components/settings/manage-languages/ManageLanguages';
import CurrentScopeHome from '../../components/settings/project-scope/landing-page/CurrentScopeHome';
import ScopeUrlsTree from '../../components/settings/project-scope/ScopeUrlsTree';
import SelectorCard from '../../components/settings/selector-customization/SelectorCard';
import {
  USER_ROLES_INSTALLATION_PAGE,
  USER_ROLES_LANGUAGE_SELECTOR_PAGE,
  USER_ROLES_LANGUAGE_SETTINGS_PAGE,
  USER_ROLES_PROJECT_SCOPE_PAGE,
} from '../../constants';
import languageCodesLoader from '../../loaders/languageCodesLoader';
import spiderTreeLoader from '../../loaders/spiderTreeLoader';
import RestrictedRoute from '../../RestrictedRoute';
import { DeploymentProvider } from '../../components/settings/installation/DeploymentContextProvider';
import Installation from '../../components/settings/installation/Installation';
import { nonGLNOWProjectTypesSet } from 'helpers';

const settingsRoutes: Array<RouteObject & { path: string }> = [
  {
    path: dashboardRC.PAGE_LANGUAGE_SETTINGS,
    children: [
      {
        index: true,
        loader: () => languageCodesLoader('ty'),
        action: addLanguagesAction,
        element: (
          <RestrictedRoute
            allowedRoles={USER_ROLES_LANGUAGE_SETTINGS_PAGE}
            allowedProjectTypes={nonGLNOWProjectTypesSet}
          >
            <ManageLanguages />
          </RestrictedRoute>
        ),
      },
      {
        path: dashboardRC.PAGE_CUSTOM_CSS_EDITOR,
        element: (
          <RestrictedRoute
            allowedRoles={USER_ROLES_LANGUAGE_SETTINGS_PAGE}
            allowedProjectTypes={nonGLNOWProjectTypesSet}
          >
            <CustomCssLanding />
          </RestrictedRoute>
        ),
      },
    ],
  },
  {
    path: dashboardRC.PAGE_LANGUAGE_SELECTOR,
    element: (
      <RestrictedRoute
        allowedRoles={USER_ROLES_LANGUAGE_SELECTOR_PAGE}
        allowedProjectTypes={nonGLNOWProjectTypesSet}
      >
        <SelectorCard />
      </RestrictedRoute>
    ),
  },
  {
    path: dashboardRC.PAGE_INSTALLATION,
    element: (
      <RestrictedRoute
        allowedRoles={USER_ROLES_INSTALLATION_PAGE}
        allowedProjectTypes={nonGLNOWProjectTypesSet}
      >
        <DeploymentProvider>
          <Installation />
        </DeploymentProvider>
      </RestrictedRoute>
    ),
  },
  {
    path: dashboardRC.PAGE_PROJECT_SCOPE,
    children: [
      {
        index: true,
        element: (
          <RestrictedRoute
            allowedRoles={USER_ROLES_PROJECT_SCOPE_PAGE}
            allowedProjectTypes={nonGLNOWProjectTypesSet}
          >
            <CurrentScopeHome />
          </RestrictedRoute>
        ),
      },
      {
        path: ':yyTranslationId/urls',
        element: (
          <RestrictedRoute
            allowedRoles={USER_ROLES_PROJECT_SCOPE_PAGE}
            allowedProjectTypes={nonGLNOWProjectTypesSet}
          >
            <ScopeUrlsTree />
          </RestrictedRoute>
        ),
        loader: spiderTreeLoader,
      },
    ],
  },
];

export default settingsRoutes;
