import { CustomModal } from 'ui';
import { Xapis } from '@glweb/xapis-client';
import { notifications } from '@mantine/notifications';
import { isSuccessStatus } from 'helpers';

type Props = {
  urlHashes: string[];
  batchKey: string;
  translationKey: string;
  opened: boolean;
  onClose: () => void;
};
const ApproveURLsModal = ({
  urlHashes,
  batchKey,
  translationKey,
  onClose,
  opened,
}: Props) => {
  const multipleHashes = urlHashes.length > 1;

  const handleReviewBatches = () => {
    Xapis.BatchURLs.patch({
      batchKey,
      translationKey,
      hashes: urlHashes,
    })
      .then((result) => {
        if (!isSuccessStatus(result.status)) {
          notifications.show({
            message: `Error while marking ${multipleHashes ? 'URLs' : 'segments'} as reviewed`,
            color: 'red',
          });
        } else {
          notifications.show({
            message: `Successfully marked ${multipleHashes ? 'URLs' : 'segments'} as reviewed`,
          });
        }
      })
      .finally(onClose);
  };

  return (
    <CustomModal
      onClose={onClose}
      opened={opened}
      footerActions={[
        { label: 'Cancel', action: onClose },
        {
          label: 'Confirm',
          action: handleReviewBatches,
        },
      ]}
      title={`Mark all ${multipleHashes ? 'URLs' : 'segments'} in this ${multipleHashes ? 'batch' : 'URL'} as having been reviewed?`}
    />
  );
};
export default ApproveURLsModal;
