import { notifications, showNotification } from '@mantine/notifications';
import { isSuccessStatus, isValidDomain } from 'helpers';
import { ProjectResponse, Xapis } from '@glweb/xapis-client';
import React from 'react';

export type FormValues = {
  domain: string;
  additional_origins: string[];
};

export const getDefaultStagingDomain = (domain: string) => {
  const hasWWWDot = domain.includes('www.');
  return hasWWWDot
    ? `${domain.substring(0, 4)}staging.${domain.substring(4)}`
    : `staging.${domain}`;
};

export const updateDomain = async (
  newDomain: string,
  newStagingDomains: string[],
  projectKey: string
): Promise<ProjectResponse> => {
  return Xapis.Project.put(projectKey, {
    origin_name: newDomain,
    additional_origins: newStagingDomains,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const { response = {} } = error || {};
      showNotification({
        message: `Oops, something went wrong while updating the project domains`,
        color: 'red',
      });
      return response;
    });
};

export const onSave = (
  data: FormValues,
  stagingDomainsEdited: boolean,
  resetForm: (data: FormValues) => void,
  setShowConfirmModal: React.Dispatch<React.SetStateAction<boolean>>,
  setShowRemoveStagingModal: React.Dispatch<React.SetStateAction<boolean>>,
  stagingDomain: string,
  currentDomain: string,
  projectKey: string,
  projectType: string
) => {
  const { domain: newDomain = '', additional_origins: newStagingDomains = [] } =
    data || {};

  if (!isValidDomain(newDomain)) {
    showNotification({
      message: 'Invalid domain entered',
      color: 'red',
    });
    return;
  }

  if (projectType !== 'GLGO' && stagingDomainsEdited) {
    showNotification({
      message: 'Updating staging domain of a non-GLGO project is not supported',
      color: 'red',
    });
    return;
  }

  if (newStagingDomains.some((domain: string) => !isValidDomain(domain))) {
    showNotification({
      message: 'Invalid staging domain entered',
      color: 'red',
    });
    return;
  }

  if (newDomain !== currentDomain) {
    setShowConfirmModal(true);
    return;
  }

  if (newStagingDomains.length === 0 && stagingDomainsEdited) {
    setShowRemoveStagingModal(true);
    return;
  }

  updateDomain(newDomain, newStagingDomains, projectKey).then((response) => {
    const {
      project: {
        origin_name: originName = '',
        additional_origins: additionalOrigins = [] as string[],
      } = {},
    } = response || {};

    if (isSuccessStatus(response.status)) {
      notifications.show({
        message: `Successfully updated staging domain!`,
      });
      resetForm({ domain: originName, additional_origins: additionalOrigins });
    } else {
      notifications.show({
        message: `Failed to update staging domain!`,
        color: 'red',
      });
    }
  });
};
