import { useCurrentProjectType } from 'ui';
import GlNowDashboard from '../../components/dashboard/dashboards/GlNowDashboard';
import GlGoDashboard from '../../components/dashboard/dashboards/GlGoDashboard';
import BasicDashboard from '../../components/dashboard/dashboards/BasicDashboard';

const DashboardPage = () => {
  const { isGLNOWProject, isGLWEBProject, isGLGOProject } =
    useCurrentProjectType();

  switch (true) {
    case isGLNOWProject:
      return <GlNowDashboard />;
    case isGLGOProject:
      return <GlGoDashboard />;
    case isGLWEBProject:
    default:
      return <BasicDashboard />;
  }
};

export default DashboardPage;
