import { CustomModal, useCurrentProjectData } from 'ui';
import React, { useEffect, useState } from 'react';
import {
  Badge,
  ComboboxItem,
  Flex,
  Image,
  OptionsFilter,
  Select,
  SelectProps,
  Stack,
  Text,
  TextInput,
  UnstyledButton,
} from '@mantine/core';
import {
  BATCH_PHASE_REVIEW,
  BATCH_PHASE_TESTING,
  BATCH_PHASE_TRANSLATION,
  BATCH_PHASE_VALIDATION,
  BATCH_STATUS_INPROGRESS,
  BATCH_STATUS_READY,
  BATCH_STATUS_TODO,
  getPhaseIcon,
  getStatusColor,
  isValidBatchAssignee,
} from './utils';
import { CircleAvatar } from '../shared/CircleAvatar';
import { DatePickerInput } from '@mantine/dates';
import moment from 'moment';
import { MdArrowDropDown } from 'react-icons/md';
import EditBatchIcon from '../../assets/images/EditBatchIcon.svg';
import { ProjectUserPermission, useViewPermissions } from 'helpers';
import { FiX } from 'react-icons/fi';

const phaseOptions = [
  BATCH_PHASE_TRANSLATION,
  BATCH_PHASE_TESTING,
  BATCH_PHASE_REVIEW,
  BATCH_PHASE_VALIDATION,
];

const statusOptions = [
  BATCH_STATUS_TODO,
  BATCH_STATUS_INPROGRESS,
  BATCH_STATUS_READY,
];

type Props = {
  batch: ActiveBatch | BatchDetailsResponse;
  editBatch: (params: Record<string, string>) => void;
  opened: boolean;
  close: () => void;
};

const Phase = ({ phase }: { phase: string }) => (
  <>
    <Image w={16} h={16} mx={5} src={getPhaseIcon(phase)} alt="Phase" />
    <Text fw={700} tt="uppercase">
      {phase}
    </Text>
  </>
);

const PhaseOption: SelectProps['renderOption'] = ({
  option: { value: phase },
}) => <Phase phase={phase} />;

const StatusOption: SelectProps['renderOption'] = ({
  option: { value: status },
}) => (
  <Badge bg={getStatusColor(status)} radius={4} tt="uppercase" w={100}>
    {status}
  </Badge>
);

const AssigneeOption: SelectProps['renderOption'] = ({
  option: { value: ukey },
}) => {
  const { projectUserPermissionMap } = useCurrentProjectData();

  const assignee = projectUserPermissionMap[ukey] ?? ({} as User);
  const { first_name, last_name } = assignee;
  return (
    <Flex align={'center'} gap={10}>
      {first_name && (
        <CircleAvatar name={first_name} backgroundColor="#6ED6CC" />
      )}
      <Text c="inherit">
        {first_name} {last_name}
      </Text>
    </Flex>
  );
};

const EditBatchModal = ({ editBatch, opened, close, batch }: Props) => {
  const { projectUserPermissionMap } = useCurrentProjectData();
  const { hasInternalRole } = useViewPermissions();
  const canEditEverything = hasInternalRole;

  const [name, setName] = useState(batch.batch_name ?? '');
  const [phase, setPhase] = useState(batch.phase ?? '');
  const [status, setStatus] = useState(batch.phase_status ?? '');
  const [assignee, setAssignee] = useState(batch.assigned_user ?? '');
  const [dueDate, setDueDate] = useState(
    batch.due_date_utc ? new Date(batch.due_date_utc) : new Date()
  );
  const [assigneeSearch, setAssigneeSearch] = useState('');

  const resetValues = () => {
    setName(batch.batch_name ?? '');
    setPhase(batch.phase ?? '');
    setStatus(batch.phase_status ?? '');
    setAssignee(batch.assigned_user ?? '');
    setDueDate(batch.due_date_utc ? new Date(batch.due_date_utc) : new Date());
  };

  useEffect(() => {
    resetValues();
  }, [batch]);

  useEffect(() => {
    !assignee && setAssigneeSearch('');
  }, [batch, assignee]);

  const handleClose = () => {
    resetValues();
    close();
  };

  const handleEditBatch = () => {
    editBatch({
      batch_name: name,
      phase,
      phase_status: status,
      assigned_user: assignee,
      due_date_utc: moment(dueDate).format('YYYY-MM-DD'),
    });
    close();
  };

  const isUnchanged =
    name === batch.batch_name &&
    phase === batch.phase &&
    status === batch.phase_status &&
    assignee === batch.assigned_user &&
    moment(new Date(dueDate)).format('YYYY-MM-DD') ===
      batch.due_date_utc.split(' ')[0];

  const someFieldEmpty =
    name?.length === 0 ||
    phase?.length === 0 ||
    status?.length === 0 ||
    assignee?.length === 0 ||
    isNaN(dueDate?.valueOf());

  const assigneeOptions = Object.entries(projectUserPermissionMap)
    .filter(([, user]) =>
      isValidBatchAssignee(user, { ...batch, phase } as
        | ActiveBatch
        | BatchDetailsResponse)
    )
    .map(([key, ,]) => key);

  const assigneeOptionNames = assigneeOptions.reduce(
    (userkeyNameMap, ukey) => {
      const assignee = (projectUserPermissionMap[
        ukey as keyof typeof projectUserPermissionMap
      ] ?? {}) as User;
      const { first_name, last_name } = assignee;
      userkeyNameMap[ukey] = `${first_name} ${last_name}`;
      return userkeyNameMap;
    },
    {} as Record<string, string>
  );

  const assigneeOptionsFilter: OptionsFilter = ({ options }) => {
    const comboBoxOptions = options as ComboboxItem[];
    return comboBoxOptions.filter(
      ({ value: userKey }) =>
        assigneeOptionNames[userKey as keyof typeof projectUserPermissionMap]
          .toLowerCase()
          .includes(assigneeSearch.toLowerCase()) ||
        userKey.toLowerCase().includes(assigneeSearch.toLowerCase())
    );
  };

  return (
    <CustomModal
      opened={opened}
      onClose={handleClose}
      footerActions={[
        { action: handleClose, label: 'Close' },
        {
          action: handleEditBatch,
          label: 'Confirm',
          disabled: isUnchanged || someFieldEmpty,
        },
      ]}
      title={
        <Flex justify={'center'}>
          <Image
            w={24}
            h={24}
            mx={5}
            src={EditBatchIcon}
            alt="Edit batch Icon"
          />
          Edit Batch
        </Flex>
      }
    >
      <Stack gap={5}>
        <TextInput
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          label="NAME"
          required
          disabled={!canEditEverything}
        />
        <Select
          data={phaseOptions}
          onChange={(phase) => {
            setPhase(phase ?? '');
            //remove assignee if not valid for changed status
            const currentAssignee: ProjectUserPermission =
              projectUserPermissionMap[assignee];
            if (
              currentAssignee &&
              !isValidBatchAssignee(currentAssignee, { ...batch, phase } as
                | BatchDetailsResponse
                | ActiveBatch)
            ) {
              setAssignee('');
              setAssigneeSearch('');
            }
          }}
          value={phase}
          label="PHASE"
          allowDeselect={false}
          required
          renderOption={PhaseOption}
          comboboxProps={{ withinPortal: false }}
          leftSectionProps={{ style: { marginLeft: 10 } }}
          leftSectionWidth={'fit-content'}
          leftSection={phase ? <Phase phase={phase} /> : <></>}
          rightSection={<MdArrowDropDown size={24} />}
          styles={{ input: { color: 'transparent' } }}
          disabled={!canEditEverything}
        />
        <Select
          data={statusOptions}
          value={status}
          onChange={(status) => {
            setStatus(status ?? '');
          }}
          label="STATUS"
          allowDeselect={false}
          required
          renderOption={StatusOption}
          comboboxProps={{ withinPortal: false }}
          leftSection={
            status?.length ? (
              <Badge
                bg={getStatusColor(status)}
                radius={4}
                tt="uppercase"
                w={100}
                mx={5}
              >
                {status}
              </Badge>
            ) : (
              <></>
            )
          }
          leftSectionProps={{ style: { marginLeft: 10 } }}
          leftSectionWidth={'fit-content'}
          rightSection={<MdArrowDropDown size={24} />}
          styles={{ input: { color: 'transparent' } }}
        />
        <Select
          data={assigneeOptions.map((uKey) => ({
            value: uKey,
            label: assigneeOptionNames[uKey],
          }))}
          value={assignee}
          label="ASSIGNEE"
          allowDeselect={false}
          required
          searchable
          filter={assigneeOptionsFilter}
          renderOption={AssigneeOption}
          onChange={(assignee) => setAssignee(assignee ?? '')}
          comboboxProps={{ withinPortal: false, position: 'top' }}
          leftSection={
            assignee?.length ? (
              <CircleAvatar
                name={assigneeOptionNames[assignee]}
                backgroundColor="#6ED6CC"
                circleSize={24}
              />
            ) : (
              <></>
            )
          }
          leftSectionProps={{ style: { paddingLeft: 10, paddingRight: 10 } }}
          leftSectionWidth={assignee ? 44 : 10}
          rightSection={
            <Flex align="center">
              {assignee && (
                <UnstyledButton
                  onClick={() => {
                    setAssigneeSearch('');
                    setAssignee('');
                  }}
                >
                  <FiX size={16} />
                </UnstyledButton>
              )}
            </Flex>
          }
          rightSectionWidth={assignee ? 40 : 0}
          rightSectionProps={{
            style: {
              pointerEvents: 'auto',
            },
          }}
          maxDropdownHeight={200}
          onSearchChange={setAssigneeSearch}
          searchValue={assigneeSearch}
          styles={
            assigneeSearch.length === 0 && phase !== BATCH_PHASE_REVIEW
              ? { dropdown: { display: 'none' } }
              : undefined
          }
        />
        <DatePickerInput
          labelProps={{ c: 'font1.0' }}
          label="DUE DATE"
          required
          value={dueDate}
          onChange={(value) => setDueDate(value ?? new Date())}
          popoverProps={{ withinPortal: false }}
          rightSection={
            <MdArrowDropDown size={24} color="var(--mantine-color-font1-0)" />
          }
        />
      </Stack>
    </CustomModal>
  );
};

export default EditBatchModal;
