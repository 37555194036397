import { useRouteLoaderData } from 'react-router-dom';
import { xapisEnv } from '@glweb/xapis-client';
import { useDashboardQueries } from 'glweb/src/queries/dashboardQueries';
import { DashboardLoaderResponse } from 'glweb/src/loaders/dashboardLoader';
import { useCurrentProjectData, useGLGOParams } from 'ui';

const useDashboardData = () => {
  const { concatenatedKey, timeSliceFrom, shouldQueryQuickQuote } =
    useRouteLoaderData('dashboard') as DashboardLoaderResponse;
  const { projectKey = '' } = useGLGOParams();
  const { payKey } = xapisEnv.getHost;
  const { project } = useCurrentProjectData();
  return useDashboardQueries({
    projectKey: projectKey!,
    payKey,
    concatenatedKey,
    timeSliceFrom,
    shouldQueryQuickQuote,
    origin_name: project?.origin_name || '',
  });
};

export default useDashboardData;
