import { dashboardRC } from '@glweb/constants';
import { xapisEnv } from '@glweb/xapis-client';
import { Button } from '@mantine/core';
import { isDesktopApp, NOOP } from 'helpers';
import { LinkWrapper, useGLGOParams } from 'ui';
import { useRecommendedThresholdContext } from '../../providers/RecommendedThresholdProvider';
type Props = {
  text?: string;
};

const SubscribeButton = ({ text = 'Subscribe' }: Props) => {
  const projectId = useGLGOParams()?.projectId;
  const threshold = useRecommendedThresholdContext().threshold;
  const isDesktop = isDesktopApp();
  const { glgoDashboardUrl } = xapisEnv.getHost;

  const link = `${isDesktop ? glgoDashboardUrl : ''}/${projectId}/${dashboardRC.PAGE_ACCOUNT}/billing-information/manage-subscription${threshold > 0 ? `?threshold=${threshold}` : ''}`;

  const buttonAction = isDesktop
    ? () => window.dashboardAPI.openUrlExternal(link)
    : NOOP;

  return (
    <LinkWrapper isDesktop={isDesktop} to={link}>
      <Button variant="light" onClick={buttonAction}>
        {text}
      </Button>
    </LinkWrapper>
  );
};

export default SubscribeButton;
