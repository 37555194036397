import { dashboardRC } from '@glweb/constants';
import { useAuth, useRemoveAuthUser } from 'auth';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
export const PostLogout = () => {
  const removeAuthUser = useRemoveAuthUser();
  const auth = useAuth();

  const {
    isAuthenticated = false,
    isLoading = false,
    error,
    activeNavigator,
  } = auth;

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      removeAuthUser();
    }
  }, [isLoading, isAuthenticated, removeAuthUser]);

  if (isLoading) return <div>PostLogout::{`${activeNavigator}`}</div>;

  if (isAuthenticated) return <Navigate to={dashboardRC.PAGE_ROOT} replace />;

  if (error) {
    const { message } = error;
    console.error('auth.error: ', error);
    return <div>Post Logout: Oops... {message}</div>;
  }

  return <Navigate to={dashboardRC.PAGE_ROOT} replace />;
};
