import { CustomModal } from 'ui';
import React, { useEffect, useState } from 'react';
import {
  Textarea,
  Text,
  MantineColor,
  StyleProp,
  Title,
  Rating,
} from '@mantine/core';
import { TbStar, TbStarFilled } from 'react-icons/tb';

type Props = {
  editBatch: (params: Record<string, string | undefined>) => void;
  opened: boolean;
  close: () => void;
  existingComment: string;
};

export const GradeBatchModal = ({
  editBatch,
  opened,
  close,
  existingComment,
}: Props) => {
  const [comment, setComment] = useState(existingComment);
  const [score, setScore] = useState(0);

  const ratingParams = {
    batch_comment: comment,
    batch_score: score ? score.toString() : undefined,
  };

  const handleClose = () => {
    setComment(existingComment);
    setScore(0);
    close();
  };

  const handleCompleteBatch = () => {
    editBatch(ratingParams);
    handleClose();
  };

  useEffect(() => {
    setComment(existingComment);
  }, [existingComment]);

  const isCommentTooLong = comment.length > 250;

  return (
    <CustomModal
      opened={opened}
      onClose={handleClose}
      footerActions={[
        { action: handleClose, label: 'Close' },
        {
          action: handleCompleteBatch,
          label: 'Confirm',
          disabled: isCommentTooLong,
        },
      ]}
      title="Grade Batch"
    >
      <Title order={2} mb={10} fw={400}>
        Assign a Score:
      </Title>
      <Rating
        onChange={setScore}
        size="lg"
        mb={15}
        emptySymbol={<TbStar size={25} color={'#FEBC2E'} strokeWidth={1} />}
        fullSymbol={<TbStarFilled size={25} color={'#FEBC2E'} />}
        styles={{ symbolBody: { marginRight: 10 } }}
      />
      <Title order={2} mt={10} mb={5} fw={400}>
        Additional Comments:
      </Title>
      <Textarea
        placeholder="Additional comments"
        onChange={(e) => setComment(e.target.value)}
        value={comment}
        description={
          <Text fz="inherit" c="font1.0" span>
            <Text
              fz="inherit"
              span
              c={
                isCommentTooLong
                  ? 'red'
                  : ('font1.0' as StyleProp<MantineColor>)
              }
            >
              {comment.length}{' '}
            </Text>
            of 250 max characters
          </Text>
        }
        descriptionProps={{ ta: 'right' }}
        error={isCommentTooLong ? 'Comment is too long' : undefined}
        inputWrapperOrder={['input', 'description']}
        autosize
        minRows={3}
        w="100%"
        p={6}
      />
    </CustomModal>
  );
};
