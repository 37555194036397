import { Button, Checkbox, Flex, Stack, TextInput, Title } from '@mantine/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCurrentProjectData } from 'ui';
import ConfirmEditDomainModal from './ConfirmEditDomainModal';
import { FormValues, getDefaultStagingDomain, onSave } from './utils';
import ConfirmRemoveStagingDomainModal from './ConfirmRemoveStagingDomainModal';

const EditDomainForm = () => {
  const {
    project: {
      project_key = '',
      project_type = '',
      origin_name = '',
      additional_origins = [],
    } = {},
  } = useCurrentProjectData();

  const [currentDomain, setCurrentDomain] = useState(origin_name);
  const [stagingDomain, setStagingDomain] = useState<string>(
    additional_origins[0] ?? getDefaultStagingDomain(currentDomain)
  );

  const [showStagingDomain, setShowStagingDomain] = useState(
    additional_origins.length > 0
  );
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showRemoveStagingModal, setShowRemoveStagingModal] = useState(false);

  const { register, setValue, reset, getFieldState, handleSubmit, watch } =
    useForm<FormValues>({
      defaultValues: {
        domain: currentDomain,
        additional_origins: additional_origins,
      },
    });

  const resetForm = (data?: FormValues) => {
    const {
      domain: newDomain = currentDomain,
      additional_origins: newOrigins = [],
    } = data ?? {};

    if (data) {
      setCurrentDomain(newDomain);
      setShowStagingDomain(newOrigins.length > 0);
      setStagingDomain(newOrigins[0] ?? getDefaultStagingDomain(origin_name));
    } else {
      setCurrentDomain(origin_name);
      setShowStagingDomain(additional_origins.length > 0);
      setStagingDomain(
        additional_origins[0] ?? getDefaultStagingDomain(origin_name)
      );
    }
    reset({
      domain: origin_name,
      additional_origins: additional_origins,
      ...(data || {}),
    });
  };

  const stagingDomainEdited = showStagingDomain
    ? additional_origins[0] !== stagingDomain
    : additional_origins.length > 0;

  return (
    <form
      onSubmit={handleSubmit((data) =>
        onSave(
          data,
          stagingDomainEdited,
          resetForm,
          setShowConfirmModal,
          setShowRemoveStagingModal,
          stagingDomain,
          currentDomain,
          project_key,
          project_type
        )
      )}
    >
      <ConfirmEditDomainModal
        showModal={showConfirmModal}
        setShowModal={setShowConfirmModal}
        currentDomain={currentDomain}
        newDomain={watch('domain')}
        newStagingDomains={watch('additional_origins')}
        resetForm={resetForm}
      />
      <ConfirmRemoveStagingDomainModal
        showModal={showRemoveStagingModal}
        setShowModal={setShowRemoveStagingModal}
        newDomain={watch('domain')}
        resetForm={resetForm}
      />
      <Stack gap={10}>
        <Title>Production Domain</Title>
        <TextInput {...register('domain')} name="domain" />
        <Checkbox
          label="Enable Staging Environment"
          onChange={(e) => {
            const checked = e.target.checked;
            setShowStagingDomain(checked);
            if (checked) {
              setValue('additional_origins', [stagingDomain]);
            } else {
              setValue('additional_origins', []);
            }
          }}
          checked={showStagingDomain}
        />
      </Stack>
      {showStagingDomain && (
        <>
          <Title mb={10} mt={20}>
            Staging Domain
          </Title>
          <Flex w="100%">
            <TextInput
              value={stagingDomain}
              onChange={(e) => {
                setStagingDomain(e.target.value);
                setValue('additional_origins', [e.target.value]);
              }}
              w="100%"
              name="staging"
            />
          </Flex>
        </>
      )}

      <Flex gap={10} justify={'flex-end'} mt={20}>
        <Button
          variant="outline"
          onClick={() => {
            resetForm();
          }}
          disabled={!getFieldState('domain').isDirty && !stagingDomainEdited}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={!getFieldState('domain').isDirty && !stagingDomainEdited}
        >
          Submit
        </Button>
      </Flex>
    </form>
  );
};

export default EditDomainForm;
