export const TRANSLATIONS = 'translations';
export const BATCH = 'batch';
export const NO_TRANSLATE_RULES = 'no-translate';
export const CSS_EDITOR = 'css-editor';
const QUOTE = 'quote';

export const studioModes = [
  TRANSLATIONS,
  BATCH,
  NO_TRANSLATE_RULES,
  CSS_EDITOR,
  // QUOTE,
] as const;
export type StudioMode = (typeof studioModes)[number];

export type StudioRouteParams = { projectId: string; studioMode: StudioMode };

export const desktopRC = {
  TRANSLATIONS,
  BATCH,
  NO_TRANSLATE_RULES,
  CSS_EDITOR,
  QUOTE,
} as const;

export const perLanguageModes = [TRANSLATIONS, CSS_EDITOR, BATCH] as const;
export const sharedConfigModes = [NO_TRANSLATE_RULES] as const;

export const editorDockModes = ['left', 'bottom', 'right'] as const;
export type EditorDockMode = (typeof editorDockModes)[number];
