export const BatchEditorIcon = ({
  name = '',
  color = '#0071DB',
}: {
  name?: string;
  color?: string;
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`icon icon-${name}`}
  >
    <path
      d="M4.0455 7.04167C4.80232 6.79375 5.63414 6.6875 6.43186 6.6875C7.76141 6.6875 9.19323 6.97083 10.1819 7.75C11.1705 6.97083 12.6023 6.6875 13.9319 6.6875C15.2614 6.6875 16.6932 6.97083 17.6819 7.75V18.1271C17.6819 18.3042 17.5114 18.4813 17.341 18.4813C17.2728 18.4813 17.2387 18.4458 17.1705 18.4458C16.2501 17.9854 14.9205 17.6667 13.9319 17.6667C12.6023 17.6667 11.1705 17.95 10.1819 18.7292C9.26141 18.1271 7.59096 17.6667 6.43186 17.6667C5.30686 17.6667 4.14777 17.8792 3.19322 18.4104C3.12504 18.4458 3.09095 18.4458 3.02277 18.4458C2.85231 18.4458 2.68186 18.2688 2.68186 18.0917V7.75C3.09095 7.43125 3.53413 7.21875 4.0455 7.04167ZM4.0455 16.6042C4.7955 16.3563 5.61368 16.25 6.43186 16.25C7.59096 16.25 9.26141 16.7104 10.1819 17.3125V9.16667C9.26141 8.56458 7.59096 8.10417 6.43186 8.10417C5.61368 8.10417 4.7955 8.21042 4.0455 8.45833V16.6042Z"
      fill={color}
    />
    <path
      d="M6.43181 10.9375C5.83181 10.9375 5.25226 11.0013 4.72726 11.1217V10.045C5.2659 9.93875 5.84545 9.875 6.43181 9.875C7.59091 9.875 8.64091 10.0804 9.5 10.4629V11.6387C8.72954 11.1854 7.65909 10.9375 6.43181 10.9375ZM9.5 12.3471V13.5229C8.72954 13.0696 7.65909 12.8217 6.43181 12.8217C5.83181 12.8217 5.25226 12.8854 4.72726 13.0058V11.9292C5.2659 11.8229 5.84545 11.7592 6.43181 11.7592C7.59091 11.7592 8.64091 11.9717 9.5 12.3471ZM6.43181 13.6504C7.59091 13.6504 8.64091 13.8558 9.5 14.2383V15.4142C8.72954 14.9608 7.65909 14.7129 6.43181 14.7129C5.83181 14.7129 5.25226 14.7767 4.72726 14.8971V13.8204C5.2659 13.7071 5.84545 13.6504 6.43181 13.6504Z"
      fill={color}
    />
    <path
      d="M18.3059 5.71635L18.4509 5.55068L19.1439 4.75872L18.3059 5.71635ZM18.3059 5.71635L18.4509 5.88202M18.3059 5.71635L18.4509 5.88202M18.4509 5.88202L19.8713 7.50528L20.0606 7.72163M18.4509 5.88202L20.0606 7.72163M20.0606 7.72163L20.25 7.50528M20.0606 7.72163L20.25 7.50528M20.25 7.50528L20.943 6.71332L20.25 7.50528ZM13.8562 10.8014L13.7939 10.8725V10.9671V12.5903V12.8419H14.0455H15.4659H15.5801L15.6552 12.756L19.8447 7.96845L19.9896 7.80279L19.8447 7.63712L18.4242 6.01385L18.2349 5.7975L18.0456 6.01385L13.8562 10.8014ZM20.943 5.77126L20.0569 4.75872L20.943 5.77126Z"
      fill={color}
      stroke={color !== '#0071DB' ? '#0071DB' : 'white'}
      strokeWidth="0.503141"
    />
  </svg>
);
export default BatchEditorIcon;
