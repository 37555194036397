import { Box, Button, Card, Divider, Flex, Text, Title } from '@mantine/core';
import fathomEventTracker from '../../analytics/fathomAnalytics';
import BrowserWindow from './BrowserWindow';
import { MobileActionHeader } from './MobileActionHeader';
import { useSetupStepsContext } from './ProjectSetupProvider';
import { TranslatedLanguages } from './TranslatedLanguages';

const NextButton = () => {
  const {
    setActiveStep,
    sourceTargetLangCodes: { targets },
  } = useSetupStepsContext();

  const isNextButtonDisabled = targets.length === 0;
  return (
    <Button
      w="auto"
      disabled={isNextButtonDisabled}
      onClick={() => {
        fathomEventTracker('Added Languages - 3');
        setActiveStep((prevStep) => prevStep + 1);
      }}
    >
      <Text c="inherit" fz="inherit" fw="inherit" id="added-languages-3">
        Next
      </Text>
    </Button>
  );
};

export const SelectLanguagesSideMenu = () => {
  const { setActiveStep } = useSetupStepsContext();

  return (
    <Flex
      w="100%"
      maw={450}
      rowGap="1.5rem"
      direction="column"
      justify="center"
      align="center"
    >
      <Title fz="2rem" order={1}>
        Project Setup
      </Title>
      <Divider w="100%" color="divider.0" size={2} />
      <Text fz="xl" fw={600} ta="center">
        Choose which language you want to offer.
      </Text>
      <Text fz="md" fw={500} ta="center">
        (You can edit this anytime after your project is created.)
      </Text>
      <Flex columnGap="1rem">
        <Button
          variant="outline"
          onClick={() => setActiveStep((prevStep) => prevStep - 1)}
        >
          Back
        </Button>
        <NextButton />
      </Flex>
    </Flex>
  );
};

export const SelectLanguagesBody = () => (
  <Box px="2rem">
    <BrowserWindow>
      <TranslatedLanguages />
    </BrowserWindow>
  </Box>
);

export const MobileSelectLanguagesStep = () => {
  const {
    sourceTargetLangCodes: { targets },
  } = useSetupStepsContext();

  const isNextButtonDisabled = targets.length === 0;

  return (
    <Card w="100%" maw={{ base: '100%', xs: 650, lg: 450 }} px="1rem" py="2rem">
      <Flex w="100%" direction="column" align="center" rowGap="1.5rem">
        <MobileActionHeader
          title="Project Setup"
          isNextButtonDisabled={isNextButtonDisabled}
        />
        <Divider w="100%" color="divider.0" size={2} />
        <TranslatedLanguages />
        <NextButton />
      </Flex>
    </Card>
  );
};
