import { Box } from '@mantine/core';
import { useSegmentEditorContext } from '../../context/SegmentEditorProvider';
import {
  useSegmentsStateActions,
  useIsSelected,
} from '../../store/SegmentsState';

import { getSourceGlossary } from '../../functions/segmentsFunctions';

import { JliffEditor } from '../Jliff/JliffEditor';
import { Source } from '../Jliff/Source';
import { StoreApi } from 'zustand';
import { EditHistoryStore } from '../../store/EditHistoryStore';
import { UpdateUnsavedList } from '../../SegmentEditor';

import classes from './Segment.module.css';
import { ReviewSegment } from '../Batch/ReviewSegment';

type SegmentProps = {
  segment: Segment;
  jliffStore: StoreApi<EditHistoryStore>;
  filterText: string;
  onSelect: (segmentHash: string) => void;
  deleteSegment: (segmentHash: string) => Promise<void>;
  updateUnsavedList: UpdateUnsavedList;
  previewAllSegments?: (segments: Segment[]) => void;
  index: number;
};

const Segment = ({
  segment,
  jliffStore,
  filterText,
  onSelect,
  updateUnsavedList,
  deleteSegment,
  previewAllSegments,
  index,
}: SegmentProps) => {
  const { setSelected } = useSegmentsStateActions();

  const isSelected = useIsSelected(segment.segment_hash);
  const { glossary: glossaryItems, isBatchMode } = useSegmentEditorContext();
  const segmentGlossary = getSourceGlossary(segment, glossaryItems);

  const selectSegment = (sHash: string) => {
    if (isSelected) return false;
    setSelected(sHash);
    if (typeof onSelect === 'function') onSelect(sHash);
  };

  return (
    <Box
      className={classes.segmentWrapper}
      style={{ gridTemplateColumns: isBatchMode ? '24px 1fr' : '1fr' }}
    >
      {isBatchMode && (
        <ReviewSegment segment={segment as BatchSegment} store={jliffStore} />
      )}
      <Box
        aria-label="segment"
        className={`${classes.segment} ${isSelected ? classes.selected : classes.unselected}`}
        onClick={() => {
          selectSegment(segment.segment_hash);
        }}
      >
        <Source
          segment={segment}
          filterText={filterText}
          glossary={segmentGlossary}
          store={jliffStore}
        />
        <JliffEditor
          store={jliffStore}
          updateUnsavedList={updateUnsavedList}
          deleteSegment={deleteSegment}
          previewAllSegments={previewAllSegments}
          segment={segment}
          glossary={segmentGlossary}
          highlightText={filterText}
        />
      </Box>
    </Box>
  );
};
export default Segment;
