import {
  Button,
  Flex,
  Stack,
  TextInput,
  Text,
  Title,
  Divider,
  Box,
  ScrollArea,
  Tooltip,
} from '@mantine/core';
import { useDeploymentContext } from './DeploymentContextProvider';
import {
  addDeploymentValue,
  COOKIE,
  DOMAIN,
  FOLDER,
  FormValues,
  getDeploymentTargets,
  hasValidDeploymentValues,
  Method,
  QUERY,
  removeDeploymentValue,
  SelectedMethod,
  TargetFormValue,
} from './deploymentUtils';
import React, { useEffect } from 'react';
import { notifications } from '@mantine/notifications';
import { useFieldArray, useForm } from 'react-hook-form';
import { MdAdd, MdOutlineRemoveCircleOutline } from 'react-icons/md';
import { isSuccessStatus } from 'helpers';

const getColumnWidths = (column: 'lang' | 'value', method: SelectedMethod) => {
  switch (true) {
    case column === 'lang' && method === COOKIE:
      return 'calc(max(60%, 250px))';
    case column === 'value' && method === COOKIE:
      return '40%';

    case column === 'lang' && method === DOMAIN:
      return 'calc(max(40%, 250px))';
    case column === 'value' && method === DOMAIN:
      return '60%';

    case column === 'lang' && method === FOLDER:
      return 'calc(max(60%, 250px))';
    case column === 'value' && method === FOLDER:
      return '40%';

    case column === 'lang' && method === QUERY:
      return 'calc(max(60%, 250px))';
    case column === 'value' && method === QUERY:
      return '40%';
    default:
      return '100%';
  }
};

const getPreviewText = (
  deploymentValue: string,
  deploymentName = '',
  deploymentMethod: SelectedMethod,
  origin_name: string
) => {
  switch (deploymentMethod) {
    case FOLDER:
      return `${origin_name}${deploymentValue}`;
    case DOMAIN:
      return `${deploymentValue}`;
    case QUERY:
      return `${origin_name}?${deploymentName}=${deploymentValue}`;
    case COOKIE:
      return `${deploymentName}=${deploymentValue}`;
    default:
      return deploymentValue;
  }
};

const LanguagesForm = () => {
  const {
    targets = [],
    updateDeploymentDetails,
    activeProject: {
      deployment_method = COOKIE,
      origin_name = '',
      deployment_name = '',
    } = {},
  } = useDeploymentContext();
  const deploymentTargets = getDeploymentTargets(targets);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { isDirty = false, errors },
  } = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      targetValues: deploymentTargets,
    },
  });
  const { fields: targetValues } = useFieldArray({
    control,
    name: 'targetValues',
  });

  const handleUpdate = async (data: { targetValues: TargetFormValue[] }) => {
    const { targetValues = [] } = data || {};
    const deploymentValues = targetValues.map(
      ({ deployment_value }) => deployment_value
    );

    updateDeploymentDetails({ deploymentValues }).then((responses) => {
      if (
        responses.every(
          (response) => !response || isSuccessStatus(response.status)
        )
      ) {
        notifications.show({
          message: `Successfully updated deployment details!`,
        });
      } else {
        notifications.show({
          message: `Failed to update deployment details!`,
          color: 'red',
        });
      }
    });
  };

  useEffect(() => {
    reset({ targetValues: getDeploymentTargets(targets) });
  }, [deployment_method, targets]);

  return (
    <form onSubmit={handleSubmit(handleUpdate)}>
      <Flex gap={20}>
        <Title order={2} w={getColumnWidths('lang', deployment_method)}>
          Language
        </Title>
        <Title order={2} w={getColumnWidths('value', deployment_method)}>
          Value
        </Title>
      </Flex>
      <ScrollArea.Autosize type={'auto'} mah={400} offsetScrollbars>
        <Stack my={10}>
          {targetValues.map(
            (
              {
                target_lang_code = '',
                target_lang_name = '',
                deployment_value = [],
              },
              targetIndex
            ) => {
              const hasMultipleValues = deployment_value.length > 1;
              return (
                <Stack key={deployment_value.toString() + targetIndex}>
                  <Flex gap={20}>
                    <Stack
                      gap={2}
                      w={getColumnWidths('lang', deployment_method)}
                    >
                      <Text>{target_lang_name}</Text>
                      <Tooltip
                        label={
                          <Text>
                            {getPreviewText(
                              deployment_value[0],
                              deployment_name,
                              deployment_method,
                              origin_name
                            )}
                          </Text>
                        }
                        maw={'fit-content'}
                        withArrow={false}
                        zIndex={1001}
                      >
                        <Text truncate fz={'xs'}>
                          {getPreviewText(
                            deployment_value[0],
                            deployment_name,
                            deployment_method,
                            origin_name
                          )}
                        </Text>
                      </Tooltip>
                    </Stack>
                    <Flex w={getColumnWidths('value', deployment_method)}>
                      <Stack mr={5} w={'100%'}>
                        {deployment_value.map(
                          (deploymentValueEntry, deploymentIndex) => {
                            const invalid =
                              errors.targetValues?.[targetIndex]
                                ?.deployment_value?.[deploymentIndex]
                                ?.message === 'invalid';
                            return (
                              <Flex
                                key={deploymentValueEntry + deploymentIndex}
                              >
                                <TextInput
                                  {...register(
                                    `targetValues.${targetIndex}.deployment_value.${deploymentIndex}`,
                                    {
                                      validate: (fieldValue) => {
                                        return (
                                          hasValidDeploymentValues(
                                            [fieldValue],
                                            deployment_method as Method
                                          ) || 'invalid'
                                        );
                                      },
                                    }
                                  )}
                                  defaultValue={deploymentValueEntry}
                                  error={invalid}
                                  w="100%"
                                />
                              </Flex>
                            );
                          }
                        )}
                      </Stack>
                      {deployment_method !== COOKIE && (
                        <Flex
                          w={100}
                          align={hasMultipleValues ? 'flex-end' : 'center'}
                        >
                          <Flex
                            h="2.5rem"
                            align="center"
                            columnGap="0.5rem"
                            w={'100%'}
                          >
                            {hasMultipleValues && (
                              <Box
                                h={25}
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  removeDeploymentValue(
                                    target_lang_code,
                                    getValues,
                                    setValue
                                  )
                                }
                              >
                                <MdOutlineRemoveCircleOutline
                                  size={25}
                                  color={'var(--mantine-color-icon-0'}
                                />
                              </Box>
                            )}
                            <Box
                              h={25}
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                addDeploymentValue(
                                  target_lang_code,
                                  getValues,
                                  setValue
                                )
                              }
                            >
                              <MdAdd
                                size={25}
                                color="var(--mantine-color-icon-0"
                              />
                            </Box>
                          </Flex>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                  {targetIndex !== deploymentTargets.length - 1 && <Divider />}
                </Stack>
              );
            }
          )}
        </Stack>
      </ScrollArea.Autosize>
      <Flex justify="flex-end" gap={5}>
        <Button variant="outline" onClick={() => reset()} disabled={!isDirty}>
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={!isDirty || (errors.targetValues?.length ?? 0) > 0}
        >
          Save
        </Button>
      </Flex>
    </form>
  );
};
export default LanguagesForm;
