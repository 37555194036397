import { MantineColor, StyleProp } from '@mantine/core';
import TranslationBatchIcon from '../../assets/images/TranslationBatchIcon.svg';
import TestingBatchIcon from '../../assets/images/TestingBatchIcon.svg';
import ReviewBatchIcon from '../../assets/images/ReviewBatchIcon.svg';
import ValidationBatchIcon from '../../assets/images/ValidationBatchIcon.svg';
import {
  createIntersectionSet,
  ProjectUserPermission,
  USER_ROLE_ADMIN,
  USER_ROLE_GLGO_ADMIN,
  USER_ROLE_LINGUIST,
  USER_ROLE_PROJECT_MANAGER,
  USER_ROLE_TRANSPERFECT_ADMIN,
  USER_ROLE_TRANSPERFECT_USER,
} from 'helpers';

export const BATCH_PHASE_TRANSLATION = 'translation';
export const BATCH_PHASE_TESTING = 'testing';
export const BATCH_PHASE_REVIEW = 'review';
export const BATCH_PHASE_VALIDATION = 'validation';

export const BATCH_STATUS_TODO = 'to do';
export const BATCH_STATUS_INPROGRESS = 'in progress';
export const BATCH_STATUS_READY = 'ready';

export const reviewAssigneeSet = new Set([
  USER_ROLE_ADMIN,
  USER_ROLE_PROJECT_MANAGER,
]);

export const nonReviewAssigneeSet = new Set([
  USER_ROLE_GLGO_ADMIN,
  USER_ROLE_TRANSPERFECT_ADMIN,
  USER_ROLE_TRANSPERFECT_USER,
]);

export const getStatusColor = (status: string): StyleProp<MantineColor> => {
  switch (status.toLowerCase()) {
    case BATCH_STATUS_TODO: {
      return 'status1.0';
    }
    case BATCH_STATUS_INPROGRESS: {
      return 'status1.1';
    }
    case BATCH_STATUS_READY: {
      return 'status1.2';
    }
    case 'completed': {
      return 'status1.3';
    }
    case 'cancelled': {
      return 'status1.4';
    }
    default: {
      return 'status1.3';
    }
  }
};

export const getPhaseIcon = (phase: string) => {
  switch (phase.toLowerCase()) {
    case BATCH_PHASE_TRANSLATION: {
      return TranslationBatchIcon;
    }
    case BATCH_PHASE_TESTING: {
      return TestingBatchIcon;
    }
    case BATCH_PHASE_REVIEW: {
      return ReviewBatchIcon;
    }
    case BATCH_PHASE_VALIDATION: {
      return ValidationBatchIcon;
    }
    default: {
      return '';
    }
  }
};

export const mergeParams = (
  form: HTMLFormElement,
  searchParams: URLSearchParams,
  clearForm?: boolean
) => {
  const originalFormData = new FormData(form);
  const submitFormData = new FormData(form);

  // Append existing search parameters to form data
  for (const [key, value] of searchParams.entries()) {
    if (!originalFormData.has(key)) {
      submitFormData.append(key, value);
    }
  }

  //remove empty keys
  const keysToRemove = [] as string[];
  submitFormData.forEach((value, key) => {
    if (!value) {
      keysToRemove.push(key);
      //remove keys from original form when clearing the form
    } else if (clearForm && originalFormData.has(key)) {
      keysToRemove.push(key);
    }
  });

  keysToRemove.forEach((key) => submitFormData.delete(key));

  // return the formData with the provided options
  return submitFormData;
};

//assumes batch names are of the form RT-Hash-CreatedDate and turns them to RT-Hash
//will not affect names that don't have a "-" in them
export const normalizeBatchName = (name: string) =>
  name.split('-').slice(0, 2).join('-');

export const isValidBatchAssignee = (
  user: ProjectUserPermission,
  batch: ActiveBatch | BatchDetailsResponse
) => {
  const isContractorWithSameTkey = (user: User) =>
    user.user_type === 'contractor' &&
    user.languagePermissionSet.has(batch.translation_key);

  const isLinguistWithSameTkey = (user: User) =>
    user.rolePermissionSet.has(USER_ROLE_LINGUIST) &&
    user.languagePermissionSet.has(batch.translation_key);

  return batch.phase === BATCH_PHASE_REVIEW
    ? Boolean(
        createIntersectionSet(reviewAssigneeSet, user.rolePermissionSet).size
      ) || isLinguistWithSameTkey(user)
    : Boolean(
        createIntersectionSet(nonReviewAssigneeSet, user.rolePermissionSet).size
      ) || isContractorWithSameTkey(user);
};
