import { QueryClient } from '@tanstack/react-query';
import { languageCodesQuery } from '../queries/languageCodesQuery';
import { projectQuery } from '@glweb/xapis-client';
import { decodeProjectId, isValidKey, isValidPID } from 'helpers';

export const projectSetupLoader =
  (queryClient: QueryClient) =>
  async ({ request }: { request: Request }) => {
    const id = new URL(request.url).searchParams.get('id') || '';
    const projectKey = decodeProjectId(id);

    if (!isValidPID(id) || !isValidKey(projectKey)) {
      const { language_codes } = await queryClient.ensureQueryData(
        languageCodesQuery('google')
      );
      return { language_codes };
    }

    const { project } = await queryClient.ensureQueryData(
      projectQuery(projectKey, false)
    );
    return { project };
  };
