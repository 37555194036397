import { COOKIE, deploymentMethods, Method } from './deploymentUtils';
import {
  Card,
  Flex,
  Stack,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import React, { useState } from 'react';
import { useDeploymentContext } from './DeploymentContextProvider';
import { useCurrentProjectData } from 'ui';
import UpgradeToPlusModal from './UpgradeToPlusModal';
import RightSectionDeploymentButton from './RightSectionDeploymentButton';
import { isSuccessStatus } from 'helpers';
import { notifications } from '@mantine/notifications';

type Props = {
  method: keyof typeof deploymentMethods;
  isSelected: boolean;
  setShowUpgradeModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const Deployment = ({ method, isSelected, setShowUpgradeModal }: Props) => {
  const {
    Icon = () => <></>,
    UrlPreview = () => <></>,
    blurb = '',
  } = deploymentMethods[method as Method] || {};
  const { colors } = useMantineTheme();

  const {
    activeProject: { origin_name: url = '' } = {},
    updateDeploymentDetails,
  } = useDeploymentContext();

  const {
    project: { deployment_name, deployment_method },
  } = useCurrentProjectData();

  const defaultDeploymentName = 'langPref';

  const [editing, setEditing] = useState(false);
  const [deploymentName, setDeploymentName] = useState<string>(
    deployment_name || defaultDeploymentName
  );

  return (
    <Card
      key={method}
      w="100%"
      px="1rem"
      py="0.75rem"
      pos="relative"
      shadow={isSelected ? '0 0 6px 0 var(--mantine-color-border1-1)' : 'none'}
      style={{
        borderColor: isSelected
          ? 'var(--mantine-color-border1-1)'
          : 'var(--mantine-color-border1-0)',
        borderRadius: 6,
      }}
    >
      <Flex columnGap="1rem" align="center">
        <Flex>
          <Icon colors={colors} />
        </Flex>
        <Stack gap={0} miw={0}>
          <Text fz="md" fw={600} tt="capitalize">
            {method}
          </Text>
          <Flex>
            <Text>{blurb}</Text>
            {method !== COOKIE ? (
              <Tooltip
                withArrow={false}
                maw="fit-content"
                position="top"
                label={
                  <UrlPreview
                    url={url}
                    textColor="font1.0"
                    fontSize="auto"
                    queryName={deploymentName}
                  />
                }
                zIndex={1001}
              >
                <Text truncate fz="md" fw={600}>
                  &nbsp;(
                  <UrlPreview url={url} queryName={deploymentName} />)
                </Text>
              </Tooltip>
            ) : (
              <></>
            )}
          </Flex>
          {editing && deployment_method === method && (
            <Flex tt="capitalize" align={'center'}>
              {method} Name:
              <TextInput
                value={deploymentName}
                onChange={(e) => setDeploymentName(e.target.value)}
                ml={20}
              />
            </Flex>
          )}
        </Stack>
        <RightSectionDeploymentButton
          editing={editing}
          setEditing={setEditing}
          setShowUpgradeModal={setShowUpgradeModal}
          selected={isSelected}
          method={method}
          onEditSave={() =>
            updateDeploymentDetails({
              deploymentValues: [],
              deploymentName: deploymentName,
            })
              .then((responses) => {
                if (
                  responses.every(
                    (response) => !response || isSuccessStatus(response.status)
                  )
                ) {
                  notifications.show({
                    message: `Successfully updated ${method} name!`,
                  });
                } else {
                  notifications.show({
                    message: `Failed to update ${method} name!`,
                    color: 'red',
                  });
                }
              })
              .finally(() => setEditing(false))
          }
          onCancel={() => {
            setEditing(false);
            setDeploymentName(deployment_name || defaultDeploymentName);
          }}
          editSaveDisabled={deploymentName === deployment_name}
        />
      </Flex>
    </Card>
  );
};

const DeploymentMethodChoices = () => {
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const { activeProject: { deployment_method: defaultMethod = '' } = {} } =
    useDeploymentContext();

  return (
    <>
      <Flex w="100%" direction="column" rowGap="1.5rem">
        {Object.keys(deploymentMethods).map((method) => {
          const isSelected = method === defaultMethod;

          return (
            <Deployment
              method={method as keyof typeof deploymentMethods}
              isSelected={isSelected}
              key={method}
              setShowUpgradeModal={setShowUpgradeModal}
            />
          );
        })}
      </Flex>
      <UpgradeToPlusModal
        opened={showUpgradeModal}
        setOpened={setShowUpgradeModal}
      />
    </>
  );
};
export default DeploymentMethodChoices;
