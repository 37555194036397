import { dashboardRC } from '@glweb/constants';
import { HiPencil } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { Header, useGLGOParams } from 'ui';

export default function MyLanguagesHeader() {
  const { projectId } = useGLGOParams();

  return (
    <Header
      minHeight={40}
      variant="h2"
      title="My Languages"
      tooltipLabel="This shows the languages your website can be translated into.
                To add or remove languages, and to manage other settings, go to your Project Settings."
      flexItems={
        <Link
          to={`/${projectId}/${dashboardRC.PAGE_SETTINGS}/language-settings`}
        >
          <HiPencil className="cardActionIcon" />
        </Link>
      }
    />
  );
}
