import { desktopRC } from '@glweb/constants';
import { Card, Flex, Text, useMantineTheme } from '@mantine/core';
import { encodeKey, getMTEngine } from 'helpers';
import { HiPencil } from 'react-icons/hi';
import { MdOutlineArrowForwardIos } from 'react-icons/md';
import { OpenWebStudio, StatusCircleIcon, useGLGOParams } from 'ui';

const Column = ({ title, value }: { title: string; value: string }) => (
  <Flex direction={'column'} miw={100}>
    <Text c="font1.0" fz="xs" fw={700}>
      {title}
    </Text>
    <Text fw={500}>{value}</Text>
  </Flex>
);

type Props = {
  translation: TranslationKey;
  handleSelection: (tKey: string) => void;
};
export const TranslationCard = ({ translation, handleSelection }: Props) => {
  const { colors } = useMantineTheme();

  const {
    target_lang_code,
    target_lang_name = '',
    source_lang_name = '',
    is_live = false,
    num_segment_translations = 0,
    mt_api_code = '',
    translation_key = '',
  } = translation || {};

  const sourceLanguage =
    source_lang_name
      ?.substring(0, source_lang_name.indexOf('('))
      .slice(0, -1) || 'English';
  const mtEngine = getMTEngine(mt_api_code);
  const { projectId } = useGLGOParams();

  return (
    <Card p={0}>
      <Flex
        wrap={'wrap'}
        mih={105}
        align="center"
        px={{ base: 20, md: 40 }}
        py={10}
      >
        <Flex
          direction="row"
          align="center"
          gap={20}
          miw={'min(450px, 100%)'}
          flex={'1 0 auto'}
        >
          <StatusCircleIcon status={is_live ? 'live' : 'private'} />
          <Text fw={500}>{sourceLanguage}</Text>
          <MdOutlineArrowForwardIos size={18} color={colors.font1[1]} />
          <Text style={{ whiteSpace: 'pre-wrap' }} fw={500}>
            {target_lang_name || target_lang_code}
          </Text>
        </Flex>
        <Flex gap={30} justify={'space-between'} flex={1}>
          <Column title="MT ENGINE" value={mtEngine} />
          <Column
            title="# OF SEGMENTS"
            value={new Intl.NumberFormat().format(num_segment_translations)}
          />
          <Flex align={'center'} justify={'center'} gap={8}>
            <Flex
              onClick={() => {
                handleSelection(translation_key);
              }}
            >
              <HiPencil className="cardActionIcon" size={18} />
            </Flex>
            <OpenWebStudio
              path={`/${projectId}/${desktopRC.TRANSLATIONS}?t=${encodeKey(translation_key)}`}
            />
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default TranslationCard;
