import { Box, Flex, Select, Text } from '@mantine/core';
import { getAvailableLanguages } from 'helpers';
import { FaChevronDown } from 'react-icons/fa6';
import { useSetupStepsContext } from './ProjectSetupProvider';
import TargetLanguagesSelector from './TargetLanguagesSelector';
import classes from './TranslatedLanguages.module.css';

export const TranslatedLanguages = () => {
  const {
    language_codes,
    sourceTargetLangCodes: { source, targets },
    setSourceTargetLangCodes,
  } = useSetupStepsContext();

  const availableSourceLanguages = getAvailableLanguages(
    'source',
    language_codes,
    {
      sourceLangCode: source,
      targetLangCodes: targets,
    }
  );
  const availableTargetLanguages = getAvailableLanguages(
    'targets',
    language_codes,
    {
      sourceLangCode: source,
      targetLangCodes: targets,
    }
  );

  return (
    <Flex w="100%" direction="column" rowGap="1.5rem">
      <Flex w="100%" p={0} pos="relative" direction="column" rowGap="1rem">
        <Box>
          <Text fw={600} fz="md">
            SOURCE LANGUAGE
          </Text>
          <Text mt="0.5rem" fw={400} c="text.9">
            Confirm the original language of your website.
          </Text>
        </Box>
        <Select
          size="md"
          width="100%"
          maw="25rem"
          type="auto"
          allowDeselect={false}
          classNames={{
            dropdown: classes.selectDropdown,
            input: classes.selectSourceInput,
          }}
          rightSection={<FaChevronDown size={18} />}
          onChange={(value) => {
            setSourceTargetLangCodes((prevLangCodes) => ({
              ...prevLangCodes,
              source: value as string,
            }));
          }}
          value={source}
          data={availableSourceLanguages}
        />
      </Flex>
      <Flex w="100%" p={0} pos="relative" direction="column" rowGap="1rem">
        <Box>
          <Text fw={600} fz="md">
            TRANSLATED LANGUAGE(S)
          </Text>
          <Text mt="0.5rem" fw={400} c="text.9">
            Type to add at least one translated language
          </Text>
        </Box>
        <TargetLanguagesSelector targetLanguages={availableTargetLanguages} />
      </Flex>
    </Flex>
  );
};
