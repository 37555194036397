import { useNavigate, useParams } from 'react-router-dom';
import { Button, Flex, Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Xapis } from '@glweb/xapis-client';
import { isSuccessStatus } from 'helpers';
import { failureNotification, successNotification, OpenWebStudio } from 'ui';
import { desktopRC } from '@glweb/constants';
import { MdArrowDropDown } from 'react-icons/md';
import { BatchProgressDetails } from './BatchProgressDetails';
import BatchActionsDropdown from '../BatchActionsDropdown';
import { BatchFieldOptions } from '../../../pages/batches/ActiveBatchesPage';
import { CancelBatchesModal } from '../CancelBatchesModal';
import { useDetailedBatch } from '../../../queries/batchesQueries';
import { BatchSelector } from './BatchSelector';
import EditBatchModal from '../EditBatchModal';
import ApproveURLsModal from './ApproveURLsModal';
import { GradeBatchModal } from '../GradeBatchModal';
import { CompleteBatchesModal } from '../batch-history/CompleteBatchesModal';

export const BatchDetailsFilters = () => {
  const { batch } = useDetailedBatch();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const studioPath = `/${projectId}/${desktopRC.BATCH}?batch_key=${batch.batch_key}`;

  const [
    approveUrlsModalOpened,
    { open: openApproveUrlsModal, close: closeApproveUrlsModal },
  ] = useDisclosure();
  const [
    cancelBatchModalOpened,
    { open: openCancelModal, close: closeCancelModal },
  ] = useDisclosure();
  const [
    completeBatchModalOpened,
    { open: openCompleteModal, close: closeCompleteModal },
  ] = useDisclosure();
  const [
    gradeBatchModalOpened,
    { open: openGradingModal, close: closeGradingModal },
  ] = useDisclosure();

  const [
    editBatchModalOpened,
    { open: openEditModal, close: closeEditBatchModal },
  ] = useDisclosure();

  const handleBatchChange = (
    params: Partial<Record<Partial<BatchFieldOptions>, string>>
  ) => {
    return Xapis.Batches.patch({
      translationKey: batch.translation_key,
      batchKeyList: batch.batch_key,
      params: params,
    })
      .then((xapisBatchPatchResult) => {
        const { status, data } = xapisBatchPatchResult;
        const hasBatchNames = Boolean(data?.batches[0]?.batch_name);

        if (!isSuccessStatus(status)) {
          failureNotification(xapisBatchPatchResult);
        } else {
          hasBatchNames
            ? successNotification(
                xapisBatchPatchResult,
                `Batch${data.batches.length > 1 ? 'es' : ''} (${data.batches.map(({ batch_name }: ActiveBatch) => batch_name).join(', ')}) updated.`
              )
            : successNotification(xapisBatchPatchResult);
        }
      })
      .finally(() => {
        closeCompleteModal();
        closeCancelModal();
        if (params.phase === 'complete') {
          //return to active batches page
          navigate('..');
        }
      });
  };
  return (
    <>
      <Flex align="center" columnGap={20} rowGap={10} wrap="wrap">
        <BatchSelector />
        <BatchProgressDetails />
        <Menu>
          <Menu.Target>
            <Button rightSection={<MdArrowDropDown size={24} />}>
              Actions
            </Button>
          </Menu.Target>
          <BatchActionsDropdown
            handleBatchChange={handleBatchChange}
            openCancelBatchModal={openCancelModal}
            openCompleteBatchesModal={openCompleteModal}
            openGradingBatchModal={openGradingModal}
            openEditBatchModal={openEditModal}
            openApproveUrlsModal={openApproveUrlsModal}
            isDetailed
            batches={[batch.batch_key]}
          />
        </Menu>
        <OpenWebStudio path={studioPath} />
      </Flex>
      <GradeBatchModal
        editBatch={handleBatchChange}
        opened={gradeBatchModalOpened}
        close={closeGradingModal}
        existingComment={batch.batch_comment}
      />
      <CancelBatchesModal
        hasMultipleBatches={false}
        cancelBatch={handleBatchChange}
        opened={cancelBatchModalOpened}
        close={closeCancelModal}
      />
      <CompleteBatchesModal
        hasMultipleBatches={false}
        completeBatches={handleBatchChange}
        opened={completeBatchModalOpened}
        close={closeCompleteModal}
      />
      <EditBatchModal
        batch={batch}
        editBatch={(params) => handleBatchChange(params)}
        opened={editBatchModalOpened}
        close={closeEditBatchModal}
      />
      <ApproveURLsModal
        urlHashes={batch.urls.map((url) => url.content_url_hash)}
        batchKey={batch.batch_key}
        translationKey={batch.translation_key}
        opened={approveUrlsModalOpened}
        onClose={closeApproveUrlsModal}
      />
    </>
  );
};
