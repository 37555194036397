import { queryOptions } from '@tanstack/react-query';
import {
  createProjectUserPermissionMap,
  createTranslationMap,
  decodeProjectId,
  filterTranslations,
  getYYTarget,
  isSuccessStatus,
  isValidKey,
  UserRole,
} from 'helpers';
import { xapisEnv } from '../service-wrappers/xapisService';
import { getUserKey, ProjectResponse, Xapis } from '../xapis-wrappers/xapis';

const projectQuery = <T>(
  key: string,
  shouldFetchSubscriptions: boolean = true,
  options?: Partial<T>
) => {
  const projectKey = isValidKey(key) ? key : decodeProjectId(key);

  return queryOptions({
    queryKey: ['project', projectKey],
    queryFn: () => getProjectData(projectKey, shouldFetchSubscriptions),
    staleTime: 1000 * 60,
    ...options,
  });
};

const { payKey } = xapisEnv.getHost;

async function getProjectData(
  projectKey: string,
  shouldFetchSubscriptions: boolean
) {
  if (!projectKey) {
    throw new Response('Project key is not defined', {
      status: 404,
      statusText: 'Project key is not defined',
    });
  }
  const response: ProjectResponse = await Xapis.Project.get(projectKey, true);

  if (!response || !isSuccessStatus(response.status) || !response.project!) {
    throw new Error('Failed to fetch project data');
  }
  const { project } = response;

  const projectTranslations = filterTranslations(project?.translations || []);
  const yyTranslation = getYYTarget(project?.translations || []);

  const projectTranslationMap = createTranslationMap(project);
  const projectUserPermissionMap = createProjectUserPermissionMap(
    project,
    projectTranslationMap
  );

  const isUserRole = (userRole: UserRole): boolean =>
    projectUserPermissionMap[getUserKey()] &&
    projectUserPermissionMap[getUserKey()].rolePermissionSet.has(userRole);
  const isTranslationRole = (translationKey: string): boolean =>
    projectUserPermissionMap[getUserKey()] &&
    projectUserPermissionMap[getUserKey()].languagePermissionSet.has(
      translationKey
    );

  const data = {
    isTranslationRole,
    isUserRole,
    projectTranslationMap,
    projectUserPermissionMap,
    translations: projectTranslations,
    subscriptions: [] as Subscription[],
    yyTranslation,
    project,
  };

  if (!shouldFetchSubscriptions) {
    return data;
  }

  try {
    const subscriptionsRes = await Xapis.Subscription.get(payKey, projectKey);
    const { subscriptions = [] } = subscriptionsRes?.data || {};
    data.subscriptions = subscriptions;
  } catch (error) {
    console.error('subs fetch error inside projectLoader', error);
  }

  return data;
}

export default projectQuery;
