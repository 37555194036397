export const defaultProject: ProjectKey = {
  additional_origins: [],
  base_domain: '',
  disable_ope: false,
  deployment_method: '',
  deployment_name: '',
  is_active: false,
  is_frozen: false,
  is_glnow_enabled: false,
  is_onelinktoken_validated: false,
  is_pci: false,
  is_referer_validated: false,
  is_translation_disabled: false,
  created_user: '',
  created_utc: '',
  groups: [],
  lastmod_user: '',
  lastmod_utc: '',
  origin_name: '',
  project_config: '',
  project_config_hash: '',
  project_dbname: '',
  project_key: '',
  project_name: '',
  project_status: '',
  project_type: '',
  quote_ticket: '',
  root_domain: '',
  skeleton_version: 0,
  subscription_pay_key: '',
  subscription_sku: '',
  subscription_type: '',
  subscription_status: 'expired',
  subscription_value: 0,
  threshold_type: '',
  threshold_value: 0,
  translations: [],
  yy_translation_key: '',
};

export const defaultUser: User = {
  user_key: '',
  project_keys: [],
  avatar_url: '',
  email: '',
  first_name: '',
  last_name: '',
  can_create_group: false,
  can_create_project: false,
  company_name: '',
  group_key: '',
  is_active: false,
  lastmod_user: '',
  lastmod_utc: '',
  phone: '',
  ui_view: '',
  user_type: '',
};
