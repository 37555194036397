import { getHostConstants, LIVE, STAGING } from '@glweb/constants';
import {
  defaultUser,
  projectQuery,
  Xapis,
  xapisEnv,
} from '@glweb/xapis-client';
import { notifications } from '@mantine/notifications';
import { QueryClient } from '@tanstack/react-query';
import { AuthContextProps, shouldSkipSSO } from 'auth';
import { asciiToHex, encodeProjectKey, saveSelectedProjectKey } from 'helpers';
import { handleHubSpotContact, sendSugarData } from 'integrations';
import { SetURLSearchParams } from 'react-router-dom';
import {
  getCustomCSS,
  getLanguageSelectorPositions,
} from './LanguageSelectorAppearances';
import { startSpiderJob } from './startSpiderJob';
import { AccountForm, CreateProjectData, ProjectSetupStatus } from './types';

const handleError = (message: string = '', autoClose = true, id?: string) => {
  notifications.show({
    message,
    color: 'red',
    autoClose,
    id,
  });
};

export const handleCreateProject = (
  data: CreateProjectData,
  updateStatus: (status: boolean, key: keyof ProjectSetupStatus) => void,
  setActiveStep: React.Dispatch<React.SetStateAction<number>>,
  xapisUser: User = defaultUser,
  setXapisUser: (user: User) => void,
  setSearchParams: SetURLSearchParams,
  queryClient: QueryClient
) => {
  updateStatus(true, 'isCreatingProject');

  const { domain, sourceTargetLangCodes, themePosition } = data;
  const pay_key = xapisEnv.getHost.payKey;
  const { email = '' } = xapisUser;
  const { source, targets } = sourceTargetLangCodes;
  const { theme, position } = themePosition;
  const { topBottom, leftRight } = getLanguageSelectorPositions(position);
  const customCSS = getCustomCSS(topBottom, leftRight, theme);

  Xapis.Register.post({
    domain,
    pay_key,
    source_lang_code: source,
    targets,
    email,
    custom_css: asciiToHex(customCSS),
  })
    .then(async ({ data }) => {
      const { project_key = '', project_keys: projects = [] } = data;

      const projectFound = projects.find(
        ({ project_key: pKey }: { project_key: string }) => {
          return pKey === project_key;
        }
      );

      if (!projectFound || !projectFound?.project_key) {
        handleError('Oops, something went wrong while creating the project');
        return;
      }

      handleHubSpotContact(
        {
          properties: {
            website: domain,
          },
        },
        email
      );

      saveSelectedProjectKey(project_key);
      setXapisUser({ ...xapisUser, project_keys: projects });

      if (queryClient) {
        const { project: { yy_translation_key = '' } = {} } =
          await queryClient.ensureQueryData(projectQuery(project_key, false));

        if (yy_translation_key) {
          startSpiderJob(yy_translation_key, projectFound.project_name || '');
        }
      } else {
        handleError('Oops, something went wrong while creating the project');
        return;
      }

      setSearchParams({ id: encodeProjectKey(project_key) }, { replace: true });
      setActiveStep((prevStep) => prevStep + 1);
    })
    .catch(() => {
      handleError('Unable to create project at this time. Please try again');
    })
    .finally(() => updateStatus(false, 'isCreatingProject'));
};

export const handleCreateAccount = (
  data: Partial<AccountForm>,
  setIsCreatingAccount: React.Dispatch<React.SetStateAction<boolean>>,
  setIsAlreadyCreated: React.Dispatch<React.SetStateAction<boolean>>,
  auth: AuthContextProps,
  searchParams: URLSearchParams
) => {
  const notificationId = 'register';
  notifications.hide(notificationId);

  setIsAlreadyCreated(false);
  setIsCreatingAccount(true);

  const {
    email = '',
    password = '',
    first_name = '',
    last_name = '',
    subscribed_to_marketing = false,
  } = data;

  Xapis.Register.post(
    {
      email,
      password,
      first_name,
      last_name,
      pay_key: xapisEnv.getHost.payKey,
    },
    { 'user-only': '1', generate_otac: '1' }
  )
    .then(({ data }) => {
      const { user_key = '', otac = '', message = '' } = data;

      if (!user_key) {
        handleError('Oops, something went wrong while creating your account');
        return;
      }

      if (message === 'already created.') {
        setIsAlreadyCreated(true);
        handleError(
          'Account already created. Please try logging in.',
          false,
          notificationId
        );
        return;
      }

      if (shouldSkipSSO()) {
        sessionStorage.setItem('user-email', email);
      }

      const { label = '' } = xapisEnv.getHost;
      const { url: sugarUrl = null, ...rest } = getHostConstants().sugar ?? {};

      const isLiveEnv = label === LIVE;
      const isLiveOrStagingEnv = isLiveEnv || label === STAGING;

      if (isLiveOrStagingEnv) {
        if (sugarUrl && 'fields' in rest) {
          sendSugarData(sugarUrl, {
            first_name,
            last_name,
            email,
            email1: email,
            fq_email_sub_c: subscribed_to_marketing,
            ga_key_c: '',
            ga_landing_c: '',
            utm_campaign_c: searchParams.get('utm_campaign') ?? '',
            utm_content_c: searchParams.get('utm_content') ?? '',
            utm_medium_c: searchParams.get('utm_medium') ?? '',
            utm_source_c: searchParams.get('utm_source') ?? '',
            fq_hidden_url_c: window.location.href,
            ...rest.fields,
          });
        }
      }

      if (isLiveEnv) {
        const todaysDate = new Date();
        const todaysDateInMS = Date.UTC(
          todaysDate.getUTCFullYear(),
          todaysDate.getUTCMonth(),
          todaysDate.getUTCDate()
        );

        Xapis.CRM.Hubspot.post({
          properties: {
            email: email,
            hs_all_assigned_business_unit_ids: 1557374,
            glgo_account_create_date: todaysDateInMS,
            hs_marketable_status: true,
            hs_marketable_reason_type: 'FORM_SUBMISSION',
            subscribed_to_marketing,
          },
        });
      }

      if (otac || shouldSkipSSO()) {
        auth.signinRedirect({
          extraQueryParams: { acr_values: `otac:${otac}` },
        });
      } else {
        handleError(
          'Account created, unable to access auto login. Please log in manually',
          false
        );
      }
    })
    .catch(() => {
      handleError('Unable to create account at this time.');
    })
    .finally(() => {
      setIsCreatingAccount(false);
    });
};
