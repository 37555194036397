import { Button, Box, Flex, Progress, Text, Menu } from '@mantine/core';

import { themeColors } from '../../theme/colors';
import { menueStyles } from '../../theme/styles';
import { CheckMarkIcon } from '../../icons/IndicatorIcons';

export const BatchTopBar = ({
  progress,
  onApproveAll,
}: {
  progress: number;
  onApproveAll: () => void;
}) => (
  <Flex className="batchBar" justify="space-between">
    <Flex align="center" gap={10} p={5}>
      <Text ml={10} fw={700}>
        REVIEW PROGRESS
      </Text>

      <Progress.Root size={18} w={150} bg={themeColors.gray[3]}>
        <Progress.Section
          value={progress}
          bg={`linear-gradient(90deg, ${themeColors.gray[3]} 0%, ${themeColors.secondery[3]} 100%)`}
          style={{ borderRadius: 10 }}
        >
          {progress > 30 && (
            <Progress.Label
              c={themeColors.gray[8]}
              style={{ fontWeight: 600 }}
            >{`${progress}%`}</Progress.Label>
          )}
        </Progress.Section>
        <Progress.Section value={100 - progress} bg={themeColors.gray[3]}>
          {progress < 30 && (
            <Progress.Label
              c={themeColors.gray[8]}
              style={{ fontWeight: 600 }}
            >{`${progress}%`}</Progress.Label>
          )}
        </Progress.Section>
      </Progress.Root>

      <Menu
        withArrow={false}
        position="right"
        disabled={progress === 100}
        styles={menueStyles}
      >
        <Menu.Target>
          <Flex align="center">
            <CheckMarkIcon
              fillColor={
                progress === 100
                  ? themeColors.secondery[5]
                  : themeColors.gray[6]
              }
            />
          </Flex>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={onApproveAll}>Check All</Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Flex>
    <Box className="buttons">
      <Button
        variant="transparent"
        onClick={() => window.editorAPI?.navigateBatchUrl('previous')}
      >{`< Previous`}</Button>
      <Button
        variant="transparent"
        onClick={() => window.editorAPI?.navigateBatchUrl('next')}
      >{`Next >`}</Button>
    </Box>
  </Flex>
);
