import { MantineColorsTuple } from '@mantine/core';

export const themeColors = {
  blue: [
    'transparent', // Placeholder
    '#ECF5FF', // 1
    '#E0E8F5', // 2
    '#B4D7FD', // 3
    '#139DD8', // 4
    '#4094E4', // 5
    '#0071DB', // 6
    '#255C8E', // 7
    '#003B71', // 8
    'transparent', // Placeholder
  ],
  gray: [
    'transparent', // Placeholder
    '#FAFCFF', // 1
    '#ECEFF2', // 2
    '#DBE1E5', // 3
    'transparent', // Placeholder
    '#AEBDDA', // 5
    '#757575', // 6
    'transparent', // Placeholder
    '#334552', // 8 Primary text
    'transparent', // Placeholder
  ],
  green: [
    '#EFF6F1', // 0 //
    '#d3f9d8', // 1
    '#AED567', // 2 //
    '#8ce99a', // 3
    '#69db7c', // 4
    '#51cf66', // 5
    '#48B076', // 6 //
    '#169355', // 7 //
    '#188038', // 8 //
    '#2b8a3e', // 9
  ],
  secondery: [
    '#FD8457', // 0: Orange hover bg
    '#FC5B1F', // 1: Orange active button
    '#D93025', // 2: Red
    '#83D4D9', // 3: Active studio mode underline
    '#EFF6F1', // 4: Pale green
    '#169355', // 5: Green
    '#188038', // 6: Darker Green
    '#FDF5B2', // 7: Pale yellow
    '#F6D94B', // 8: Yellow
    'transparent', // Placeholder
  ],
} as const;

export type ThemeColors = typeof themeColors;
