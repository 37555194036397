import { useParams, useLocation } from 'react-router-dom';
import { Box, Tooltip } from '@mantine/core';
import { OpenLink } from 'images';
import { isDesktopApp } from 'helpers';

const { DEV } = import.meta.env;
const { dashboardAPI } = window;
const isDesktop = isDesktopApp();

export const OpenWebStudio = ({
  path,
  disabled,
  color,
  icon,
  enableInBrowser,
}: {
  path: string;
  disabled?: boolean;
  enableInBrowser?: boolean;
  color?: string;
  icon?: React.ReactNode;
}) => {
  const { projectId } = useParams();
  const { pathname, search } = useLocation();
  const currentPath = pathname + search;
  const canOpenPath =
    path && (isDesktop || enableInBrowser) && dashboardAPI && projectId;
  const Icon = icon || <OpenLink color={color} size={18} />;

  return canOpenPath && !disabled ? (
    <Tooltip label={`Open in WebStudio${DEV ? ': ' + path : ''}`}>
      <Box
        style={(theme) => ({
          WebkitAppRegion: 'no-drag',
          cursor: 'pointer',
          color: theme.colors.text[8],
          display: 'flex',
          alignItems: 'center',
        })}
        onClick={(e) => {
          e.stopPropagation();
          setTimeout(() => {
            dashboardAPI?.openStudio(path, currentPath);
          }, 500);
        }}
      >
        {Icon}
      </Box>
    </Tooltip>
  ) : (
    <></>
  );
};
